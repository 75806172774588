import React from 'react'
import { INPUT_STYLE, currencyData } from '../../../constants'
import { validateMakePayment } from '../../../validators/checkout';
import { makeManualPayment } from '../../../services/checkoutServices';
import { error, loaderStart } from '../../../store/reducers/loaderSlice';
import { useDispatch } from 'react-redux';

function MakePayment({checkoutData, payload, handleSuccess, setPayload, handleClose, showCloseCreate=true}) {

    const dispatch = useDispatch();

    const handleOnChange = (e, type ) => {
        let value = e.target.value;
        switch (type) {
            case 'vendor':
              setPayload((prev) => ({...prev, vendor:value}))
              break 
            case 'payment_method':
              setPayload((prev) => ({...prev, payment_method:value}))
              break
            case 'payment_type' :
              if (value == 1) {
                setPayload((prev) => ({...prev, amount:checkoutData?.balance_amount, meta:{...prev.meta, part_payment:value}}))
              }
              else {
                setPayload((prev) => ({...prev, amount:0, meta:{...prev.meta, part_payment:value}}))
              }
              break
            case 'amount':
              setPayload((prev) => ({...prev, amount:+value}))
              break
            case 'currency':
              setPayload((prev) => ({...prev, currency:value}))
              break
            case 'txn_id1':
              setPayload((prev) => ({...prev, txn_id1:value}))
              break
            case 'txn_id2':
              setPayload((prev) => ({...prev, txn_id2:value}))
              break
            case 'description':
              setPayload((prev) => ({...prev, description:value}))
              break
            case 'payment_date':
              setPayload((prev) => ({...prev, payment_date:value}))
              break
            default:
              break
        }
    
    }

    const handleMakePayment = async () => {
        try {
          dispatch(loaderStart())
          let body = {...payload, 
            user: {email_id:checkoutData.email, mobile:checkoutData.mobile, mobile_ext:checkoutData.phone_ext, fname:checkoutData.fname, lname:checkoutData.lname},
            source: {eid: checkoutData.eid, type:"checkout"},
            action:{type: checkoutData.reservation_id ? "repayment" : "create_order"},
            meta: {...payload.meta, custom_amount:payload.amount, final_amount:checkoutData.total_amount}}
          
          validateMakePayment(body, checkoutData);
          const response = await makeManualPayment({request_data:body});
          handleSuccess(response?.data);
          return
        }
        catch (e) {
          dispatch(error(e?.message))
        }
    }


   


  return (
      <>
      <div className="flex items-center w-full  justify-between shadow-lg p-2 mb-6 border rounded-lg">
         <div className="flex flex-col w-[13%]">
            <span className="text-base text-slate-600">Fisrt Name</span>
            <p title={checkoutData?.fname} className="text-base truncate text-slate-600">{checkoutData?.fname}</p>
         </div>

         <div className="flex flex-col w-[13%]">
            <span className="text-base text-slate-600">Last Name</span>
            <p title={checkoutData?.lname} className="text-base truncate text-slate-600">{checkoutData?.lname}</p>
         </div>

         <div className="flex flex-col w-[16%]">
            <span className="text-base text-slate-600">Email</span>
            <p title={checkoutData?.email} className="text-base truncate text-slate-600">{checkoutData?.email}</p>
         </div>

         <div className="flex flex-col w-[16%]">
            <span className="text-base text-slate-600">Mobile</span>
            <span title={checkoutData?.mobile} className="text-base truncate text-slate-600">{checkoutData?.phone_ext} {checkoutData?.mobile}</span>
         </div>

         <div className="flex flex-col w-[13%]">
            <span className="text-base text-slate-600">Total</span>
            <p className="text-base text-slate-600">{checkoutData?.total_amount}</p>
         </div>

         <div className="flex flex-col w-[13%]">
            <span className="text-base text-slate-600">Balance</span>
            <p className="text-base text-slate-600">{checkoutData?.balance_amount}</p>
         </div>

       </div>
       
       
       <div className="flex items-center justify-between flex-wrap gap-y-3">
          <div className="flex flex-col w-[30%]">
            <label className="text-slate-600">Payment Vendor</label>
            <input className={`${INPUT_STYLE}`} onChange={(e) => handleOnChange(e, 'vendor')} value={payload?.vendor} placeholder="amazon" />
          </div>

          <div className="flex flex-col w-[30%]">
            <label className="text-slate-600">Payment Method</label>
            <input className={`${INPUT_STYLE}`} onChange={(e) => handleOnChange(e, 'payment_method')} value={payload?.payment_method} placeholder="button, card" />
          </div>

          <div className="flex flex-col w-[30%]">
            <label className="text-slate-600">Payment Type</label>
            <select className={`${INPUT_STYLE}`} onChange={(e) => handleOnChange(e, 'payment_type')} value={payload?.meta?.part_payment} >
              <option value={""}>Choose an option</option>
              <option value={1}>Full Payment</option>
              <option value={2}>Part Payment</option>
            </select>
          </div>

          <div className="flex flex-col w-[30%]">
            <label className="text-slate-600">Payment Date</label>
            <input className={`${INPUT_STYLE}`} onChange={(e) => handleOnChange(e, 'payment_date')} type="date" />
          </div>

          <div className="flex flex-col w-[30%]">
            <label className="text-slate-600">Amount</label>
            <input className={`${INPUT_STYLE} ${payload?.meta?.part_payment == 1 ? '!bg-slate-200 cursor-not-allowed' : ''}`} 
            disabled={payload?.meta?.part_payment == 1 ? true : false} 
            onChange={(e) => handleOnChange(e, 'amount')} 
            value={payload?.amount} 
            onWheel={(e) => e?.target.blur()} type="number" />
          </div>

          <div className="flex flex-col w-[30%]">
            <label className="text-slate-600">Transaction Id -1</label>
            <input className={`${INPUT_STYLE}`} onChange={(e) => handleOnChange(e, 'txn_id1')} value={payload?.txn_id1} />
          </div>

          <div className="flex flex-col w-[30%]">
            <label className="text-slate-600">Transaction Id -2</label>
            <input className={`${INPUT_STYLE}`} onChange={(e) => handleOnChange(e, 'txn_id2')} value={payload?.txn_id2} />
          </div>

          <div className="flex flex-col w-[30%]">
             <label className="text-slate-600">Choose Currency</label>
             <select className={`${INPUT_STYLE}`} onChange={(e) => handleOnChange(e, 'currency')} value={payload?.currency}>
                <option value={""}>Choose Curreny</option>
                {Object.keys(currencyData).map((curreny) => (
                  <option value={curreny}>{curreny}</option>
                ))}
             </select>
          </div>

          <div className="flex flex-col w-[30%]">
             <label className="text-slate-600">Description</label>
             <textarea className={`${INPUT_STYLE}`} onChange={(e) => handleOnChange(e, 'description')} value={payload?.description}></textarea>
          </div>

       </div>


       {showCloseCreate && (
         <div className="mt-10 flex items-center justify-between">
          <button className="border p-2 rounded-md border-black hover:text-white hover:bg-black" onClick={handleClose}>Close</button>
          <button className="p-2 rounded-md bg-black text-white" onClick={handleMakePayment}>Create</button>
        </div>
       )}
      </>
  )
}

export default MakePayment