module.exports = {
    //listings
    getAllListings : (page, size) => `listing/list/${page}/${size}`,
    createListing: () => `listing/new`,
    getListingById: (id) => `listing/${id}`,
    deleteListingById: (id) => `listing/${id}`,


    //ical
    addIcal: () => `listing/calendar/ical`,
    

    //house-rules 
    getAllHouseRules: () => `attributes/list/houserules`,
    createHouseRule: () => `attributes/list/houserules`,
    updateHouseRule: () => `attributes/list/houserules`,
    deleteHouseRule: (id) => `attributes/list/houserules/${id}`,


    //amenities
    getAllAmenities: () => `attributes/list/amenities`,
    createAmenities: () => `attributes/list/amenities`,
    updateAmenities: () => `attributes/list/amenities`,
    deleteAmenities: (id) => `attributes/list/amenities/${id}`,

    //amenitiesGroup 
    getAllAmenitiesGroup: () => `attributes/list/amenities-group`,
    getAllAmenitiesGroupQuery: () => `attributes/list/amenities-group/query`,
    deleteAmenitiesGroup: (id) => `attributes/list/amenities-group/${id}`,
    updateAmenitiesGroup: () => `attributes/list/amenities-group`,
    createAmenitiesGroup: () => `attributes/list/amenities-group`,

    //catagories
    getAllCategories: () => `attributes/list/categories`,
    createCategory: () => `attributes/list/categories`,
    updateCategory: () => `attributes/list/categories`,
    deleteCategory: (id) => `attributes/list/categories/${id}`,


    //continent 
    getAllContinents: () => `attributes/list/continent`,
    createContinent: () => `attributes/list/continent`,
    updateContinent: () => `attributes/list/continent`,
    deleteContinent: (id) => `attributes/list/continent/${id}`,


    //countries 
    getALLCountries: () => `attributes/list/country`, 
    createCountry: () => `attributes/list/country`, 
    updateCountry: () => `attributes/list/country`, 
    deleteCountry: (id) => `attributes/list/country/${id}`, 

    //destinations 
    getAllDestinations: () => `attributes/list/destinations`,
    createDestination: () => `attributes/list/destinations`,
    updateDestination: () => `attributes/list/destinations`,
    deleteDestination: (id) =>  `attributes/list/destinations/${id}`,

    //Regions
    getAllRegions: () =>  `attributes/list/regions`,
    createRegion: () =>  `attributes/list/regions`,
    updateRegion: (id) =>  `attributes/list/regions/${id}`,
    deleteRegion: (id) =>  `attributes/list/regions/${id}`,

    //extraServices 
    getAllExtraServices: () => `attributes/list/extra-services`,
    createExtraServices: () => `attributes/list/extra-services`,
    updateExtraServices: () => `attributes/list/extra-services`,
    deleteExtraServices: (id) =>  `attributes/list/extra-services/${id}`,

    //listing types
    getAllListingTypes: () => `attributes/list/listing-types`,
    createListingTypes: () => `attributes/list/listing-types`,
    updateListingTypes: () => `attributes/list/listing-types`,
    deleteListingTypes: (id) => `attributes/list/listing-types/${id}`,
   
    //Offers
    getAllOffers: () => `attributes/list/offers`,
    createOffer: () => `attributes/list/offers`,
    updateOffer: () => `attributes/list/offers`,
    deleteOffer: (id) => `attributes/list/offers/${id}`,


    //pets 
    getAllPets: () => `attributes/list/pets`,
    createPet: () => `attributes/list/pets`,
    updatePet: () => `attributes/list/pets`,
    deletePet: (id) => `attributes/list/pets/${id}`,

    //property types 
    getAllPropertyTypes: () => `attributes/list/propertytypes`,

    //processing fee 
    getAllProcessingFee: () => `attributes/list/processing-fee`,
    createProcessingFee: () => `attributes/list/processing-fee`,
    updateProcessingFee: () => `attributes/list/processing-fee`,
    deleteProcessingFee: (id) => `attributes/list/processing-fee/${id}`,

    
    //addons 
    getAllAddons: () => `attributes/list/addons`,
    postAddons: () =>  `attributes/list/addons`,
    putAddons: () => `attributes/list/addons`,
    deleteAddons: (id) => `attributes/list/addons/${id}`,


    
    //users 
    getAllUsers: () => `user/query`,
    getUserById: (id) =>  `user/${id}`,
    createUser: () =>  `user`,
    updateUser: (id) =>  `user/${id}`,
    deleteUser: (id) =>  `user/${id}`,


    //images 
    getImagesQuery: () => `upload/image/public/query`,
    uploadSingleImage: () => `upload/image/public/app`,
    uploadMultipleImages: () => `upload/image/public/multi`,
    singleImageUpload: () => `upload/image/public/app`,

    //uploda multiplle listing images 
    uploadMultipleListingImages: () => `upload/image/public/listing/multi`,


    //page config
    getAllPages: () => `page-config`,
    getPageById: ( id ) => `page-config/${id}`,
    
    
    //destination activities attractions mapping 
    getAllActivitiesAttractionMapping: () => `attributes/list/dest-attraction-mapping`,
    createActivitiesAttractionMapping: () => `attributes/list/dest-attraction-mapping`,
    updateActivitiesAttractionMapping: (id) => `attributes/list/dest-attraction-mapping/${id}`,
    deleteActivitiesAttractionMapping: (id) => `attributes/list/dest-attraction-mapping/${id}`,


    //activities and attractions 
    getAllActivities: () => `attributes/list/attractions`,
    createActivities: () => `attributes/list/attractions`,
    updateActivities: () => `attributes/list/attractions`,
    deleteActivities: (id) => `attributes/list/attractions/${id}`,


    //other-platform users
    getAllOtherPlatformUsers: () => `channel/users/query`,
    getOtherPlatformUsersById: (id) => `channel/users/${id}`,
    createOtherPlatformUser: () => `channel/users/`,
    updateOtherPlatformUser: (id) => `channel/users/${id}`,
    deleteOtherPlatformUser: (id) =>  `channel/users/${id}`,

    //other-platform Booking
    getAllOtherPlatformBookings: () => `channel/bookings/query`,
    getAllOtherPlatformBookingsById: ( id ) => `channel/bookings/${id}`,
    createOtherPlatformBooking: () => `channel/bookings`,
    updateOtherPlatformBooking: (id) => `channel/bookings/${id}`,
    deleteOtherPlatformBooking: (id) => `channel/bookings/${id}`,


    //checkout 
    createCheckout: () => `checkout/`,
    getAllCheckout: () => `checkout/query`,
    getCheckoutById: (id) => `checkout/${id}`,
    createCheckoutSteps: (id) => `checkout/step/${id}`,
    deleteCheckout: (id) => `checkout/${id}`,
    updateCheckout: (id) => `chekout/${id}`,

    //manual payment 
    createManualPayment: () => `payment/manual-payment`,


    //affiliate earning
    getAllAffiliateEarning: () =>  `referral-earning/query`,
    createAffiliateEarning: () => `referral-earning`,
    updateAffiliateEarning: (id) => `referral-earning/${id}`,
    deleteAffiliateEarning: (id) => `referral-earning/${id}`,


    //refferal
    getAllReferrals: () => `referral/query`,
    createReferral: () => `referral`,
    updateReferral: (id) => `referral/${id}`,
    deleteReferral: (id) =>  `referral/${id}`,


    //reservations
    getAllReservations: () => `reservations/query`,
    getAllReservationsById: (id) => `reservations/${id}`,
    createReservation: () => `reservations`,
    updateReservation: (id) => `reservations/${id}/status`,
    deleteReservation: (id) => `reservations/${id}`,

    //event modules 
    getAllEventModules: () => `event-webhooks/query`,
    createEventModule: () => `event-webhooks`,
    updateEventModule: (id) => `event-webhooks/${id}`,
    deleteEventModule: (id) => `event-webhooks/${id}`,

    //Facilities
    getAllFacilities: () => `attributes/list/facilities`,
    createFacilities: () => `attributes/list/facilities`,
    updateFacilities: () => `attributes/list/facilities`,
    deleteFacilities: (id) => `attributes/list/facilities/${id}`,


    //faqs
    getAllFaqs: () => `attributes/list/faqs`,
    createFaq: () => `attributes/list/faqs`,
    updateFaq: (id) => `attributes/list/faqs/${id}`,
    deleteFaq: (id) => `attributes/list/faqs/${id}`,
    getFaqById: (id) => `attributes/list/faqs/${id}`,

    //geo tags 
    getAllGeoTags: () => `attributes/list/geotags`,
    createGeoTag: () => `attributes/list/geotags`,
    updateGeoTag: () => `attributes/list/geotags`,
    deleteGeoTag: (id) => `attributes/list/geotags/${id}`,

    //Gift Card 
    getAllGiftCards: () => `attributes/list/gift-card`,
    createGiftCard: () => `attributes/list/gift-card`,
    updateGiftCard: () => `attributes/list/gift-card`,
    deleteGiftCard: (id) => `attributes/list/gift-card/${id}`,


    //payment gateway
    getAllPaymentGateway: () => `/payment-gateway/query`,
    createPaymentGateway: () => `/payment-gateway`,
    updatePaymentGateway: (id) => `/payment-gateway/${id}`,
    deletePaymentGateway: (id) => `/payment-gateway/${id}`,


    //system-variable
    getAllSystemVariables: () =>  `system-variable/query`,
    createSystemVariable: () =>  `system-variable`,
    updateSystemVariable: (id) =>  `system-variable/${id}`,
    deleteSystemVariable: (id) =>  `system-variable/${id}`,

    //tax
    getAllTaxes: () => `attributes/list/tax`,
    createTax: () => `attributes/list/tax`,
    updateTax: () => `attributes/list/tax`,
    deleteTax: (id) => `attributes/list/tax/${id}`,


    //coupons
    getAllCoupons: () => `attributes/list/coupons`,
    createCoupon: () => `attributes/list/coupons`,
    updateCoupon: () => `attributes/list/coupons`,
    deleteCoupon: (id) => `attributes/list/coupons/${id}`,

    //platform fee 
    getAllPlatFormFee: () =>  `attributes/list/platform-fee`,
    createPlatFormFee: () =>  `attributes/list/platform-fee`,
    updatePlatFormFee: () =>  `attributes/list/platform-fee`,
    deletePlatFormFee: (id) =>  `attributes/list/platform-fee/${id}`,
    
}