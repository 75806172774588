export const isValidToken = (token) => {
  try {
    if (
      token === null ||
      token === undefined ||
      token === "undefined" ||
      token === ""
    ) {
      throw new Error("token is empty");
    }
    // console.log(' token---', token)
    const tokenArr = token.split(".");
    if (tokenArr.length < 2) {
      throw new Error("invalid token");
    }
    const header = JSON.parse(atob(tokenArr[0]));
    const body = JSON.parse(atob(tokenArr[1]));

    validateHeader(header);
    validateBody(body);



    return { data: true, name:body?.name };
  } catch (e) {
    return { data: false, error: e.message };
  }
};

const validateHeader = (header) => {
  if (header) {
    if (header.typ !== "JWT") {
      throw new Error("header is not JWT");
    }
  }
};

const validateBody = (body) => {
  const clientId=process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID;
  if (body) {
    const emailId = body.email;
    const domain = emailId.split("@")[1];
    const currentTime = new Date().getTime();
    const expireToken = body.exp * 1000;

    if (body.aud !== clientId) {
      throw new Error("Invalid Credential");
    }
    if (domain !== "skimbox.us" && domain !== "holidaykeepers.com") {
      throw new Error("Invalid Email");
    }
    if (currentTime > expireToken) {
      //error check
      throw new Error("Token is Expired");
    }
  } else {
    throw new Error("Invalid payload");
  }
};


// function transformRows(response) {
//   return response?.map(res => ({
//     ...res,
//     order_id: <OrderLink orderId={res.order_id} />,
//     checkout_id: <CheckoutLink checkoutId={res.checkout_id} />,
//     info: <GetInfo fname={res.fname} lname={res.lname} phone={res.phone} email={res.email}/>,
//     checkin: formatDate(res.checkin),
//     checkout: formatDate(res.checkout),
//     order_date: formatCreatedAtUpdatedAt(res.order_date)
//   }));
// }