import api from '../api'
import endPoints from './endPoints';


export async function getAllCountrys() {
  try {
    const response = await api.get(endPoints.getALLCountries())
    return response
  }
  catch ( e ) {
    throw new Error( e )
  }
}

export async function postCountrys(body) {
  try {
    const response = await api.post(endPoints.createCountry(), body)
    return response
  }
  catch ( e ) {
    throw new Error( e )
  }
}

export async function putCountrys(body) {
  try {
    const response = await api.put(endPoints.updateCountry(), body)
    return response
  }
  catch ( e ) {
    throw new Error( e )
  }
}

export async function deleteCountrys(id) {
  try {
    const response = await api.delete(endPoints.deleteCountry(id))
    return response
  }
  catch ( e ) {
    throw new Error( e )
  }
}




