import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getOtherPlatformUsersById, updateOtherPlatformUser } from "../../services/otherPlatforms/users";
import { formatDate } from "../../utils/common"; 
import { loaderStart, loaderSuccess, error } from "../../store/reducers/loaderSlice";
// import Button from "../../../components/button/button";
// import ConfirmationModal from "../../../components/dialogBox/generic/confirmationModal";
import { BOOKING_CHANNEL_ARR } from "../../constants/otherPlatforms"; 
const INPUT_CLASS =
  "w-1/2 placeholder:italic placeholder:text-slate-500 block bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm shadow-primary text-primary focus:outline-none focus:border-red-300 focus:ring-red-300 focus:ring-1 sm:text-sm";

function OtherplatformUserDetail() {
  const [userDetails, setUserDetails] = useState();
  const [editableUserDetails, setEditableUserDetails] = useState();
  const [newUserDetails, setNewUserDetails] = useState();
  const [isEditable, setIsEditable] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [request, setRequest] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    getData();
  }, [request]);

  function getData() {
    dispatch(loaderStart());
    getOtherPlatformUsersById(params.id)
      .then((res) => {
        dispatch(loaderSuccess());
        setUserDetails(res);
        setEditableUserDetails(res);
      })
      .catch((err) => {
        dispatch(error(err.message));
        console.log("you have a error in api user-details-page", err);
      });
  }

  function handleUserEdit(type) {
    switch (type) {
      case "edit": {
        setIsEditable((prev) => !prev);
        break;
      }
      case "update": {
        updateUser();
        break;
      }
      case "cancel": {
        setIsEditable((prev) => !prev);
        setRequest((prev) => !prev);
        break;
      }
      default:
        return;
    }
  }

  function handleInput(e, type) {
    let val = e.target.value;


    if (type === "user_channel") {
        console.log('call', val);
      setEditableUserDetails({  ...editableUserDetails,  user_channel: val,});
      setNewUserDetails({ ...newUserDetails, user_channel: val,});
    } 
    else if (type === "email_id") {
      setEditableUserDetails({ ...editableUserDetails, email_id: val,});
      setNewUserDetails({...newUserDetails,email_id: val,});
    } 
    else if (type === "fname") {
      setEditableUserDetails({ ...editableUserDetails, fname: val,});
      setNewUserDetails({ ...newUserDetails, fname: val,});
    } 
    else if (type === "lname") {
      setEditableUserDetails({  ...editableUserDetails,  lname: val,});
      setNewUserDetails({...newUserDetails,lname: val,});
    } 
    else if (type === "mobile") {
      setEditableUserDetails({ ...editableUserDetails, mobile: val});
      setNewUserDetails({ ...newUserDetails, mobile: val,});
    }
  }

  function handleUpdateModal() {
    setShowUpdateModal((prev) => !prev);
    
  }

  function updateUser() {
    console.log("new user details: ", newUserDetails);
    dispatch(loaderStart());
    updateOtherPlatformUser(params.id, newUserDetails)
      .then((res) => {
        dispatch(loaderSuccess());
        setShowUpdateModal((prev) => !prev);
        setEditableUserDetails({});
        setRequest((prev) => !prev);
        setIsEditable((prev) => !prev);
      })
      .catch((err) => {
        console.log("Error in upddating user detaills: ", err);
        dispatch(error(err?.message));
      });
  }

  return (
    <div className="min-h-screen">
      {/* {showUpdateModal && (
        <ConfirmationModal
          isOpen={showUpdateModal}
          closeModal={handleUpdateModal}
          onUpdate={updateUser}
        />
      )} */}
      {userDetails ? (
        <div className="flex flex-col items-center pb-36">
          <div className="flex justify-between mt-5 items-center w-2/3">
            <h1 className="text-red-500  font-medium pb-3  text-lg">
              User Details
            </h1>
            {isEditable ? (
              <div>
                <button onClick={() => handleUserEdit("cancel")}> Cancel</button>
                  {/* value="Cancel"
                  type="cancel"
                  size="sm"
                  className="mr-4"
                  onClick={() => handleUserEdit("cancel")} */}
                {/* /> */}
                <button  onClick={() => handleUserEdit("update")}>Update</button>
                {/* <Button
                  value="Update"
                  type="success"
                  size="sm"
                  onClick={() => handleUserEdit("update")}
                /> */}
              </div>
            ) : (
            //   <Button
            //     value="Edit"
            //     type="primary"
            //     size="sm"
            //     onClick={() => handleUserEdit("edit")}
            //   />
            <button onClick={() => handleUserEdit("edit")}>Edit</button>
            )}
          </div>

          <div className="w-2/3 bg-slate-100 rounded p-3 drop-shadow mt-3 mb-5">
            <div className="flex justify-between items-center pb-2">
              <p className="w-1/2 text-slate-700">Id</p>
              <p className="w-1/2">{userDetails.id}</p>
            </div>
            <div className="flex justify-between items-center pb-2">
              <p className="w-1/2 text-slate-700">Channel Name</p>
              {isEditable ? (
                <select 
                  value={editableUserDetails.user_channel}
                  className={`${INPUT_CLASS}`}
                  onChange={(e) => handleInput(e, "user_channel")}
                >
                  <option value="">Choose Booking Channel</option>
                  {BOOKING_CHANNEL_ARR.map((channel) => (
                    <option value={channel?.value}>
                      {" "}
                      {channel?.name}{" "}
                    </option>
                  ))}
                </select>
              ) : (
                <p className="w-1/2">{userDetails.user_channel}</p>
              )}
            </div>
            <div className="flex justify-between items-center pb-2">
              <p className="w-1/2 text-slate-700">Email</p>
              {isEditable ? (
                <input
                  className={`${INPUT_CLASS}`}
                  value={editableUserDetails.email_id}
                  onChange={(e) => handleInput(e, "email_id")}
                />
              ) : (
                <p className="w-1/2">{userDetails.email_id}</p>
              )}
            </div>
            <div className="flex justify-between items-center pb-2">
              <p className="w-1/2 text-slate-700">FirstName</p>
              {isEditable ? (
                <input
                  className={`${INPUT_CLASS}`}
                  value={editableUserDetails.fname}
                  onChange={(e) => handleInput(e, "fname")}
                />
              ) : (
                <p className="w-1/2">{userDetails.fname}</p>
              )}
            </div>
            <div className="flex justify-between items-center pb-2">
              <p className="w-1/2 text-slate-700">LastName</p>
              {isEditable ? (
                <input
                  className={`${INPUT_CLASS}`}
                  value={editableUserDetails.lname}
                  onChange={(e) => handleInput(e, "lname")}
                />
              ) : (
                <p className="w-1/2">{userDetails.lname}</p>
              )}
            </div>
            <div className="flex justify-between items-center pb-2">
              <p className="w-1/2 text-slate-700">Mobile</p>
              {isEditable ? (
                <input
                  className={`${INPUT_CLASS}`}
                  value={editableUserDetails.mobile}
                  onChange={(e) => handleInput(e, "mobile")}
                />
              ) : (
                <p className="w-1/2">{userDetails.mobile}</p>
              )}
            </div>
            <div className="flex justify-between items-center pb-2">
              <p className="w-1/2 text-slate-700">Created at</p>
              <p className="w-1/2">{formatDate(userDetails.created_at)}</p>
            </div>
            <div className="flex justify-between items-center pb-2">
              <p className="w-1/2 text-slate-700">Updated at</p>
              <p className="w-1/2">{formatDate(userDetails.updated_at)}</p>
            </div>
          </div>
        </div>
      ) : (
        "No Data"
      )}
    </div>
  );
}

export default OtherplatformUserDetail;
