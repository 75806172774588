import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { getUserById, updateUser } from '../../../services/userServices';
// import UserEditForm, { RenderUserNames } from './userEditForm';
// import UserPopup from './userPopup';
// import { userInputNames } from '../../../constants/userData';
// import Dialog from '../../../ui/dialog';
import { formatDate } from '../../../utils/common';
import { useDispatch } from 'react-redux';
import { loaderStart, loaderSuccess, error } from '../../../store/reducers/loaderSlice';
import { showNotifications } from '../../../store/reducers/notificationsSlice';
import PhoneExtensionSelectBar from '../../../components/dropdown/PhoneInputs/selectPhoneNo';
import { useCountryHashMapSelector, useCountrySelector, useRegionsHashMapSelector, useRegionsSelector } from '../../../store/selectors';
import { INPUT_STYLE } from '../../../constants';

const UserID = () => {
    const [userData, setUserData] = useState({});
    const [pageLoad, setPageLoad] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [payload, setPayload] = useState()
    
    const {  fname,  lname,  mobile,  dob,  email,  gender, postal_code, address1, address2, bio, mobile_ext, house_no,  username,
    user_role, country_id, created_at, updated_at,  languages, state_id,} = userData


    const params = useParams();
    const dispatch = useDispatch();

    const countriesData = useCountrySelector();
    const countryHashMap = useCountryHashMapSelector();
    const regionHashMap = useRegionsHashMapSelector();
    const regionsData = useRegionsSelector();
    

    useEffect(() => {
        getUserDataById();
    }, [pageLoad]);


    const getUserDataById = async () => {
        try {
            dispatch(loaderStart())
            const response = await getUserById(params.id);
            setUserData(...response.data);
            dispatch(loaderSuccess())
        } 
        catch (e) {
            dispatch(error(e?.message))
        }
    };

    const handleUpdate = async () => {
        try {
            dispatch(loaderStart())
            const response = await updateUser(payload, params.id);
            console.log('response', response)
            dispatch(loaderSuccess())
            setPageLoad((prev) => !prev);
            setIsEdit(false);
            dispatch(showNotifications('Updated Successfully'));
        }
        catch (e) {
            dispatch(error(e?.message))
        }
    };


    const handleOnChange = async (e, key) => {
        let value = e.target.value
        if (key === 'country_id') {
            setPayload((prev) => ({...prev, [key]:value, state_id:''}));
            setUserData((prev) => ({...prev, [key]:value, state_id:''}));
        }
        else {
            setPayload((prev) => ({...prev, [key]:value}));
            setUserData((prev) => ({...prev, [key]:value}));
        }
       
    }

    //phone number input on change
    const handlePhoneOnChange = (e) => {
        setPayload((prev) => ({...prev, mobile:e.target.value}))
        setUserData((prev) => ({...prev, mobile:e.target.value}))
    }

    //phone ext onchange
    const handlePhoneExt = (value) => {
        setPayload((prev) => ({...prev, mobile_ext:value}))
        setUserData((prev) => ({...prev, mobile_ext:value}))
    }


    const usersData = [
        { name: "First Name", value: fname, key:"fname", isEditable:true, type:'text'},
        { name: "Last Name", value: lname, key:"lname",  isEditable:true, type:'text' },
        { name: "Mobile", value: mobile, value1:!mobile_ext ? "+1" : mobile_ext, key:"mobile", key1:"mobile_ext",  isEditable:true, type:'phone'},
        { name: "Date of Birth", value: dob ? formatDate(dob) : '', key:"dob", isEditable:true, type:'date' },
        { name: "Email", value: email,  isEditable:true, type:'text', key:"email" },
        { name: "Gender", value: gender,  isEditable:true, type:'select', key:'gender', options:[{name:"Male", value:1}, {name:"Female", value:2}] },
        { name: "Address 1", value: address1, isEditable:true, type:'text', key:'address1' },
        { name: "Address 2", value: address2, isEditable:true, type:'text',  key:'address2' },
        { name: "House No", value: house_no, isEditable:true, type:'text',  key:'house_no'  },
        { name: "Pincode", value: postal_code, isEditable:true, type:'text', key:'postal_code' },
        { name: "Bio", value: bio, isEditable:true, type:'textarea', key:'bio' },
        { name: "Languages", value: languages, isEditable:true, type:'text',  key:'languages'  },
        { name: "User Name", value: username, isEditable:true, type:'text', key:'username' },
        { name: "User Role", value: user_role, isEditable:true, type:'select', key:'user_role',  options:[{name:"Admin", value:1}, {name:"Host", value:2}, {name:"Customer", value:2}] },
        { name: "Country ID", value: country_id,  isEditable:true, type:'country_id',  key:'country_id', },
        { name: "State ID", value: state_id, isEditable:true, type:'state_id',  key:'state_id' },
        // { name: "Last Login", value: last_login, isEditable:false, },
        // { name: "Login Attempts", value: login_attempts, isEditable:false, type:'text' },
        // { name: "Reset Attempts", value: reset_attempts,  isEditable:true,  key:'reset_attempts', type:'text'  },
        // { name: "Is Active", value: is_active, isEditable:false, },
        // { name: "Is Deleted", value: is_deleted, isEditable:false, },
        { name: "Created At", value: created_at ? formatDate(created_at) : "" , isEditable:false,},
        { name: "Updated At", value: updated_at ? formatDate(updated_at) : "", hr: true , isEditable:false,},
    ];


    const renderInputElements = (type, value, options, key, key1, value1) => {
       switch (type) {
          case 'text':
            return <input className={`${INPUT_STYLE} w-1/2`} value={value} onChange={(e) => handleOnChange(e, key)} />

          case 'select':
            return (
                 <select className={`${INPUT_STYLE}`} value={value}  onChange={(e) => handleOnChange(e, key)}>
                     <option value="">Choose an option</option>
                     {options?.map((data, idx) => (
                        <option key={idx} value={data?.value}>{data?.name}</option>
                     ))}
                 </select>
            )
          case 'date':
            return <input className={`${INPUT_STYLE}`} value={value} type="date"  onChange={(e) => handleOnChange(e, key)} />

          case 'textarea':
            return <textarea className={`${INPUT_STYLE}`} value={value}  onChange={(e) => handleOnChange(e, key)}></textarea>
          
          case 'phone':
            return (
                <PhoneExtensionSelectBar
                    phoneExt={value1}
                    onChange={handlePhoneOnChange}
                    inputStyle={"flex flex-col w-[100%]"}
                    value={value}
                    selectPhoneExt={handlePhoneExt}
                    className={"w-[100%] h-[40px] bg-white"}
                />
            )
          case 'country_id':
            return (
                <select className={`${INPUT_STYLE}`} value={value}  onChange={(e) => handleOnChange(e, 'country_id')}>
                    <option value="">Choose an option</option>
                    {countriesData?.map((data, idx) => (
                    <option key={idx} value={data?.id}>{data?.name}</option>
                    ))}
               </select>
            )

           case 'state_id':
                return (
                    <select className={`${INPUT_STYLE}`} value={value}  onChange={(e) => handleOnChange(e, 'state_id')}>
                        <option value="">Choose an option</option>
                        {regionsData?.filter((data) => data?.parent_id === country_id)?.map((region, idx) => (
                           <option key={idx} value={region?.id}>{region?.name}</option>
                        ))}
                   </select>
            )
         
       }

    }

    const showCountryRegionName = (id, key) => {
       if (key === 'country_id') {
          return countryHashMap[id]?.name
       }
       else if (key === 'state_id') {
        return regionHashMap[id]?.name
       }
    }
   
    return (
        <div className='h-screen overflow-y-auto mb-20 mt-12'>
            <div className='flex items-center justify-end mx-3 gap-x-3'>
                {!isEdit && <button className='bg-black text-white px-3 py-1 rounded-md cursor-pointer' onClick={() => setIsEdit(true)}>Edit</button>}
                {isEdit && (
                    <>
                      <button className='hover:bg-black hover:text-white border border-black px-3 py-1 rounded-md cursor-pointer' onClick={() => setIsEdit(false)}>Cancel</button>
                      <button className='bg-black text-white px-3 py-1 rounded-md cursor-pointer' onClick={handleUpdate}>Update</button>
                    </>
                )}
            </div>
            <div className='flex items-center justify-center'>
                <div className='bg-slate-100 rounded-md p-3 w-[70%] mx-4'>
                    <div>
                        {usersData?.map((data, idx) => {
                            return (
                                <div key={idx} className='flex items-center gap-x-3 mt-3'>
                                    <p className='w-1/2 text-slate-600'>{data?.name}</p>
                                    {(isEdit && data?.isEditable) ? renderInputElements(data?.type, data?.value, data?.options, data?.key, data?.key1, data?.value1) :  
                                    <p>{data?.type === 'country_id' || data?.type === 'state_id' ? showCountryRegionName(data?.value, data?.type ) :data?.value }</p>}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserID