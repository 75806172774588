import React, { useState } from "react";
import { currencyData } from "../../../../utils/common";
import {
  useCountryHashMapSelector,
  useCountrySelector,
  useDestinationHashMapSelector,
  useDestinationsSelector,
  useRegionsHashMapSelector,
  useRegionsSelector,
} from "../../../../store/selectors";
import { createPage, updatePage } from "../../../../services/pageConfig";
import {
  error,
  loaderStart,
  loaderSuccess,
} from "../../../../store/reducers/loaderSlice";
import { useDispatch } from "react-redux";
import Input from "../../../createListing.js/input";
import FormInputs from "../../../popup/formInputs";
import Button from "../../../button";
import { INPUT_STYLE } from "../../../../constants";
import { showNotifications } from "../../../../store/reducers/notificationsSlice";

function MainPageConfigForm({  onClose,  setPageLoad,  initialEditState,  editId,  action = "create",}) {
  let initialPayload = action === "create"   ? { slug: "", default_currency: "", page_type: "country" }  : initialEditState;

  const [payload, setPayload] = useState(initialPayload);

  const dispatch = useDispatch();

  const countriesData = useCountrySelector();
  const regionsData = useRegionsSelector();
  const destinationsData = useDestinationsSelector();

  const countryHashMap = useCountryHashMapSelector();
  const regionHashMap = useRegionsHashMapSelector();
  const destinationHashMap = useDestinationHashMapSelector();

  

  const handleOnChange = (e, key) => {
    let value = e.target.value;
    console.log(key);
    if (key === "page_type") {
      let refKey = `${value}_ref_id`;
      setPayload((prev) => ({
        slug: "",
        default_currency: prev.default_currency,
        page_type: value,
        [refKey]: "",
      }));
    } else if (key === `${payload?.page_type}_ref_id`) {
      let slug;

      if (key === "country_ref_id") {
        slug = countryHashMap[value]?.slug;
      }

      if (key === "region_ref_id") {
        slug = regionHashMap[value]?.slug;
      }

      if (key === "destination_ref_id") {
        slug = destinationHashMap[value]?.slug;
      }

      setPayload((prev) => ({ ...prev, [key]: value, slug }));
    } else {
      setPayload((prev) => ({ ...prev, [key]: value }));
    }
  };


  const handleCreate = async () => {
    try {
      if (action === "create") {
        dispatch(loaderStart());
        if (!payload?.page_type) {
          throw new Error("Page Type Required");
        }

        if (!payload[`${payload?.page_type}_ref_id`]) {
          throw new Error("Reference required");
        }

        if (!payload?.slug) {
          throw new Error("Slug required");
        }

        if (!payload?.default_currency) {
          throw new Error("Currency required");
        }

        const data = await createPage(payload);
        dispatch(loaderSuccess());
        onClose((prev) => !prev);
        setPageLoad((prev) => !prev);
        dispatch(showNotifications('Created Successfully'))
      } else {
        dispatch(loaderStart());
        const data = await updatePage(editId, payload);
        dispatch(loaderSuccess());
        onClose((prev) => !prev);
        setPageLoad((prev) => !prev);
        dispatch(showNotifications('Updated Successfully'))
      }
    } catch (e) {
      console.log("e", e);
      dispatch(error(e?.message));
    }
  };

  const renderOptions = () => {
    switch (payload?.page_type) {
      case "country":
        return countriesData.map((country, idx) => (
          <option key={idx} value={country?.id}>
            {country?.name}
          </option>
        ));

      case "region":
        return regionsData.map((region, idx) => (
          <option key={idx} value={region?.id}>
            {region?.name}
          </option>
        ));

      case "destination":
        return destinationsData.map((destination, idx) => (
          <option value={destination?.id} key={idx}>
            {destination?.name}
          </option>
        ));

      default:
        break;
    }
  };

  return (
    <>
      <div className='flex flex-col'>
            <label>Page Type</label>
            <select className={`${INPUT_STYLE}`}  onChange={(e) =>  handleOnChange(e, 'page_type')} value={payload?.page_type}>
                {/* <option value="">Choose Country</option> */}
                <option value="country">Country</option>
                <option value="region">Region</option>
                <option value="destination">Destination</option>
            </select>
        </div>
        <div className='flex flex-col mt-6'>
            <label>Reference</label>
            <select className={`${INPUT_STYLE}`} onChange={(e) => handleOnChange(e, `${payload?.page_type}_ref_id`)} value={payload[`${payload?.page_type}_ref_id`]}>
              <option value="">Choose {payload?.page_type}</option>
             {renderOptions()}
            </select>
        </div>
       
        <div className='flex flex-col mt-6'>
          <label>Slug</label>
          <input onChange={(e) => handleOnChange(e, `slug`)}  className={`${INPUT_STYLE} cursor-not-allowed`} value={payload?.slug}/>
        </div>
       
        <div className='flex flex-col mt-6'>
            <label>Default Currency</label>
            <select className={`${INPUT_STYLE}`} onChange={(e) => handleOnChange(e, 'default_currency')} value={payload?.default_currency}>
              <option value="">Choose Currency</option>
                {Object.keys(currencyData).map((currency, index) => (
                    <option key={index} value={currency}>{currencyData[currency]?.symbol} - {currency}</option>
                 ))}
           </select>
        </div>

        <div className='flex justify-between items-center mt-5'>
          <button className="hover:bg-black hover:text-white px-3  py-2 border border-black rounded-md" onClick={() => onClose((prev) => !prev)}>Close</button>
          <button className="bg-black text-white px-3  py-2 rounded-md" onClick={handleCreate}>{action === 'create' ? 'Create' : 'Update'}</button>
       </div>
    </>
  );
}

export default MainPageConfigForm;
