import api from '../api'
import endPoints from './endPoints';

export  const getAllTypes = async () => {
  try {
    const response = await api.get(endPoints.getAllListingTypes())
    return response
  }
  catch ( e ) {
    throw new Error( e )
  }
}

export const createType = async (body) => {
  try {
    const response = await api.post(endPoints.createListingTypes(), body)
    return response
  }
  catch ( e ) {
    throw new Error( e )
  }
}

export const updateType = async (body) => {
  try {
    const response = await api.put(endPoints.updateListingTypes(), body)
    return response
  }
  catch ( e ) {
    throw new Error( e )
  }
}

export const deleteType = async (id) => {
  try {
    const response = await api.delete(endPoints.deleteListingTypes(id))
    return response
  }
  catch ( e ) {
    throw new Error( e )
  }
}


 