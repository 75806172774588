import React, { useEffect, useState } from "react";
import Title from "../title";
import Button2 from "../button";
import Dialog from "../../../ui/dialog";
import UploadImage from "../../../ui/input/uploadImage";
import RoomArrangeMentForm from "./roomArrangeMentForm";
import DeletePopup from "../../popup/deletePopup";

function RoomArrangement({ payload, setPayload }) {
  const [pageLoad, setPageLoad] = useState(false);
  const [pop, setPop] = useState({
    isCreate: false,
    isEdit: false,
    isDelete: false,
  });
  const [deletedObj, setDeletedObj] = useState(null);
  const [uploadState, setUploadState] = useState({ index: null, url: "" });
  const resetFormData = {
    name: "",
    details: [
      {
        name: "",
        description: "",
        price: "",
        additional_info: "",
        sequence: 1,
        image: "",
      },
    ],
  };
  const [edit, setEditData] = useState([]);

  useEffect(() => {
    console.log("useEffect");
  }, [payload]);

  const hidePopup = (item) => {
    if (item === "Create") setPop({ ...pop, isCreate: false });
    if (item === "Edit") setPop({ ...pop, isEdit: false });
    if (item === "Delete") setPop({ ...pop, isDelete: false });
  };
  const visibleCreatePopup = () => {
    // console.log("open--", pop);
    setPop((prev) => ({ ...prev, isCreate: true }));
  };

  const setUpdateHandler = (index) => {
    // console.log(payload.room_arrangement[index]);
    const object = payload.room_arrangement[index];
    setEditData({ ...object, index });
    setPop((prev) => ({ ...prev, isEdit: true }));
    // console.log(object);
  };

  const deleteHandler = () => {
    const index = deletedObj.index;
    // console.log(index,payload.room_arrangement);
    setPayload((prev) => {
      prev.room_arrangement.splice(index, 1);
      // console.log(prev.room_arrangement)
      return prev;
    });
    setPageLoad((prev) => !prev);
  };


  // console.log(payload);
  return (
    <div className="">
      <div className="flex items-center justify-between">
        <Title>Room Arrangement</Title>
        <Button2 onClick={visibleCreatePopup}>Add</Button2>
      </div>
      {/* all room_arrangement list */}
      <div className="mt-10">
        {payload?.room_arrangement.map((elm, index) => (
          <div
            className="flex items-center my-2 rounded-lg p-3 relative"
            key={index}
          >
            <div className="w-[100%]">
              <div className="flex items-center justify-between">
                <span className="font-bold text-xl">{elm.name}</span>

                <div className="flex gap-2 ">
                  <div className="flex flex-col gap-y-2">
                    <Button2 onClick={() => setUpdateHandler(index)}>
                      Edit
                    </Button2>
                  </div>

                  <div className="flex flex-col gap-y-2">
                    <Button2
                      onClick={() => {
                        setDeletedObj({ index: index, Description: `${elm.name}` });
                        setPop({ ...pop, isDelete: true });

                        // deleteHandler(index);
                      }}
                    >
                      Delete
                    </Button2>
                  </div>
                </div>
              </div>

              <div className="flex gap-8 flex-wrap lg p-2 ">
                {elm?.details.map((detail, inx) => (
                  <div
                    key={`${detail.name}-${detail.image}`}
                    className="flex p-4  w-[500px] border rounded-lg"
                  >
                    <div className="flex w-[15%] ">
                      <img
                        className="w-9 h-9"
                        src={`${process.env.REACT_APP_CDN_URL}${detail.image}`}
                        alt={detail.name}
                      />
                    </div>
                    <div className="">
                      <div className="flex flex-col  ">
                        {/* <label>Name</label> */}
                        <span className="font-normal">{detail.name}</span>
                      </div>
                      <div className="flex flex-col ">
                        {/* <label>Description</label> */}
                        <span>{detail.description}</span>
                      </div>
                      <div className="flex flex-col  ">
                        {/* <label>Price</label> */}
                        <span>{detail.price}</span>
                      </div>
                      <div className="flex flex-col ">
                        {/* <label>Info</label> */}
                        <span>{detail.additional_info}</span>
                      </div>
                    </div>
                    {/* <div></div> */}
                  </div>
                ))}
              </div>
            </div>

            {/* <div className="flex gap-2 absolute right-2">
              <div className="flex flex-col gap-y-2">
                <Button2 onClick={() => setUpdateHandler(index)}>Edit</Button2>
              </div>

              <div className="flex flex-col gap-y-2">
                <Button2 onClick={() => deleteHandler(index)}>
                  Delete
                </Button2>
              </div>
            </div> */}
          </div>
        ))}
      </div>

      <Dialog
        closeModal={() => hidePopup("Create")}
        isOpen={pop.isCreate}
        createClick={pop.isCreate}
        childrenClass="bg-white p-5 rounded-lg w-[50%]"
      >
        <RoomArrangeMentForm
          payload={payload}
          eventType="Create"
          setPop={setPop}
          setPayload={setPayload}
          editData={{
            name: "",
            details: [
              {
                name: "",
                description: "",
                price: "",
                additional_info: "",
                image: "",
              },
            ],
          }}
          // setShowUploadDialog={setShowUploadDialog}
        />
      </Dialog>
      <Dialog
        closeModal={() => hidePopup("Edit")}
        isOpen={pop.isEdit}
        createClick={pop.isEdit}
        childrenClass="bg-white p-5 rounded-lg w-[50%]"
      >
        <RoomArrangeMentForm
          payload={payload}
          eventType="Edit"
          setPop={setPop}
          setPayload={setPayload}
          editData={edit}
          // setShowUploadDialog={setShowUploadDialog}
        />
      </Dialog>

      {/* //Delete popup */}
      {pop.isDelete  && (
        <DeletePopup
          deletedObj={deletedObj}
          setIsOpen={setPop}
          // close={handleClose}
          title="Room-Arrangements"
          onDeleteList={deleteHandler}
        />
       )}
    </div>
  );
}

export default RoomArrangement;
