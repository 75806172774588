import api from '../api'
import endPoints from './endPoints'

export async function getAllEventModules() {
  try {
    let body = { pagination: {limit:2000, page:1} }
    let response = await api.post(endPoints.getAllEventModules(), body);
    return response
  }
  catch (e) {
    throw new Error(e?.response?.data?.error?.message)
  }
}

export async function postEventModules( body ) {
  try {
    let response =  await api.post(endPoints.createEventModule(), body);
    return response
  }
  catch (e) {
    throw new Error(e?.response?.data?.error?.message)
  }
}

export async function putEventModules(body, id) {
  try {
    let response = await api.put(endPoints.updateEventModule(id), body);
    return response
  }
  catch (e) {
    throw new Error(e?.response?.data?.error?.message)
  }
}

export async function deleteEventModules(id) {
  try {
    let response = await api.delete(endPoints.deleteEventModule(id));
    return response
  }
  catch (e) {
    throw new Error(e?.response?.data?.error?.message)
  }

}






