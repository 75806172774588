import api from '../api'
import endPoints from './endPoints';

export async function getAllSystemVariables(body) {
  try {
    const data = await api.post(endPoints.getAllSystemVariables(), body);
    return data
  }
  catch(e) {
    throw new Error(e?.response?.data?.error?.message)
  }
}

export async function postSystemVariables(body) {
  try {
    const data = await api.post(endPoints.createSystemVariable(), body);
    return data
  }
  catch(e) {
    throw new Error(e?.response?.data?.error?.message)
  }
}

export async function putSystemVariables(body, id) {
  try {
    const data = await api.put(endPoints.updateSystemVariable(id), body);
    return data
  }
  catch(e) {
    throw new Error(e?.response?.data?.error?.message)
  }
}


export async function deleteSystemVariables(id) {
  try {
    const data = await api.delete(endPoints.deleteSystemVariable(id));
    return data
  }
  catch(e) {
    throw new Error(e?.response?.data?.error?.message)
  }
}


