import React, { useState } from 'react'
import Input from '../../popup/Input'
import PhoneExtensionSelectBar from '../../dropdown/PhoneInputs/selectPhoneNo';
import PriceBreakUp from './priceBreakUp';
import { useAddonsHashMapSelector , useExtraServicesHashMapSelector} from '../../../store/selectors';
import { formatDate } from '../../../utils/common';
import { ChevronDownIcon } from '../../../icons';

const Step2 = ({ payload, setPayload, checkoutData}) => {

    const [basePriceOpen, setBasePriceOpen] = useState(false);
    // const [securityDepositOpen, setSecurityDepositOpen] = useState( false );
    const [showAddons, setShowAddons] = useState(false);
    // const [showAddonsDetail, setShowAddonsDetail] = useState( false);
    const [showServices, setShowServices] = useState( false);
    const [showTax, setShowTax] = useState(false);



    const [phoneExt, setPhoneExt] = useState(`${payload?.phone_ext ? payload.phone_ext : "+1"}`);

    const addonsHashMap = useAddonsHashMapSelector();
    const extraServicesHashMap = useExtraServicesHashMapSelector();

    let listingImage = checkoutData?.listing_data?.media?.file_path
    let title = checkoutData?.listing_data?.title 
    let checkin = checkoutData?.checkin_dt
    let checkout = checkoutData?.checkout_dt 
    let checkinTime = checkoutData?.listing_data?.check_in_time
    let checkoutTime = checkoutData?.listing_data?.check_out_time
    let pricingData = checkoutData?.pricing_data && (JSON.parse(checkoutData?.pricing_data) || {});

    let className='text-slate-600 font-medium'
    return (
        <div className='overflow-auto'>
            <h1 className='text-gray-900 text-[18px] mb-4 font-bold text-center'>STEP-2</h1>
            <div className='flex items-start justify-between'>
                <div className='flex flex-col items-start w-1/2'>
                    <Input
                        className={'w-full'}
                        type={`text`}
                        value={payload.fname}
                        label={`First Name`}
                        required={true}
                        onChange={(e) => setPayload({ ...payload, fname: e.target.value })}
                    />
                    <Input
                        type={`text`}
                        value={payload.lname}
                        className={'w-full mt-3'}
                        label={`Last Name`}
                        required={true}
                        onChange={(e) => setPayload({ ...payload, lname: e.target.value })}
                    />

                    <Input
                        type={`email`}
                        value={payload.email}
                        required={true}
                        className={'w-full mt-3'}
                        label={`Email`}
                        onChange={(e) =>setPayload({ ...payload, email: e.target.value })}
                    />

                    
                    <PhoneExtensionSelectBar
                        type={"number"}
                        label={"Mobile"}
                        inputStyle={" flex-col w-full mt-3"}
                        className={" border-[solid]  py-2 px-4 border-2 !w-[100%]"}
                        phoneExt={phoneExt}
                        onChange={(e) => setPayload({ ...payload, phone_ext: phoneExt, mobile: e.target.value })}
                        selectPhoneExt={(item) => setPhoneExt(item)}
                        labelStyle={"text-md"}
                        value={payload.mobile}
                    />
                 
            
                </div>

                <div className='w-[47%] '>
                    <img src={`${process.env.REACT_APP_CDN_URL}${listingImage}`}/>
                    <p className='font-bold mt-2 text-base'>{title}</p>
                    
                    <hr className='mt-6 mb-3'/>
                    <h3 className='mb-2 font-bold'>Check in/out Details</h3>

                    <div className='flex items-start justify-between'>
                        <div>
                            <p className='font-bold text-base'>Check in</p>
                            <p className={`${className}`}>{formatDate(checkin)}</p>
                            <p className={`${className}`}>{checkinTime}</p>
                        </div>

                        <div>
                            <p className='font-bold text-base'>Check out</p>
                            <p className={`${className}`}>{formatDate(checkout)}</p>
                            <p className={`${className}`}>{checkoutTime}</p>
                        </div>

                    </div>

                    <hr className='mt-6 mb-3'/>
                    <h3 className='mb-2 font-bold'>Guests Details</h3>
                    <p className={`${className} font-base`}>{pricingData?.no_of_guests} Guest</p>

                    <hr className='mt-6 mb-3'/>
                    <h3 className='mb-2 font-bold'>Price Breakup</h3>

                    <div className='flex items-center justify-between'>
                        <p className='flex gap-x-2 items-center'> <p className={`${className}`}>Base Price</p><ChevronDownIcon onClick={() => setBasePriceOpen((prev) => !prev)}  className={`${basePriceOpen ? "rotate-180" : ""} mt-1 cursor-pointer`} /></p>
                        <p className={`${className}`}>{pricingData?.base_price_total}</p>
                    </div>

                    {basePriceOpen && <PriceBreakUp className={`${className}`} priceObj={pricingData?.base_price_detail} />}

                    <div className='flex items-center justify-between mt-3'>
                        <p className='flex gap-x-2 items-center'> <p className={`${className}`}>Security Deposit</p>
                        </p>
                        <p className={`${className}`}>{pricingData?.security_deposit_total}</p>
                    </div>
                    
                    <div className='flex items-center justify-between mt-3'>
                        <div className='flex gap-x-2 items-center'> <p className={`${className} `}>Addons</p><ChevronDownIcon onClick={() => setShowAddons((prev) => !prev)}  className={`${showAddons ? "rotate-180" : ""} mt-1 cursor-pointer`} /></div>
                        <p className={`${className}`}>{pricingData?.add_ons_price_total}</p>
                    </div>

                    <div className={`${showAddons ? 'block' : "hidden"}`}>
                        {Object.keys(pricingData?.add_ons_price_key_detail)?.length > 0 && (
                           <>
                             {Object.keys(pricingData?.add_ons_price_key_detail)?.map((data, idx) => (
                                 <div key={idx}>
                                    <div  className='flex items-center justify-between ml-3 mt-1'>
                                        <p className={`${className}`}>{addonsHashMap[data]?.name} </p>
                                        <p className={`${className}`}>{pricingData?.add_ons_price_key_detail[data]}</p>
                                   </div>
                                 </div>
                             ))}
                           </>
                       )}
                    </div>

                    <div className='flex items-center justify-between mt-3'>
                    <div className='flex gap-x-2 items-center'> <p className={`${className} `}>Extra Services</p><ChevronDownIcon onClick={() => setShowServices((prev) => !prev)}  className={`${showServices ? "rotate-180" : ""} mt-1 cursor-pointer`} /></div>
                      <p className={`${className}`}>{pricingData?.extra_service_price_total}</p>
                    </div>
                    <div className={`${showServices ? 'block' : "hidden"}`}>
                        {Object.keys(pricingData?.extra_service_price_key_detail)?.length > 0 && (
                            <>
                              {Object.keys(pricingData?.extra_service_price_key_detail)?.map((data, idx) => (
                                 <div key={idx}>
                                     <div className='flex items-center justify-between ml-3 mt-1'>
                                        <p className={`${className}`}>{extraServicesHashMap[data]?.name} </p>
                                        <p className={`${className}`}>{pricingData?.extra_service_price_key_detail[data]}</p>
                                     </div>
                                 </div>
                              ))}
                            </>
                        )}
                    </div>

                    <div className='flex items-center justify-between mt-3'>
                    <div className='flex gap-x-2 items-center'> <p className={`${className} `}>Tax</p><ChevronDownIcon onClick={() => setShowTax((prev) => !prev)}  className={`${showTax ? "rotate-180" : ""} mt-1 cursor-pointer`} /></div>
                      <p className={`${className}`}>{pricingData?.tax_price_total}</p>
                    </div>

                    <div className={`${showTax ? 'block' : "hidden"}`}>
                        {Object.keys(pricingData?.tax_price_detail)?.length > 0 && (
                            <>
                                {Object.keys(pricingData?.tax_price_detail)?.map((data, idx) => (
                                    <div key={idx} className='flex items-center justify-between ml-3 mt-1'>
                                        <p className={`${className}`}>{data}</p>
                                        <p className={`${className}`}>{pricingData?.tax_price_detail[data]}</p>
                                    </div>
                                ))}
                            </>
                        )}
                    </div>


                    <hr className='mt-6 mb-3'/>
                    <h3 className='mb-2 font-bold'>Final Price</h3>
                    <div className='flex items-center justify-between'>
                        <p className={`${className} font-base`}>Total amount</p>
                        <p className={`${className} font-base`}>{pricingData?.final_price}</p>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Step2