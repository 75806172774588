const buttonTypes = {
    "primary": "bg-[#0097E6] text-white hover:bg-[#1976d2]",
    "secondary": "bg-[#E31C9E] text-white hover:bg-[#E31C9E]",
    "danger": "bg-[#D3232F] text-white hover:bg-[#D3232F]",
    "warning": "bg-[#FF8000] text-white hover:bg-[#FF8000]",
    "success": "bg-[#2CA01C] text-white hover:bg-[#108000]",
    "cancel": `bg-black2 text-white hover:bg-black1 `,
  };
  
  const buttonSizes = {
    "sm": "px-3 py-2",
    "lg": "px-4 py-3",
  };
  
  function Button(props) {
    const { type, value, size, onClick, className} = props;
    let backgroundColor = buttonTypes[type];
    let buttonSize = buttonSizes[size];
  
    return (
      <button
        className={` ${backgroundColor} ${buttonSize} cursor-pointer mb-1 rounded min-w-max ${className}`}
        onClick={() => onClick()}
      >
        {value}
      </button>
    );
  }
  export default Button;
  