import React, { useState } from 'react'
import Title from './title'
import Description from './description'
import Button from './button'
import Dialog from '../../ui/dialog'
import { DeleteIcon } from '../../icons'
import { INPUT_STYLE } from '../../constants'

function Ical({payload, setPayload, setUpdateIcal}) {
  const [icalPayload, setIcalPayload] = useState({label:'', name:'', url:''})
  const [openIcalCalendar, setOpenIcalCalendar] = useState(false)

//   https://rentmyhotel.com/api/v2/listing/calendar/ical
// listing_id
// : 
// "9260ef7d-dd5e-4356-a975-3c5eceac6f03"

// https://www.airbnb.co.in/calendar/ical/586559048174368356.ics?s=d575843fba31f7567c653360a388143b


const handleClose = () => {
  setOpenIcalCalendar((prev) => !prev);
  setIcalPayload({label:'', name:'', url:''})
}

const handleCreateIcal = () => {
  setPayload((prev) => ({...prev, ical:[...prev?.ical, icalPayload]}))
  setIcalPayload({label:'', name:'', url:''})
  setOpenIcalCalendar((prev) => !prev);
  setUpdateIcal(true)
}


const renderVendorNames =  [
  { id: 1, title: 'Airbnb', value: 'airbnb' },
  { id: 2, title: 'Booking.com', value: 'bookingdotcom' },
  { id: 4, title: 'Vrbo', value: 'vrbo' },
  { id: 4, title: 'Hostaway', value: 'hostaway' }
]

const vendorHashMap = {
  airbnb : "Airbnb",
  bookingdotcom: "Booking.com",
  vrbo: "Vrbo",
  hostaway:"Hostaway"
}


const renderIcalIcons = (name) => {
   switch (name) {
      case 'airbnb': 
       return <img src='/icons/ical/airbnb.png' className='w-10 h-10'/>

      case 'bookingdotcom':
        return <img src='/icons/ical/bookingdotcom.png' className='w-10 h-10' /> 

      case 'hostaway':
        return <img src= '/icons/ical/hostaway.png' className='w-10 h-10' /> 

      case 'vrbo':
        return <img src='/icons/ical/vrbo.png' className='w-10 h-10' /> 

      default: 
        return <img src='/icons/ical/airbnb.png' className='w-10 h-10'/> 
   }
}


const handleRemove = ( idx ) => {
  let icalData = [...payload?.ical]
  icalData.splice(idx, 1);
  setPayload((prev) => ({...prev, ical:icalData}))
  setUpdateIcal(true)
}

 




  return (
    <div>
        <div className='flex items-center justify-between'>
            <div>
              <Title>Ical</Title>
              <Description description="Keep track of availability by synchronizing calendars with other vacation rentals"/>
            </div>
            <Button onClick={() => setOpenIcalCalendar((prev) => !prev)}>Import Ical</Button>
        </div>


       <div className='w-1/2 mt-10'>
        {payload?.ical?.length > 0 && payload?.ical?.map((item, idx) => (
              <div className='flex items-center justify-between mt-7'>
                  <div key={idx} className='flex items-center gap-x-3'>
                      {renderIcalIcons(item?.name)}
                      <p className='text-lg font-bold '>{vendorHashMap[item?.name]}</p>
                    </div>

                  <DeleteIcon onClick={() => handleRemove(idx)}/>
              </div>
          ))}
       </div>

        

        <Dialog showCreateClick={false} title={"Import Ical"} isOpen={openIcalCalendar} closeModal={handleClose} childrenClass='bg-white w-[350px] p-2 rounded-md'>
          
          <div>
              <div className='mt-2'>
                 <label className='text-slate-700 mb-2'>Vendor Name</label>
                 <select className={`${INPUT_STYLE} mt-1`} value={icalPayload?.name} onChange={(e) => setIcalPayload((prev) => ({...prev, name:e.target.value}))}>
                    <option>Choose Vendor</option>
                    {renderVendorNames?.map((vendor, idx) =>  (
                      <option key={idx} value={vendor.value}>{vendor?.title}</option>
                    ))}
                 </select>
              </div>

              <div className='mt-6'>
                 <label className='text-slate-700 mb-2'>Source Name</label>
                 <input className={`${INPUT_STYLE} mt-1`} value={icalPayload?.label} onChange={(e) => setIcalPayload((prev) => ({...prev, label:e.target.value}))} />
              </div>


              <div className='mt-6'>
                 <label className='text-slate-700 mb-2'>Url</label>
                 <input className={`${INPUT_STYLE} mt-1`} value={icalPayload?.url} onChange={(e) => setIcalPayload((prev) => ({...prev, url:e.target.value}))} />
              </div>

          </div>

   


            <div className="flex items-center justify-between mt-10">
                  <Button onClick={() => setIcalPayload({label:'', name:'', url:''})}>Clear</Button>
                  <Button onClick={handleCreateIcal}>Add</Button>
            </div>
        </Dialog>
    </div>
  )
}

export default Ical