import { createSlice } from "@reduxjs/toolkit";

const initialState = { message: ""}

export const notificationsSlice = createSlice({
    name: "Notifications",
    initialState,
    reducers: {
        showNotifications: (state, actions) => {
            state.message = actions.payload
        },
        clearMessage: (state, action) => {
            state.message = ""
        }
    }
})

export const {showNotifications, clearMessage} = notificationsSlice.actions
export default notificationsSlice.reducer