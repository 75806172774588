import api from '../api'
import endPoints from './endPoints';

export async function getAllPets() {
  try {
    const response = await api.get(endPoints.getAllPets())
    return response
  }
  catch ( e ) {
    throw new Error( e )
  }
}

export async function postPet(body) {
  try {
    const response = await api.post(endPoints.createPet(), body)
    return response
  }
  catch ( e ) {
    throw new Error( e )
  }
}

export async function putPet(body) {
  try {
    const response = await api.put(endPoints.updatePet(), body)
    return response
  }
  catch ( e ) {
    throw new Error( e )
  }
}


export async function deletePet(id) {
  try {
    const response = await api.delete(endPoints.deletePet(id))
    return response
  }
  catch ( e ) {
    throw new Error( e )
  }
}

