// import Dialog from "../../ui/dialog";
import Button from "../button";

function CommonDeletePopup({ title, onClose, deleteObj, handleDeleteData }) {

  const handleDelete = () => {
    // onClose((prev) => !prev); // close popup function
    handleDeleteData(); // calling delete list function
    // setPageLoad((prev) => !prev)
  };


  return (
    <>
      <div
        className="fixed top-0 bottom-0 right-0 left-0 bg-transparent flex justify-center items-center"
        onClick={() =>  onClose((prev) => !prev)}
      >
        <div
          className="bg-[#FFFFFF] p-4 rounded-xl w-[500px] shadow border-2 border-gray-300 
        animate-popup "
          onClick={(e) => e.stopPropagation()}
        >
          <h3 className="text-xl mt-3 mb-5 font-bold leading-6 text-center text-red-900">
            {`Delete ${title}`}
          </h3>
          <div className="mt-5 ml-5 w-100%] flex flex-col text-md font-mono ">
            
            {deleteObj && Object.keys(deleteObj).map((eachKey, index) => {
              if (eachKey.toLocaleLowerCase() !== "id") {
                return (
                  <div className="flex" key={index}>
                    <p className="font-bold text-[#B8495B] w-[100px]">
                      {eachKey}
                    </p>
                    <p className="text-black font-[400] pl-2">{`: ${deleteObj[eachKey]}`}</p>
                  </div>
                );
              }
            })}
          </div>
          <p className="mt-5 ml-5 font-semibold text-[16px]">Are You Sure?</p>
          <div className="flex justify-between">
            <Button
              onClick={() =>  onClose((prev) => !prev)}
              className="m-5 shadow rounded-full sm:w-[100px] "
              value="Cancel"
              type="black"
            />
            <Button
              onClick={handleDelete}
              className="m-5 shadow rounded-full sm:w-[100px] text-[17px] font-bold"
              value="Delete"
              type="cancel"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default CommonDeletePopup;
