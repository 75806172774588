import api from '../api'
import endPoints from './endPoints';

export async function getAllPlatFormFee() {
    try {
      const response = await api.get(endPoints.getAllPlatFormFee())
      if ( response.data.error ) {
        throw new Error( response?.data?.message)
      }
      return response?.data?.data
    }
    catch ( e ) {
      throw new Error( e )
    }
}


export async function createPlatFormFee(body) {
    try {
      const response = await api.post(endPoints.createPlatFormFee(), body)
      if ( response.data.error ) {
        throw new Error( response?.data?.message)
      }
      return response?.data?.data
    }
    catch ( e ) {
      throw new Error( e )
    }
}


export async function updatePlatFormFee(body) {
    try {
      const response = await api.put(endPoints.updatePlatFormFee(), body)
      if ( response.data.error ) {
        throw new Error( response?.data?.message)
      }
      return response?.data?.data
    }
    catch ( e ) {
      throw new Error( e )
    }
}


export async function deletePlatFormFee(id) {
    try {
      const response = await api.delete(endPoints.deletePlatFormFee(id))
      if ( response.data.error ) {
        throw new Error( response?.data?.message)
      }
      return response?.data?.data
    }
    catch ( e ) {
      throw new Error( e )
    }
}


