import React from "react";
import {  Transition } from "@headlessui/react";
import { Fragment, useState, useEffect } from "react";
import { Combobox } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/solid";

const INPUT_CLASS =
  "w-full placeholder:italic placeholder:text-slate-500 block bg-white border border-slate-300 rounded-md py-2 pl-4 pr-3 shadow-sm focus:outline-none focus:border-red-300 focus:ring-red-300 focus:ring-1 sm:text-sm";
export function MyComboBox({
  data,
  title,
  myKey,
  propertyId,
  newData,
  setNewData,
}) {


  const [selected, setSelected] = useState({[title]: "select From the list",});
  const [query, setQuery] = useState("");

  console.log('query', query)

  useEffect(() => {
    let selectedProperty;
    if (propertyId) {
      selectedProperty = data.filter((property) => property.listing_id == propertyId);

      setSelected(selectedProperty[0]);
    }
  }, []);

  useEffect(() => {
    getInfo();
  }, [selected]);

  
  const filteredData =
    query === ""
      ? data
      : data?.filter((singleData) =>
          singleData[title]
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  function getInfo() {
    // console.log('get info', selected)
    setNewData({ ...newData, [myKey]: selected[myKey] });
  }

  return (
    <div className={`w-[100%]`}>
      <Combobox value={selected} onChange={setSelected}>
        <div className="relative  mt-1">
          <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
            <Combobox.Input
              className={`${INPUT_CLASS}`}
              displayValue={(data) => `${data[title]}`}
              onChange={(event) => console.log(setQuery(event.target.value))}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon
                className="h-5 w-5 text-red-300"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery("")}
          >
            <Combobox.Options className="absolute mt-1 z-10 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredData?.length === 0 && query !== "" ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                filteredData?.map((data, idx) => (
                  <Combobox.Option
                    key={idx}
                    className={({ active }) =>
                      `relative cursor-default select-none py-3  pl-10 pr-4 ${
                        active ? "bg-teal-600 text-white" : "text-gray-900"
                      } rounded`
                    }
                    value={data}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {data[`${title}`]}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? "text-white" : "text-teal-600"
                            }`}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
}

export default MyComboBox;
