import api from '../api'
import endPoints from './endPoints';

export async function getAmentiesGroup() {
  try {
    const response = await api.get(endPoints.getAllAmenitiesGroup())
    return response
  }
  catch ( e ) {
    throw new Error( e )
  }
}

export async function getAmentiesGroupQuery() {
  try {
    const response = await api.get(endPoints.getAllAmenitiesGroupQuery())
    return response
  }
  catch ( e ) {
    throw new Error( e )
  }

}

export async function createAmentiesGroup(body) {
  try {
    const response = await api.post(endPoints.createAmenitiesGroup(), body)
    return response
  }
  catch ( e ) {
    throw new Error( e )
  }

}

export async function updateAmentiesGroup(body) {
  try {
    const response = await api.put(endPoints.updateAmenitiesGroup(), body)
    return response
  }
  catch ( e ) {
    throw new Error( e )
  }

}

export async function deleteAmentiesGroup(id) {
  try {
    const response = await api.delete(endPoints.deleteAmenitiesGroup(id))
    return response
  }
  catch ( e ) {
    throw new Error( e )
  }

}
