import React, { useEffect, useState } from "react";
import Title from "../title";
import Button2 from "../button";
import Dialog from "../../../ui/dialog";
import ShortDescriptionForm from "./ShortDescriptionForm";
import DeletePopup from "../../popup/deletePopup";
import FormInputs from "../../popup/formInputs";

const ShortDescription = ({ payload, setPayload }) => {
  const [pageLoad, setPageLoad] = useState(false);
  const [pop, setPop] = useState({
    isCreate: false,
    isEdit: false,
    isDelete: false,
  });

  const [deletedObj, setDeletedObj] = useState(null);
  const [edit, setEditData] = useState([]);

  useEffect(() => {
    // console.log("useEffect");
  }, [payload]);

  const hidePopup = (item) => {
    if (item === "Create") setPop({ ...pop, isCreate: false });
    if (item === "Edit") setPop({ ...pop, isEdit: false });
    if (item === "Delete") setPop({ ...pop, isDelete: false });
  };
  const visibleCreatePopup = () => {
    // console.log("open--", pop);
    let object = {
      name: payload.short_description.name,
      description: payload.short_description.description,
      items: [
        {
          name: "",
          image: "",
          description: "",
          sequence: 1,
        },
      ],
    };
    if (
      payload.short_description &&
      payload.short_description.items &&
      payload.short_description.items.length >= 1
    ) {
      console.log("if-condition-------------");
      object = payload.short_description;
    }
    setEditData(object);
    setPop((prev) => ({ ...prev, isCreate: true }));
  };

  const setUpdateHandler = (index) => {
    // console.log(payload.room_arrangement[index]);
    const object = payload.ShortDescription[index];
    // console.log(object)
    setEditData({ ...object, index });
    setPop((prev) => ({ ...prev, isEdit: true }));
    // console.log(object);
  };

  const deleteHandler = () => {
    const index = deletedObj.index;
    // console.log(index,payload.room_arrangement);
    setPayload((prev) => {
      prev.ShortDescription.splice(index, 1);
      // console.log(prev.room_arrangement)
      return prev;
    });
    setPageLoad((prev) => !prev);
  };

  console.log(payload);
  return (
    <div className="">
      <div className="flex items-center justify-between">
        <Title>Short Description</Title>
        <Button2 onClick={visibleCreatePopup}>Add</Button2>
      </div>
      {/* all room_arrangement list */}
      <div className="mt-10">
        <div className="">
          <FormInputs
            type="text"
            label="Heading"
            labelStyle="text-lg font-semibold"
            className="text-lg "
            value={payload.short_description.name}
            onChange={(e) => {
              setPayload((prev) => ({
                ...prev,
                short_description: {
                  ...prev.short_description,
                  name: e.target.value,
                },
              }));
            }}
          />
          <FormInputs
            type="textarea"
            label="Description"
            labelStyle="text-lg font-semibold"
            className="text-lg"
            value={payload.short_description.description}
            onChange={(e) => {
              setPayload((prev) => ({
                ...prev,
                short_description: {
                  ...prev.short_description,
                  description: e.target.value,
                },
              }));
            }}
          />
        </div>
        <div className="flex gap-2 flex-wrap mt-8">
          {payload.short_description &&
            payload.short_description.items &&
            payload.short_description.items.map((item, index) => (
              <div
                key={`${item.name}${item.sequence}`}
                className="p-4 m-2 flex w-[400px] border rounded-lg"
              >
                <div className="w-[20%] flex items-center">
                  <img
                    className="w-10 h-10"
                    src={`${process.env.REACT_APP_CDN_URL}${item.image}`}
                    alt={item.name}
                  />
                </div>
                <div className="w-[70%] flex flex-col">
                  <span className="text-lg font-medium">{item.name}</span>
                  <span>{item.description}</span>
                </div>
              </div>
            ))}
        </div>
      </div>

      {/* //Delete popup */}
      {pop.isDelete && (
        <DeletePopup
          deletedObj={deletedObj}
          setIsOpen={setPop}
          // close={handleClose}
          title="Room-Arrangements"
          onDeleteList={deleteHandler}
        />
      )}

      <Dialog
        closeModal={() => hidePopup("Create")}
        isOpen={pop.isCreate}
        createClick={pop.isCreate}
        childrenClass="bg-white p-5 rounded-lg w-[50%]"
      >
        <ShortDescriptionForm
          payload={payload}
          eventType="Create"
          setPop={setPop}
          setPayload={setPayload}
          //   editData={}
          editData={edit}
        />
      </Dialog>
      {/* <Dialog
        closeModal={() => hidePopup("Edit")}
        isOpen={pop.isEdit}
        createClick={pop.isEdit}
        childrenClass="bg-white p-5 rounded-lg w-[50%]"
      >
        <ShortDescriptionForm
          payload={payload}
          eventType="Edit"
          setPop={setPop}
          setPayload={setPayload}
          editData={edit}
          // fun={fun}
          // setShowUploadDialog={setShowUploadDialog}
        />
      </Dialog> */}
    </div>
  );
};

export default ShortDescription;
