/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  loaderStart,
  loaderSuccess,
  error,
} from "../../../store/reducers/loaderSlice";
import { useDispatch } from "react-redux";
import { DeleteIcon, EditIcon } from "../../../icons";
import DeletePopup from "../../../components/popup/deletePopup";
import { formatDate } from "../../../utils/common";
import {getDestinationsData,  createDestination,  deleteDestination, updateDestination } from "../../../services/destinationsServices";
import PageHeader from "../../../components/pageHeader";
import Table from "../../../components/hkTable";
import Dialog from "../../../ui/dialog";
import DestinationForm from "./destinationForm";
import CommonDeletePopup from "../../../components/popup/commonDeletePopup";
import { useCountryHashMapSelector, useRegionsHashMapSelector } from "../../../store/selectors";

const TABLE_COLUMNS = [
  {
    fieldName: "id",
    headName: "ID",
    sort: false,
    filter: false,
    className: "w-[150px]",
    filterFormat: "array",
    inputType: "text",
    hideColumn: false,
  },
  {
    fieldName: "name",
    headName: "Name",
    filter: false,
    sort: false,
    className: "w-[200px]",
    filterFormat: "string",
    inputType: "text",
    hideColumn: false,
  },
  {
    fieldName: "slug",
    headName: "Slug",
    filter: false,
    className: "w-[200px]",
    filterFormat: "array",
    inputType: "text",
    hideColumn: false,
  },
  {
    fieldName: "grandparent_id",
    headName: "Grand Parent",
    filter: false,
    className: "w-[200px]",
    filterFormat: "array",
    inputType: "text",
    hideColumn: false,
  },
  {
    fieldName: "grandparent_type",
    headName: "Grand Parent Type",
    filter: false,
    className: "w-[200px]",
    filterFormat: "array",
    inputType: "text",
    hideColumn: false,
  },
  {
    fieldName: "parent_id",
    headName: "parent",
    filter: false,
    className: "w-[200px]",
    filterFormat: "array",
    inputType: "text",
    hideColumn: false,
  },
  {
    fieldName: "parent_type",
    headName: "Parent Type",
    filter: false,
    className: "w-[200px]",
    filterFormat: "array",
    inputType: "text",
    hideColumn: false,
  },
  {
    fieldName: "description",
    headName: "Description",
    filter: false,
    className: "w-[200px]",
    filterFormat: "array",
    inputType: "number",
    hideColumn: false,
  },
  {
    fieldName: "sequence_no",
    headName: "Sequence No",
    filter: false,
    className: "w-[200px]",
    filterFormat: "array",
    inputType: "number",
    hideColumn: false,
  },
  {
    fieldName: "is_active",
    headName: "Is Active",
    filter: false,
    className: "w-[150px]",
    filterFormat: "string",
    inputType: "select",
    hideColumn: false,
  },
  {
    fieldName: "is_deleted",
    headName: "Is Deleted",
    filter: false,
    className: "w-[150px]",
    filterFormat: "string",
    inputType: "select",
    hideColumn: false,
  },
  {
    fieldName: "created_by",
    headName: "Created By",
    filter: false,
    className: "w-[150px]",
    filterFormat: "string",
    inputType: "select",
    hideColumn: false,
  },
  {
    fieldName: "createdAt",
    headName: "Created At",
    filter: false,
    className: "w-[200px]",
    filterFormat: "string",
    inputType: "text",
    hideColumn: false,
  },
  {
    fieldName: "updatedAt",
    headName: "Updated At",
    filter: false,
    className: "w-[200px]",
    filterFormat: "string",
    inputType: "text",
    hideColumn: false,
  },
  {
    fieldName: "edit",
    headName: "Edit",
    filter: false,
    className: "w-[100px]",
    filterFormat: "string",
    inputType: "text",
    hideColumn: false,
  },
  {
    fieldName: "delete",
    headName: "Delete",
    filter: false,
    className: "w-[100px]",
    filterFormat: "string",
    inputType: "text",
    hideColumn: false,
  },
];






function Destinations() {
  const dispatch = useDispatch();
  const [rows, setRows] = useState();
  const [pageLoad, setPageLoad] = useState(false);
  const [actionType, setActionType] = useState('CREATE');
  const [isOpen, setIsOpen] = useState(false);
  const [deleteObj, setDeleteObj] = useState({});
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [payload, setPayload] = useState({parent_id:'', grandparent_id:'', grandparent_type:'country', parent_type:'region', name:'', slug:'', description:'', sequence_no:'', icon_path:''});


  const countryHashMap = useCountryHashMapSelector()
  const regionsHashMap = useRegionsHashMapSelector()

  useEffect(() => {
    getData();
  }, [pageLoad]);

  async function getData(){
    try {
      dispatch(loaderStart())
      const response = await getDestinationsData();
      let result = transformRows(response)
      setRows(result)
      dispatch(loaderSuccess())
    }
    catch (e) {
      dispatch(error(e?.message))
    }
 }

 function transformRows(response) {
  return response?.map(res => ({
    ...res,
    edit: getEditComponent(res),
    delete: getDeleteComponent(res),
    grandparent_id: <GetGrandParentComponent id={res?.grandparent_id}/>,
    parent_id: <GetParentComponent id={res?.parent_id}/>,
    is_deleted: <GetBooleonJsx type={res?.is_deleted}/>,
    is_active: <GetBooleonJsx type={res?.is_active}/>,
    name: <GetNameComponent name={res?.name} icon_path={res?.icon_path}/>
  }))
}

function GetNameComponent({name, icon_path}) {
   return (
     <div className="flex">
        <img src={`${process.env.REACT_APP_CDN_URL}${icon_path}`} alt={`${name}`} className="w-[20px] h-[20px] mr-1 self-center"/>
        <p className="self-center">{name}</p>
     </div>
   )
}

function GetGrandParentComponent({id}) {
   return <> {countryHashMap[id]?.name}
   </>
}

function GetParentComponent({id}) {
  return <>
  
  {regionsHashMap[id]?.name}
  </>
}

function GetBooleonJsx({type}) {
  return (
    <span>{type ? "True": "False"}</span>
   )
}

function getEditComponent(item) {
  return (
    <div onClick={() => handleOpenEditDialog(item)}><EditIcon /></div>
  );
}

function getDeleteComponent(item) {
  return (<div onClick={() => handleOpenDeleteDialog(item)}><DeleteIcon/></div>);
}


function handleOpenDeleteDialog(item) {
  const { id, name, slug } = item
  setDeleteObj({id, name, slug});
  setShowDeleteDialog((prev) =>  !prev)
}

function handleOpenCreateDialog() {
  setPayload({parent_id:'', grandparent_id:'', grandparent_type:'country', parent_type:'region', name:'', slug:'', description:'', sequence_no:'', icon_path:''})
  setActionType('CREATE')
  setIsOpen((prev) => !prev)
}

function handleOpenEditDialog( item ) {
  const {id, parent_id, grandparent_id, grandparent_type, parent_type, name, slug, description, sequence_no, icon_path} = item
  setPayload({id, parent_id, grandparent_id, grandparent_type, parent_type, name, slug, description, sequence_no, icon_path});
  setIsOpen((prev) => !prev)
  setActionType('UPDATE')
}

const handleCloseDialog = () => {
  setPayload({parent_id:'', grandparent_id:'', grandparent_type:'country', parent_type:'region', name:'', slug:'', description:'', sequence_no:'', icon_path:''})
  setIsOpen((prev) => !prev)
  setActionType('CREATE')
}


const handleAction = async () => {
  if ( actionType === 'CREATE') {
    console.log('aaa', actionType)
    await handleCreate()
  }
  else {
    await handleEdit()
  }
}


async function handleEdit() {
  try {
      // let body = {parent_id:payload?.parent_id, grandparent_id:payload?.grandparent_id, grandparent_type:payload?.grandparent_type, parent_type:payload?.parent_type, name:payload?.name, slug:payload?.slug, description:payload?.description, sequence_no:payload?.sequence_no, icon_path:payload?.icon_path}
      dispatch(loaderStart())
      const response = await updateDestination( payload );
      dispatch(loaderSuccess())
      setIsOpen((prev) => !prev)
      setPageLoad((prev) => !prev)
  } 
  catch (e) {
    dispatch(e?.message)
  }
}

async function handleCreate() {
  try {
    await validatePayload( payload )
    dispatch(loaderStart())
    const response = await createDestination( payload )
    dispatch(loaderSuccess())
    setIsOpen((prev) => !prev)
    setPageLoad((prev) => !prev)
  }
  catch (e) {
    dispatch(error(e?.message))
  }
}

async function validatePayload( payload ) {
 
  // id, parent_id, grandparent_id, grandparent_type, parent_type, name, slug, description, sequence_no, icon_path
 
 
 if ( !payload?.grandparent_id ) {
   throw new Error('Please Select Country')
 }
 if ( !payload?.parent_id ) {
  throw new Error('Please Select Region')
}
 if ( !payload?.name ) {
   throw new Error('Please Give Destination Name')
 }
 if ( !payload?.slug ) {
   throw new Error('Please Give Slug Name')
 }
 
 
  
}



async function handleDelete() {
  try {
    dispatch(loaderStart())
    const response = await deleteDestination( deleteObj?.id );
    dispatch(loaderSuccess())
    setPageLoad((prev) => !prev)
    setShowDeleteDialog((prev) => !prev)
  }
  catch (e) {
    dispatch(e?.message)
  }
}




  return (
    <>
      <div className="m-3">
        <PageHeader title="Destination" onClick={handleOpenCreateDialog} />
        
        {rows && (
          <Table 
           rows={rows}
            columns={TABLE_COLUMNS}
            filter={false}
            pagiNationFilter={false}
          />
        )}
      </div>

      
      <Dialog closeModal={handleCloseDialog} isOpen={isOpen} createClick={actionType === 'CREATE'}  childrenClass={"w-[50%] p-6 rounded-md no-scrollbar dark-scrollbar"}>
          <DestinationForm 
            actionType={actionType} 
            payload={payload} 
            setPayload={setPayload} 
            handleAction={handleAction} 
            handleCloseDialog={handleCloseDialog}
          />
      </Dialog>


      {showDeleteDialog && (
          <CommonDeletePopup
            title={"Destination"}
            deleteObj={deleteObj}
            handleDeleteData={handleDelete}
            setPageLoad={setPageLoad}
            onClose={() => setShowDeleteDialog((prev) => !prev)}
          />
        )
      }  
    </>
  );
}

export default Destinations;
