import api from '../api'
import endPoints from './endPoints'

export const getAllActivitiesAttractionMapping = async () => {
   try {
      const response = await api.get(endPoints.getAllActivitiesAttractionMapping())
      if ( response.data.error ) {
        throw new Error( response.data.message)
      }
      return response.data.data
   }
   catch ( e ) {
    throw new Error( e )
   }
}

export const createActivitiesAttractionMapping = async ( body ) => {
    try {
        const response = await api.post(endPoints.createActivitiesAttractionMapping(), body )
        if ( response.data.error ) {
            throw new Error( response?.data?.message)
        }
        return response?.data?.data
    }
    catch ( e ) {
        throw new Error( e )
    }
}

export const updateActivitiesAttractionMapping = async ( id, body ) => {
    try {
        const response = await api.put(endPoints.updateActivitiesAttractionMapping(id), body);
        if ( response.data.error ) {
            throw new Error( response?.data?.message)
        }
        return response?.data?.data
    }
    catch ( e ) {
        throw new Error( e )
    }
}

export const deleteActivitiesAttractionMapping = async ( id ) => {
    try {
        const response = await api.delete(endPoints.deleteActivitiesAttractionMapping(id))
        if ( response.data.error ) {
            throw new Error( response?.data?.message)
        }
        return response?.data?.data
    }
    catch ( e ) {
        throw new Error( e );
    }
}
 