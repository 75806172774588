import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { loaderStart,loaderSuccess, error, } from "../../store/reducers/loaderSlice";
import { formatDate } from "../../utils/common";
import { getAllCheckout,} from "../../services/checkoutServices";
import { useNavigate } from "react-router-dom";
import Table from "../../components/hkTable";
import PageHeader from "../../components/pageHeader";
import Dialog from "../../ui/dialog";
import CheckoutCreateForm from "../../components/forms/checkout";
import Button from "../../components/button";
// import Button from "../../components/button";

const TABLE_COLUMNS = [
  {
    fieldName: "id",
    headName: "ID",
    sort: false,
    filter: false,
    className: "w-[100px]",
    filterFormat: "array",
    inputType: "text",
    hideColumn: false,
  },
  {
    fieldName: "reservation_id",
    headName: "Booking ID",
    filter: false,
    className: "w-[200px]",
    filterFormat: "string",
    inputType: "text",
    hideColumn: false,
  },
  {
    fieldName: "fname",
    headName: "First name",
    filter: false,
    className: "w-[200px]",
    filterFormat: "array",
    inputType: "text",
    hideColumn: false,
  },
  {
    fieldName: "lname",
    headName: "Last name",
    filter: false,
    className: "w-[200px]",
    filterFormat: "array",
    inputType: "number",
    hideColumn: false,
  },
  {
    fieldName: "email",
    headName: "Email",
    filter: false,
    className: "w-[200px]",
    filterFormat: "range",
    inputType: "text",
    hideColumn: false,
  },
  {
    fieldName: "mobile",
    headName: "Mobile",
    filter: false,
    className: "w-[200px]",
    filterFormat: "string",
    inputType: "text",
    hideColumn: false,
  },
  {
    fieldName: "checkin_dt",
    headName: "checkin Date",
    filter: false,
    className: "w-[200px]",
    filterFormat: "string",
    inputType: "text",
    hideColumn: false,
  },
  {
    fieldName: "checkout_dt",
    headName: "Checkout Date",
    filter: false,
    className: "w-[200px]",
    filterFormat: "string",
    inputType: "text",
    hideColumn: false,
  },
  {
    fieldName: "total_amount",
    headName: "Total Amount",
    filter: false,
    className: "w-[200px]",
    filterFormat: "string",
    inputType: "text",
    hideColumn: false,
  },
  {
    fieldName: "amount_paid",
    headName: "Amount Paid",
    filter: false,
    className: "w-[200px]",
    filterFormat: "string",
    inputType: "text",
    hideColumn: false,
  },
  {
    fieldName: "balance_amount",
    headName: "Balance Amount",
    filter: false,
    className: "w-[200px]",
    filterFormat: "string",
    inputType: "text",
    hideColumn: false,
  },
 
  {
    fieldName: "eid",
    headName: "E-ID",
    filter: false,
    sort: false,
    className: "w-[200px]",
    filterFormat: "string",
    inputType: "text",
    hideColumn: false,
  },
  {
    fieldName: "listing_id",
    headName: "Listing ID",
    filter: false,
    className: "w-[200px]",
    filterFormat: "string",
    inputType: "text",
    hideColumn: false,
  },
  {
    fieldName: "host_id",
    headName: "Host ID",
    filter: false,
    className: "w-[150px]",
    filterFormat: "string",
    inputType: "text",
    hideColumn: false,
  },
  {
    fieldName: "is_deleted",
    headName: "Deleted",
    filter: false,
    className: "w-[150px]",
    filterFormat: "string",
    inputType: "text",
    hideColumn: false,
  },
  {
    fieldName: "created_at",
    headName: "Created At",
    filter: false,
    className: "w-[200px]",
    filterFormat: "string",
    inputType: "text",
    hideColumn: false,
  },
  {
    fieldName: "updated_at",
    headName: "Updated At",
    filter: false,
    className: "w-[200px]",
    filterFormat: "string",
    inputType: "text",
    hideColumn: false,
  },
];


function Checkout() {
  const [rows, setRows] = useState();
  const [pageLoad, setPageLoad] = useState(false);
  const [body, setBody] = useState({sort: { col: "id", orderby: "desc" },pagination: { page: 1, limit: 20 }, filters: []})
  const [isOpen, setIsOpen] = useState( false );
  
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, [pageLoad, body]);

  //get checkout data
  async function getData() {
    try {
      dispatch(loaderStart());
      const response = await getAllCheckout( body )
      let res = response.data;
      let result = transformRows(res);
      setRows(result);
      dispatch(loaderSuccess());
    }
    catch (e) {
      dispatch(error(e?.message));
    }
  }

  //tranforms rows
  function transformRows( response ) {
      return response?.map((res) => ({
       ...res, 
       created_at: formatDate(res.created_at),
       updated_at: formatDate(res.updated_at),
       checkin_dt: formatDate(res.checkin_dt),
       checkout_dt: formatDate(res.checkout_dt),
       id: <GetIdComponent id={res?.id} eid={res?.eid}/>,
       mobile: <GetMobileComponent  phone_ext={res.phone_ext} mobile={res.mobile} />
      }))
  }

  //navigate to checkout detail page
  function handleNavigate(id) {
    if ( id ) {
      navigate(`/checkout/${id}`);
    }
  }

  //transform mobile value
  function GetMobileComponent({phone_ext, mobile}) {
    return ( <div>{mobile ? phone_ext + " " + mobile : ""}</div>);
  }

  //transform checkout id 
  function GetIdComponent({id, eid}) {
    return (
      <div onClick={() => handleNavigate(eid)} className={` text-blue-500 cursor-pointer ${!eid && 'text-slate-400 !cursor-not-allowed'}`}>{id}</div>
    );
  }



  const handleClose = () => {
    setPageLoad((prev) => !prev)
    setIsOpen((prev) => !prev)
  }

  const handleStepsFilter = (step) => {
    
    // setBody((prev) => ({...prev, filters:[...prev.filters, {col:"steps_completed", val:step, type:"string"}]}))
  }

 

  return (
    <div className="m-3">
      <PageHeader title="Checkout" onClick={() => setIsOpen((prev) => !prev)} />

      {/* <div>
         <button onClick={() => handleStepsFilter(1)}>New</button>
         <button>Completed</button>
         <button>Empty</button>
      </div> */}

      {rows && (
        <Table
          setQueryBody={setBody}
          queryBody={body}
          rows={rows}
          columns={TABLE_COLUMNS}
          filter={false}
          pagiNationFilter={true}
        />
      )}

      <Dialog showCreateClick={false} closeModal={handleClose} isOpen={isOpen} createClick={isOpen}  childrenClass={"w-[50%] h-[70%] rounded-md overflow-y-scroll "}>
        <CheckoutCreateForm />
      </Dialog>
    </div>
  );
}

export default Checkout;
