import React from 'react'
import  { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { DeleteIcon, EditIcon } from "../../../icons";
import {  error,  loaderStart,  loaderSuccess,} from "../../../store/reducers/loaderSlice";
import { formatCreatedAt } from "../../../utils/common";
import PageHeader from "../../../components/pageHeader";
import Table from "../../../components/hkTable";
import Dialog from "../../../ui/dialog";
import {getProcessingFee, deleteProcessingFee, updateProcessingFee, createProcessingFee} from '../../../services/processingFeeServices'
import { useCountryHashMapSelector } from '../../../store/selectors';
import CommonDeletePopup from '../../../components/popup/commonDeletePopup';
import { showNotifications } from '../../../store/reducers/notificationsSlice';
import ProcessingFeeForm from '../../../components/forms/listingAttributes/processingFeeForm';
import { validateProcessingFee } from '../../../validators/processingFee';

const TABLE_COLUMNS = [
    {
      fieldName: "id",
      headName: "ID",
      sort: false,
      filter: false,
      className: "w-[200px]",
      filterFormat: "array",
      inputType: "text",
      hideColumn: false,
    },
    {
      fieldName: "country_id",
      headName: "Country",
      filter: false,
      sort: false,
      className: "w-[150px]",
      filterFormat: "string",
      inputType: "text",
      hideColumn: false,
    },
    {
      fieldName: "name",
      headName: "Name",
      filter: false,
      className: "w-[150px]",
      filterFormat: "array",
      inputType: "text",
      hideColumn: false,
    },
    {
      fieldName: "slug",
      headName: "Slug",
      filter: false,
      className: "w-[150px]",
      filterFormat: "string",
      inputType: "select",

      hideColumn: false,
    },
    {
      fieldName: "fee_type",
      headName: "Fee type",
      filter: false,
      className: "w-[150px]",
      filterFormat: "string",
      inputType: "select",
      hideColumn: false,
    },
    {
      fieldName: "currency",
      headName: "Currency",
      filter: false,
      className: "w-[150px]",
      filterFormat: "string",
      inputType: "select",
      hideColumn: false,
    },
    {
      fieldName: "fee_amount",
      headName: "Fee amount",
      filter: false,
      className: "w-[150px]",
      filterFormat: "string",
      inputType: "text",
      hideColumn: false,
    },
    {
      fieldName: "is_active",
      headName: "Active",
      filter: false,
      className: "w-[100px]",
      filterFormat: "string",
      inputType: "text",
      hideColumn: false,
    },
    {
        fieldName: "is_deleted",
        headName: "Deleted",
        filter: false,
        className: "w-[100px]",
        filterFormat: "string",
        inputType: "text",
        hideColumn: false,
      },
      {
        fieldName: "edit",
        headName: "Edit",
        filter: false,
        className: "w-[100px]",
        filterFormat: "string",
        inputType: "text",
        // selectData: [{ New: 1 }, { Completed: 2 }, { Empty: 0 }],
        hideColumn: false,
      },
      {
        fieldName: "delete",
        headName: "Delete",
        filter: false,
        className: "w-[100px]",
        filterFormat: "string",
        inputType: "text",
        // selectData: [{ New: 1 }, { Completed: 2 }, { Empty: 0 }],
        hideColumn: false,
      },
];


function ProcessingFee() {
    const [rows, setRows] = useState();
    const [pageLoad, setPageLoad] = useState(false);
    const [payload, setPayload] = useState({country_id:"", name:"", slug:"", fee_type:'', fee_amount:0, currency:'USD'});
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false)
    const [deleteObj, setDeleteObj] = useState({})
    const [actionType, setActionType] = useState('CREATE')

    const dispatch = useDispatch();
    const contryHashMap = useCountryHashMapSelector()

    useEffect(() => {
        getData();
    }, [pageLoad]);

    async function getData() {
        try {
            dispatch(loaderStart());
            const response = await getProcessingFee()
            dispatch(loaderSuccess());
            let res = response;
            let result = transformRows(res);
            setRows(result);
        }
        catch (err) {
            dispatch(error(err?.response?.data?.error?.message));
        }
    }

    function transformRows(response) {
      return response?.map(res => ({
        ...res,
        edit: <GetEditComponent item={res}/>,
        delete: <GetDeleteComponent item={res}/>,
        per_person: <GetBooleonJsx type={res?.per_person}/>,
        per_night: <GetBooleonJsx type={res?.per_night}/>,
        is_deleted: <GetBooleonJsx type={res?.is_deleted}/>,
        is_active: <GetBooleonJsx type={res?.is_active}/>,
        country_id: <GetCountryName country_id={res?.country_id}/>,
        createdAt: formatCreatedAt(res?.createdAt),
        updatedAt:formatCreatedAt(res?.updatedAt),
      }))
   }

  function GetCountryName({country_id}) {
    return <>{contryHashMap[country_id]?.name}</>
  }

  function GetBooleonJsx({type}) {
    return (
      <span>{type ? "True": "False"}</span>
     )
  }

   function GetBooleonJsx({type}) {
    return (
      <span>{type ? "True": "False"}</span>
    )
  }



    function GetEditComponent({item}) {
        return (
        
        <EditIcon onClick={() => handleOpenEditDialog(item)}/>
      
        );
    }
    
    function GetDeleteComponent({item}) {
        return (
          <DeleteIcon onClick={() => handleOpenDeleteDialog(item)}/>
        );
    }
  

    //OPEN DIALOG BOXES
    const handleOpenCreateDialog = () => {
      setPayload({country_id:"", name:"", slug:"", fee_type:'', fee_amount:0, currency:'USD'})
      setActionType('CREATE')
      setIsOpen((prev) => !prev)
    };

    const handleOpenEditDialog = ( item ) => {
      const {country_id, name, slug, fee_type, id, fee_amount,  currency} = item
      setPayload({country_id, name, slug, id, fee_type, fee_amount, currency});
      setIsOpen((prev) => !prev)
      setActionType('UPDATE')
    }

    function handleOpenDeleteDialog( item ) {
      const {id, country_id, name} = item 
      setDeleteObj({id, country:contryHashMap[country_id]?.name, name, })
      setIsOpenDeleteDialog((prev) => !prev)
    }

    const handleCloseDialog = () => {
      setIsOpen((prev) => !prev)
      setActionType('CREATE')
    }

    const handleAction = async () => {
      if ( actionType === 'CREATE') {
        await handleCreate()
      }
      else {
        await handleEdit()
      }
    }

    async function handleCreate() {
      try {
        dispatch(loaderStart())
        validateProcessingFee(payload)
        const response = await createProcessingFee( payload )
        dispatch(loaderSuccess())
        setIsOpen((prev) => !prev)
        setPageLoad((prev) => !prev)
        dispatch(showNotifications('Created Successfully'))
      }
      catch (e) {
        dispatch(error(e?.message))
      }
    }

    async function handleEdit() {
      try {
          dispatch(loaderStart())
          validateProcessingFee(payload)
          const response = await updateProcessingFee( payload );
          dispatch(loaderSuccess())
          setIsOpen((prev) => !prev)
          setPageLoad((prev) => !prev)
          dispatch(showNotifications('Updated Successfully'))

      } 
      catch (e) {
        dispatch(error(e?.message))
      }
    }

    async function handleDelete() {
      try {
        dispatch(loaderStart())
        const response = await deleteProcessingFee( deleteObj?.id );
        dispatch(loaderSuccess())
        setPageLoad((prev) => !prev)
        setIsOpenDeleteDialog((prev) => !prev)
        dispatch(showNotifications('Deleted Successfully'))
      }
      catch (e) {
        dispatch(error(e?.message))
      }
    }


  return (
    <div className='m-3'>
        <PageHeader title="Processing Fee" onClick={handleOpenCreateDialog} />
        {rows && (
            <Table
            rows={rows}
            columns={TABLE_COLUMNS}
            filter={false}
            pagiNationFilter={false}
            />
        )}

      <Dialog createClick={actionType === 'CREATE'} isOpen={isOpen} closeModal={handleCloseDialog} childrenClass={"w-[50%] p-6 rounded-md overflow-y-scroll"}>
          <ProcessingFeeForm actionType={actionType} payload={payload} setPayload={setPayload} handleAction={handleAction} handleCloseDialog={handleCloseDialog} />
      </Dialog>

        {isOpenDeleteDialog && (
          <CommonDeletePopup
            title={'Processing Fee'}
            deleteObj={deleteObj}
            handleDeleteData={handleDelete}
            onClose={() => setIsOpenDeleteDialog((prev) => !prev)}
          />
        )
        } 

    </div>
  )
}

export default ProcessingFee