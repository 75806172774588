import React from 'react'
import { formatBreakUpDates } from '../../../utils/common'

function PriceBreakUp({priceObj, className=""}) {
  return (
      <>
        {Object.keys(priceObj)?.length > 0 && (
            <div>
                {Object.keys(priceObj)?.map((priceKey, idx) => (
                    <div key={idx} className='flex justify-between items-center ml-3'>
                       <span className={className}>{formatBreakUpDates(priceKey)}</span>
                       <span className={className}>{priceObj[priceKey]}</span>
                    </div>
                ))}
            </div>
        )}
      </>
  )
}

export default PriceBreakUp