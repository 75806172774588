import api from '../api'
import endPoints from './endPoints';

export  const getAllCoupons = async () => {
  try {
    const response = await api.get(endPoints.getAllCoupons())
    return response
  }
  catch ( e ) {
    throw new Error( e )
  }
}

export const createCoupon = async (body) => {
  try {
    const response = await api.post(endPoints.createCoupon(), body)
    return response
  }
  catch ( e ) {
    throw new Error( e )
  }
}

export const updateCoupon = async (body) => {
  try {
    const response = await api.put(endPoints.updateCoupon(), body)
    return response
  }
  catch ( e ) {
    throw new Error( e )
  }
}
 

export const deleteCoupon = async (id) => {
  try {
    const response = await api.delete(endPoints.deleteCoupon(id))
    return response
  }
  catch ( e ) {
    throw new Error( e )
  }
}
