import React from "react";
import Header from "./header";
import Body from "./body";
import Pagination from "./pagination";
import Filter from "./filter";

 {/* h-[430px] max-h-[500px] */}
function Table({ rows, columns, filter, pagiNationFilter, setQueryBody, queryBody }) {
  return (
    <div>
      <div>
        {filter && <Filter columns={columns} />}{" "}
        {/* <table className="block border-collapse">
            <thead className="block  px-4 bg-[#F2F2F2] p-[16px] h-[54px]">
              <tr className=" block  w-full">
               <Header columns={columns} />
              </tr>
            </thead>
        </table> */}
        <div className="overflow-x-auto scrollbar-hide ">
          <div className="min-w-fit relative">
          <Header columns={columns} />
            <div className="overflow-y-scroll overflow-hidden dark-scrollbar !mb-5 ">
           
              <Body rows={rows} columns={columns} />
            </div>
          </div>
        </div>
      </div>
      {pagiNationFilter && (
        <div className="mb-5">
          <Pagination setQueryBody={setQueryBody} queryBody={queryBody} />
        </div>
      )}
    </div>
  );
}

export default Table;
