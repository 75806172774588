import React from "react";
import Button from "../button";

const PageHeader = ({onClick,title,titleClassName,buttonName = "Create", className="", showCreate=true}) => (
  <div className={`flex justify-between items-center m-3 mt-6 ${className}`}>
    <h1 className="text-2xl font-semibold">{title}</h1>
    {showCreate &&  <Button
      onClick={onClick}
      className={`m-3 sm:w-[100px] ${titleClassName}`}
      value={buttonName}
      type="black"
    />}
   
  </div>
);

export default PageHeader;
