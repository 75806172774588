import api from '../api'
import endPoints from "./endPoints";

export async  function getAllReservations( body ) {
  try {
    const data = await api.post(endPoints.getAllReservations(), body);
    return data
  }
  catch(e) {
    throw new Error(e?.response?.data?.error?.message)
  }
}

export async  function getAllReservationById( id ) {
  try {
    const data = await api.get(endPoints.getAllReservationsById(id));
    return data
  }
  catch(e) {
    throw new Error(e?.response?.data?.error?.message)
  }
}

export async function createReservations( body ) {
  try {
    const data = await api.post(endPoints.createReservation(), body);
    return data
 }
 catch(e) {
   throw new Error(e?.response?.data?.error?.message)
 }
}

export async function updateReservations(body, id) {
  try {
    const data = await api.put(endPoints.updateReservation(id), body);
    return data
 }
 catch(e) {
   throw new Error(e?.response?.data?.error?.message)
 }
}

export async function deleteReservations(id) {
  try {
    const data = await api.delete(endPoints.deleteReservation(id));
    return data
 }
 catch(e) {
   throw new Error(e?.response?.data?.error?.message)
 }
}



