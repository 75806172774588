export  function validateProcessingFee( payload ) {
    if ( !payload?.country_id ) {
      throw new Error('Please select Country')
    }
    if ( !payload?.name ) {
      throw new Error('Required Processing Fee Name')
    }
    if ( !payload?.slug ) {
      throw new Error('Required slug name')
    }
    if ( !payload?.fee_type ) {
      throw new Error('Please Select Fee type ')
    }
    if ( !payload?.fee_amount ) {
      throw new Error('Required Fee Amount')
    }
    if ( !payload?.currency ) {
      throw new Error('Please Select Curreny')
    }
}