import React, { useEffect, useRef, useState } from 'react';
import { ChevronDownIcon } from '../../../icons';
import { INPUT_STYLE } from '../../../constants';
import Checkbox from '../../input/checkbox';
import { useAddonsHashMapSelector } from '../../../store/selectors';
import { formatCurrency } from '../../../utils/common';

// function useOutsideAlerter(ref, callback) {
//     useEffect(() => {
//         function handleClickOutside(event) {
//             if (ref.current && !ref.current.contains(event.target)) {
//                 callback();
//             }
//         }

//         document.addEventListener("mousedown", handleClickOutside);
//         return () => {
//             document.removeEventListener("mousedown", handleClickOutside);
//         };
//     }, []);

// }





const Addons = ({ payload, setPayload, addons }) => {
    const [open, setOpen] = useState(false);
    const dropDownRef = useRef(null);
    // useOutsideAlerter(dropDownRef, () => setOpen(false));

    const addonsHashMap = useAddonsHashMapSelector()

//handle addonns checkout box 
function handleAddons( id ) {
    const index = payload?.addons?.indexOf( id );
    if (index === -1) {
        setPayload((prev) => ({...prev, addons:[...prev.addons, id]}))
    }
    else {
        let updateIds = [...payload?.addons];
        updateIds.splice(index, 1);
        setPayload((prev) => ({...prev, addons:updateIds}))
    }
}
  
    return (
        <div className='flex relative flex-col'>

            <div  className={`relative h-[50px] w-full ${INPUT_STYLE}`}>
                <p className='self-center mt-2'>Select Addons</p>
                <div className='absolute right-[10px] top-[50px] translate-y-[-50%] mt-[-25px] cursor-pointer'>
                    <ChevronDownIcon onClick={() => setOpen((prev) => !prev)} size={20} className={`${open ? "rotate-180" : ""}`} />
                </div>
            </div>

            {open && (
                <div  className={`dropdown z-10 mt-[50px] drop-shadow-lg  px-4 py-4 max-h-[290px] bg-white overflow-y-auto absolute w-full`}>
                    {addons?.length > 0 && (
                <div className='mt-2'>
                        {addons?.map((addon, idx) => (
                            <div key={idx} className='flex items-center justify-between my-2'>
                                <div className='flex items-center gap-x-3'>
                                    <Checkbox checked={payload?.addons?.includes(addon?.id)} onChange={() => handleAddons(addon?.id)}/>
                                    <p className='!font-bold text-lg'> {addonsHashMap[addon?.id]?.name}</p>
                                </div>
                                <p className='font-medium text-base'>{formatCurrency(payload?.options?.currency, addon?.amount)}</p>
                            </div>
                        ))}
                    </div>
                )
               }
                </div>
            )}
        </div>
    );
};

export default Addons;
