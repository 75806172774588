  //validations
 
//step 1 validation
export  function validateStepOnePayload( payload ) {
    if ( !payload?.listing_id ) {
      throw new Error('Please select Property')
    }
    if ( !payload?.checkin ) {
      throw new Error('Please select Checkin Date')
    }
    if ( !payload?.checkout ) {
      throw new Error('Please select Checkin Date')
    }
    if ( !payload?.options?.currency ) {
      throw new Error('Please select currency')
    }
    if ( !payload?.overrides?.base_price ) {
      throw new Error('Base price is required')
    }
    if ( !payload?.overrides?.security_deposit ) {
      throw new Error('Security deposit is required')
    }
    if ( !payload?.overrides?.weekend_price ) {
      throw new Error('Weekend price is required')
    }
}

//step 2 validation
export function validateStepTwoPayload( payload ) {
    if ( !payload?.step ) {
      throw new Error('Step is missing')
    }
    if ( !payload?.fname ) {
      throw new Error( 'First Name is required' )
    }
    if ( !payload?.lname ) {
      throw new Error( 'Last Name is required' )
    }
    if ( !payload?.email ) {
      throw new Error( 'Email is required' )
    }
    if ( !payload?.mobile ) {
      throw new Error( 'Mobile is required' )
    }
    if ( !payload?.phone_ext ) {
      throw new Error( 'Phone ext is required' )
    }
}


//step 3 validation
export function validateStepThreePayload( payload ) {
    if ( !payload?.agreement_status ) {
        //if value is zero or empty
        throw new Error( `agreement required` );
    };
    if ( !payload?.step ) {
      throw new Error('Step is missing')
    }
}


export function validateMakePayment(payload, checkoutData) {
  if ( !payload.vendor ) {
     throw new Error('Payment vendor is required')
  }
  if (!payload.payment_method) {
   throw new Error('Payment Method is required')
  }
  if (!payload.meta.part_payment) {
   throw new Error('Payment type is required')
  }
  if (!payload.currency) {
   throw new Error('Curreny is required')
  }
  if (!payload.amount) {
   throw new Error('Amount is required')
  }
  if ( payload.amount > checkoutData.balance_amount ){
     throw new Error('Amount cannot be greater than balance amount')
  }
}