import api from '../api'
import endPoints from './endPoints';

export  const getAllExtraServices = async () => {
  try  {
    const response = await api.get(endPoints.getAllExtraServices());
    return response
  }
  catch (e) {
    throw new Error(e?.message)
  }
}

export const createExtraService = async ( body ) => { 
  try  {
    const response = await api.post(endPoints.createExtraServices(), body );
    return response
  }
  catch (e) {
    throw new Error(e?.message)
  }
}

export const updateExtraService = async ( body ) => {
  try  {
    const response = await api.put(endPoints.updateExtraServices(), body);
    return response
  }
  catch (e) {
    throw new Error(e?.message)
  }
};

export const deleteExtraService = async (id) => {
  try  {
    const response = await api.delete(endPoints.deleteExtraServices(id));
    return response
  }
  catch (e) {
    throw new Error(e?.message)
  }
}
 


