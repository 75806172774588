import api from '../api'
import endPoints from './endPoints';

export async function getAllTax() {
  try {
    const response = await api.get(endPoints.getAllTaxes())
    return response
  }
  catch ( e ) {
    throw new Error( e )
  }
}

export async function postTax(body) {
  try {
    const response = await api.post(endPoints.createTax(), body)
    return response
  }
  catch ( e ) {
    throw new Error( e )
  }
}

export async function putTax(body) {
  try {
    const response = await api.put(endPoints.updateTax(), body)
    return response
  }
  catch ( e ) {
    throw new Error( e )
  }
}

export async function deleteTax(id) {
  try {
    const response = await api.delete(endPoints.deleteTax(id))
    return response
  }
  catch ( e ) {
    throw new Error( e )
  }
}


