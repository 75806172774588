import api from '../api'
import endPoints from './endPoints'

export function getAllActivities() {
  try {
    const response = api.get(endPoints.getAllActivities());
    return response 
  }
  catch (e) {
    throw new Error(e)
  }
}

export function deleteActivities(id) {
  try {
    const response = api.delete(endPoints.deleteActivities(id));
    return response
  }
  catch (e) {
    throw new Error(e)
  }
}


export async function createActivities(data) {
  try {
    const response = await api.post( endPoints.createActivities(), data);
    if ( response.data.error ) {
      throw new Error( response.data.message)
    }
  }
  catch (e) {
    throw new Error( e )
  }

}

export async function updateActivities(data) {
  try {
    const response = await api.put( endPoints.updateActivities(),  data );
    if ( response.error ) {
      throw new Error( response.message)
    }
  }
  catch (e) {
    throw new Error( e)
  }
  
}

