import React, { useState, useEffect } from "react";
// import Dialog from "../../../../ui/dialog";
import Button from "../../../button";
import { useDispatch } from "react-redux";
import {
  error,
  loaderStart,
  loaderSuccess,
} from "../../../../store/reducers/loaderSlice";
import FormInputs from "../../../popup/formInputs";
import UploadImage from "../../../../ui/input/uploadImage";
import {  putFaqs } from "../../../../services/faqsServices";
import Input from "../../../popup/Input";

const FORM_LIST = [
  {
    id: 1,
    type: "text",
    label: "Title",
    apiKey: "title",
    optionsList: "",
  },
  {
    id: 2,
    type: "text",
    label: "Question",
    apiKey: "question",
    optionsList: "",
  },
  {
    id: 3,
    type: "text",
    label: "Answer",
    apiKey: "answer",
    optionsList: "",
    // className: "w-[85%] my-2",
  },
  {
    id: 4,
    type: "url",
    label: "Link",
    apiKey: "link",
    optionsList: "",
    // className: "w-[85%] my-2",
  },
];

const DetailsPageForm = ({
  updateData,
  btn,
  setPageLoad,
  hidePopup,
  id,
  name,
  isEdit,
  createClick,
  object,
  handleRequest,
}) => {
  const resetFormData = {
    title: "",
    faqs: [
      {
        question: "",
        answer: "",
        link: "",
      },
    ],
    // sequence: 1,
    //  _id: generateUUID(),
  };
  const [formList, setFormList] = useState(resetFormData);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(updateData);
  const [showUploadDialog, setShowUploadDialog] = useState(false);

  // console.log(updateData);
  //  const filledInputFields = () => {
  //   }

  const editApiHandler = async () => {
    // const { id, name, slug, icon_path } = formData;
    dispatch(loaderStart());
    try {
      const prevData = object.details.filter((elm) => elm._id !== formData._id);
      const obj = {
        details: [formList, ...prevData],
      };
      // console.log(formData._id)
      // console.log("obj---------", obj);
      // console.log("formList---------", formList);
      handleRequest(obj, id);
      // const response = await putFaqs(obj, id);
      setPageLoad((prev) => !prev);
      dispatch(loaderSuccess());
    } catch (e) {
      dispatch(error(e?.response?.data?.error?.message));
    }
  };

  const createApiHandler = async () => {
    dispatch(loaderStart());

    try {
      // const res = await getFaqsById(id);
      // console.group(res.data.data.details);
      const prevData = object.details;
      const obj = {
        details: [
          ...prevData,
          formList,
        ],
      };

      // console.log("==========", formList);
      // console.log("obj--" , obj)
      console.log("create-api fun", "obj--", obj);
      handleRequest(obj, id);
      // const response = await putFaqs(obj, id);
      setPageLoad((prev) => !prev);
      dispatch(loaderSuccess());
    } catch (error) {
      dispatch(error(error?.response?.data?.error?.message));
    }
  };

  const setNewFormData = (index, fieldName, value) => {
    const formDetails = clone({ ...formList });
    formDetails.faqs[index][fieldName] = value;
    // console.log(formList.details[index][fieldName]);
    setFormList(formDetails);
    // console.log(formDetails)
  };
  // console.log(formData)

  const clone = (item) => structuredClone(item);

  const removeFormFields = (index) => {
    console.log("removeFormFields", index);
    setFormList((prev) => {
      const newFaqs = prev.faqs.filter((elm, inx) => inx !== index);
      console.log(newFaqs);
      return { ...prev, faqs: newFaqs };
    });
    // console.log(formList);
  };

  const addFormFields = () => {
    const formDetails = clone(...resetFormData.faqs);
    // formDetails.sequence = formDetails.sequence + 1;
    // formDetails.push({...formDetails});
    //  formDetails?.push({
    //    ...resetFormData.details[0],
    //    sequence: formDetails.reduce((a, b) => {
    //      return b.sequence > a ? b.sequence + 1 : a + 1;
    //    }, 1),
    //  });
    // console.log({...formList})
    setFormList((prev) => {
      prev.faqs.push(formDetails);
      // console.log(prev.details);
      return { title: prev.title, faqs: prev.faqs };
    });
    console.log(formList);
  };
  // console.log(formList)
  useEffect(() => {
    setFormList(updateData);
    // console.log(updateData)
  }, []);

  return (
    <div>
      {true && (
        <form className="max-h-[400px]">
          <div>
            <FormInputs
              type="text"
              label="Title"
              className="w-[100%]"
              value={formList.title}
              onChange={(e) => {
                e.preventDefault();
                setFormList({ ...formList, title: e.target.value });
              }}
            />
            {formList?.faqs?.map((eachItem, index) => (
              <div key={index} className="flex flex-col gap-4 my-2">
                <div className="flex justify-between items-center">
                  <div className="mt-2">item - {index + 1}</div>
                  {formList && formList.faqs.length > 1&& <div
                    onClick={() => removeFormFields(index)}
                    className="cursor-pointer"
                  >
                    ❌
                  </div>}
                </div>
                <div className="flex justify-between flex-wrap">
                  <FormInputs
                    type="text"
                    label="Question"
                    value={formList.faqs[index].question}
                    className="w-[49%]"
                    onChange={(e) => {
                      e.preventDefault();
                      setNewFormData(index, "question", e.target.value);
                    }}
                  />
                  <FormInputs
                    type="text"
                    label="Answer"
                    value={formList.faqs[index].answer}
                    className="w-[49%]"
                    onChange={(e) =>
                      setNewFormData(index, "answer", e.target.value)
                    }
                  />
                </div>
                <div className="flex justify-between flex-wrap">
                  <FormInputs
                    type="text"
                    label="Link"
                    value={formList.faqs[index].link}
                    className="w-[49%]"
                    onChange={(e) =>
                      setNewFormData(index, "link", e.target.value)
                    }
                  />
                </div>
              </div>
            ))}
            {/* <div className="flex self-center justify-end">
              <Button
                buttonType="button"
                // onClick={() => setShowUploadDialog((prev) => !prev)}
                type="secondary"
                value="Upload"
                className="mt-8"
              />
            </div> */}
          </div>
          <div className="flex justify-end mt-4">
            <Button
              className="p-2"
              type="secondary"
              value={"Add"}
              onClick={(e) => {
                e.preventDefault();
                addFormFields();
                // const t = formList;
              }}
            />
          </div>
          <div className="flex justify-between mt-4">
            <Button
              className="p-2"
              type="outline"
              value={"Cancel"}
              onClick={hidePopup}
            />
            {btn.isEdit && (
              <Button
                className="p-2"
                type="secondary"
                value={"Update"}
                onClick={(e) => {
                  hidePopup();
                  editApiHandler();
                  e.preventDefault();
                }}
              />
            )}
            {btn.isCreate && (
              <Button
                className="p-2"
                type="secondary"
                value={"Create"}
                onClick={(e) => {
                  hidePopup();
                  createApiHandler();
                  e.preventDefault();
                }}
              />
            )}
          </div>
        </form>
      )}
      {/* <Dialog
        parentClass={"no-scrollbar"}
        childrenClass="w-[400px] h-[280px]"
        showCreateClick={false}
        isOpen={showUploadDialog}
        closeModal={() => setShowUploadDialog((prev) => !prev)}
      >
        <UploadImage
          setShowUploadDialog={setShowUploadDialog}
          setIconPath={(iconPath) =>
            setFormData({ ...formData, icon_path: iconPath })
          }
          title={formData.name}
        />
      </Dialog> */}
    </div>
  );
};

export default DetailsPageForm;
