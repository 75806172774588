import React, { useState, useEffect } from 'react'
import PageHeader from '../../components/pageHeader';
import { deletePage, getAllPages } from '../../services/pageConfig';
import {
    loaderStart,
    loaderSuccess,
    error,
  } from "../../store/reducers/loaderSlice";
import Table from '../../components/hkTable';
import { useDispatch } from 'react-redux';
import Dialog from '../../ui/dialog';
import MainPageConfigForm from '../../components/forms/pageConfig1/mainPageForms';
import { useNavigate } from 'react-router-dom';
import { DeleteIcon, EditIcon } from '../../icons';
import CommonDeletePopup from '../../components/popup/commonDeletePopup';
import { showNotifications } from '../../store/reducers/notificationsSlice';



const TABLE_COLUMNS = [
    {
      fieldName: "id",
      headName: "ID",
      sort: false,
      filter: false,
      className: "w-[200px]",
      filterFormat: "array",
      inputType: "text",
      hideColumn: false,
    },
    {
      fieldName: "slug",
      headName: "Slug Name",
      filter: false,
      sort: false,
      className: "w-[200px]",
      filterFormat: "string",
      inputType: "text",
      hideColumn: false,
    },
    {
      fieldName: "page_type",
      headName: "Page Type",
      filter: false,
      sort: false,
      className: "w-[200px]",
      filterFormat: "string",
      inputType: "text",
      hideColumn: false,
    },
    {
      fieldName: "country_ref_id",
      headName: "Country Reference Id",
      filter: false,
      className: "w-[200px]",
      filterFormat: "array",
      inputType: "text",
      hideColumn: false,
    },
    {
      fieldName: "region_ref_id",
      headName: "Region Reference Id",
      filter: false,
      className: "w-[200px]",
      filterFormat: "array",
      inputType: "text",
      hideColumn: false,
    },
    {
      fieldName: "destination_ref_id",
      headName: "Destination Reference Id",
      filter: false,
      className: "w-[200px]",
      filterFormat: "array",
      inputType: "text",
      hideColumn: false,
    },
    {
      fieldName: "default_currency",
      headName: "Currency",
      filter: false,
      className: "w-[200px]",
      filterFormat: "array",
      inputType: "text",
      hideColumn: false,
    },
  
    {
      fieldName: "created_by",
      headName: "Created By",
      filter: false,
      className: "w-[200px]",
      filterFormat: "string",
      inputType: "select",
      hideColumn: false,
    },
  
    {
      fieldName: "created_at",
      headName: "Created At",
      filter: false,
      className: "w-[200px]",
      filterFormat: "string",
      inputType: "text",
      hideColumn: false,
    },
    {
      fieldName: "updated_at",
      headName: "Updated At",
      filter: false,
      className: "w-[200px]",
      filterFormat: "string",
      inputType: "text",
      hideColumn: false,
    },
    {
      fieldName: "edit",
      headName: "Edit",
      filter: false,
      className: "w-[100px]",
      filterFormat: "string",
      inputType: "text",
      hideColumn: false,
    },
    {
      fieldName: "delete",
      headName: "Delete",
      filter: false,
      className: "w-[100px]",
      filterFormat: "string",
      inputType: "text",
      hideColumn: false,
    },
];

function PageConfig11() {
    const [rows, setRows] = useState();
    const [pageLoad, setPageLoad] = useState(false);
    const [isOpen, setIsOpen] = useState( false );
    const [action, setAction] = useState( 'create' );
    const [editPayload, setEditPayload] = useState({});
    const [id, setId]  = useState(null)
    const [deleteObj, setShowDeleteObj]  = useState({})
    const [showDeletePopup, setShowDeletePopup] = useState(false)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        getData();
    }, [pageLoad]);

    async function getData() {
      try {
        dispatch(loaderStart());
        const response = await getAllPages()
        dispatch(loaderSuccess());
        let res = response.data;
        let result = transformRows(res);
        setRows(result);
      }
      catch ( e ) {
        dispatch(error(e?.response?.data?.error?.message));
      }
    }
    
    function transformRows( response ) {
      return response?.map(res => ({
        ...res,
        id: <GetIdComponent id={res?.id}/>,
        edit: <GetEditComponent item={res}/>,
        delete: <GetDeleteComponent  item={res}/>
      }))
    }


    function GetIdComponent({ id }) {
      const handleNavigate = () => {
        navigate(`/page-config/${id}`)
      }

      return (
        <div onClick={handleNavigate} className='text-blue-500'>{id}</div>
      )
    }



    function GetEditComponent({item}) {
      return (
        <EditIcon onClick={() => handleEdit(item)}/>
      )
    }

    function GetDeleteComponent({item}) {
      return (
        <DeleteIcon onClick={() => handleDelete(item)}/>
      )
    }


  //edit
  function handleEdit( item ) {
    let refKey = `${item?.page_type}_ref_id`
    setEditPayload({page_type:item?.page_type, [refKey]: item[refKey], slug:item?.slug, default_currency:item.default_currency})
    setId(item?.id)
    setAction('edit')
    setIsOpen((prev) => !prev);
  }

  //delete
  function handleDelete( item ) {
    setId(item.id)
    setShowDeleteObj({page_type:item?.page_type, slug:item?.slug})
    setShowDeletePopup((prev) => !prev)
  }

  async function handleDeleteData() {
    try {
      dispatch(loaderStart())
      const data = await deletePage( id )
      setShowDeletePopup((prev) => !prev); 
      dispatch(loaderSuccess())
      setPageLoad((prev) => !prev)
      dispatch(showNotifications('Deleted Successfully'))
    }
    catch (e) {
      dispatch(error(e?.message))
    }
  }

  const handleOpen = () => {
    setAction('create')
    setIsOpen((prev) => !prev)
  }

    
  return (
    <div className='m-3'>
      <PageHeader title="Page Config" onClick={handleOpen} />
      
      {rows && (
        <Table
          rows={rows}
          columns={TABLE_COLUMNS}
          filter={false}
          pagiNationFilter={false}
        />
      )}

      {/* EDIT AND CREATE */}
      <Dialog  childrenClass={"w-[50%] p-6 rounded-md no-scrollbar dark-scrollbar"} showCreateClick={false} isOpen={isOpen} closeModal={() => setIsOpen((prev) => !prev) }>
        <MainPageConfigForm action={action} editId={id} initialEditState={editPayload} onClose={setIsOpen} setPageLoad={setPageLoad} />
      </Dialog>

      {showDeletePopup && (
          <CommonDeletePopup
          title={'Page Config'}
          deleteObj={deleteObj}
          handleDeleteData={handleDeleteData}
          setPageLoad={setPageLoad}
          onClose={setShowDeletePopup}
          />
      )}

    </div>
  )
}

export default PageConfig11