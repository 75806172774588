import React, {useState } from "react";
import Dialog from "../../../ui/dialog";
import UploadImage from "../../../ui/input/uploadImage";
// import { INPUT_STYLE } from '../../../../constants';
import {  useCountrySelector, useRegionsSelector } from "../../../store/selectors";
import { INPUT_STYLE } from "../../../constants";


function DestinationForm({actionType, payload, setPayload, handleAction, handleCloseDialog}) {

  const [showUploadDialog, setShowUploadDialog] = useState(false);

  const countriesData = useCountrySelector()
  // const countryHashMap = useCountryHashMapSelector()

  const regionsData = useRegionsSelector()
  // const regionsHashMap = useRegionsHashMapSelector()

  
  const handleOnChange = (e, key) => {
      let value = e.target.value 
      if (key === 'grandparent_id') {
        setPayload((prev) => ({...prev, [key]:value, parent_id:'', grandparent_type:'country', parent_type:'region'}))
      }
      else {
        setPayload((prev) => ({...prev, [key]:value, grandparent_type:'country', parent_type:'region'}))
      }
      
  }

  const handleIcon = ( url ) => {
    setPayload((prev) => ({...prev, icon_path:url}))
  }


  return (
    <>
    <div className="flex justify-between flex-wrap">

      <div className="flex flex-col w-[49%]">
          <label>Choose Country</label>
          <select className={INPUT_STYLE} value={payload?.grandparent_id} onChange={(e) => handleOnChange(e, 'grandparent_id')}>
             <option value="">Choose Country</option>
             {countriesData?.map((country, idx) => (
              <option key={idx} value={country?.id}>{country?.name}</option>
             ))}
          </select>
      </div>

      <div className="flex flex-col  w-[49%]">
         <label>Choose Region</label>
         <select className={INPUT_STYLE} value={payload?.parent_id} onChange={(e) => handleOnChange(e, 'parent_id')}>
           <option value="">Choose Region</option>
           {regionsData?.filter(region => region?.parent_id === payload?.grandparent_id).map((data, idx) => (
            <option key={idx} value={data?.id}>{data?.name}</option>
           ))}
           {/* {regionsData?.map((data, idx) => (
            <option key={idx} value={data?.id}>{data?.name}</option>
           ))} */}
         </select>
      </div>

      <div className="flex flex-col  w-[49%]">
           <label>Name</label>
           <input className={INPUT_STYLE} value={payload?.name} onChange={(e) => handleOnChange(e, 'name')}/>
      </div>

      <div className="flex flex-col  w-[49%]">
           <label>Slug</label>
           <input className={INPUT_STYLE} value={payload?.slug} onChange={(e) => handleOnChange(e, 'slug')}/>
      </div>

      <div className="flex flex-col  w-[49%]">
          <label>Description</label>
          <textarea className={INPUT_STYLE} value={payload?.description} onChange={(e) => handleOnChange(e, 'description')}></textarea>
      </div>

      <div className="flex flex-col  w-[49%]">
          <label>Sequence No</label>
          <input onWheel={(e) => e.target.blur()} type="number" className={INPUT_STYLE} value={payload?.sequence_no} onChange={(e) => handleOnChange(e, 'sequence_no')}/>
      </div>

      <div className="flex flex-col  w-[49%]">
          <label>IconPath</label>
           <div>
           <input value={payload?.icon_path} onChange={(e) => handleOnChange(e, 'icon_path')}   className={`${INPUT_STYLE} cursor-not-allowed !bg-slate-100`}/>
           <button onClick={() => setShowUploadDialog((prev) => !prev)}>Upload</button>
           </div>
      </div>


      <Dialog parentClass={"no-scrollbar"} childrenClass='w-[400px] h-[280px]' showCreateClick={false} isOpen={showUploadDialog} closeModal={() => setShowUploadDialog((prev) => !prev)}>
        <UploadImage setShowUploadDialog={setShowUploadDialog} setIconPath={handleIcon} title={payload?.name} />
      </Dialog>

    </div>

    <div className="mt-10 flex items-center justify-between">
      <button className='border border-black rounded-md px-3 py-2 hover:bg-black hover:text-white' onClick={handleCloseDialog}>Close</button>
      <button className='bg-black text-white px-3 py-2 rounded-md' onClick={handleAction}>{actionType === 'CREATE' ? 'Create': 'Update'}</button>
    </div>
   </>
  );
}
export default DestinationForm;
