import api from '../api'
import endPoints from './endPoints';

export async function getAllCategories() {
  try {
    const response = await api.get(endPoints.getAllCategories())
    return response
  }
  catch ( e ) {
    throw new Error( e )
  }
}
export async function createCategory(body) {
  try {
    const response = await api.post(endPoints.createCategory(), body)
    return response
  }
  catch ( e ) {
    throw new Error( e )
  }
}

export async function updateCategory(body) {
  try {
    const response = await api.put(endPoints.updateCategory(), body)
    return response
  }
  catch ( e ) {
    throw new Error( e )
  }
}

export async function deleteCategory(id) {
  try {
    const response = await api.delete(endPoints.deleteCategory(id))
    return response
  }
  catch ( e ) {
    throw new Error( e )
  }
}


