import React, { useEffect, useRef, useState } from 'react'
import PhotosUpload from '../../ui/input/photos'
import { getImagesQuery } from '../../services/uploads'

function Photos( { payload, setPayload } ) {
  const [images, setImages] = useState()

  const dragStartIndex = useRef(null);
  const dragEndIndex = useRef(null);


  useEffect(() => {
    getData()
  }, [payload?.media]);


  async function getData() {
    const imageIds = payload.media.map(item => item.id) || [];
    try {
     let body =  {filters: [ {col:"id", val:imageIds, type: "array"} ] }
     const response = await getImagesQuery(body);
     setImages(response.data)
    }
    catch ( e ) {
     console.log(e)
    }
  }
  
  const handleUpdateSequence = () => {
    let data = [...payload.media]
    console.log(dragStartIndex, dragEndIndex)
    const removeEndIndex = data.splice(dragStartIndex.current, 1)[0];
    data.splice(dragEndIndex.current, 0, removeEndIndex);
    data = data.map((item, idx) => ({...item, sequence: idx + 1}));
    setPayload((prev) => ({...prev, media:data}))
  }

  console.log('payload', payload)

  return (
    <div>
        <PhotosUpload setPayload={setPayload}/>

        <div className='flex items-center gap-x-3'>
        {payload.media.fi}
        {images && images.map((image, idx) => (
          <div 
            draggable
            onDragStart={() => dragStartIndex.current = idx}
            onDragEnter={() => dragEndIndex.current = idx}
            onDragEnd={handleUpdateSequence}
            onDragOver={(e) => e.preventDefault()}
            key={idx} className='mt-10'>
            {image.id}
            <img className='w-[200px] h-[200px]' src={`${process.env.REACT_APP_LISTING_URL}${image.file_path}`}/>
          </div>
        ))}
        </div>
    </div>
  )
}

export default Photos