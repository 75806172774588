import React, { useEffect, useState } from 'react'
import Input from '../../popup/Input';
// import { INPUT_STYLE } from '../../../constants';
import Calendar from '../../../ui/calender';
import SelectDropdown from '../../dropdown/index';
import Guests from './guests';
import { getAllListings } from '../../../services/listingsServices';
// import { useAddonsHashMapSelector } from '../../../store/selectors';
// import Checkbox from '../../input/checkbox';
import { INPUT_STYLE } from '../../../constants';
import Addons from './addons';

const Step1 = ({ payload, setPayload }) => {
    const [listings, setListings] = useState([]);
    const [addons, setAddons] = useState([]);
    // const addonsHashMap = useAddonsHashMapSelector()
    
    useEffect(() => {
        getListingData();
    }, []);

    //get all listing for options data
    const getListingData = async () => {
        try {
            const response = await getAllListings();
            setListings(response.data.data);
        } 
        catch (error) {
            console.log(error);
        }
    };

    //listing onchange
    const handleOnChangeChange = (listingId) => {
        let findListing = listings?.find(item => item?.listing_id === listingId);
        setAddons(findListing?.add_ons)
        let base_price = findListing?.basic_pricing?.base_price
        let security_deposit = findListing?.basic_pricing?.security_deposit
        let weekend_price = findListing?.basic_pricing?.weekend_price
        setPayload((prev) => ({...prev, listing_id:findListing?.listing_id, overrides:{...prev?.overrides, base_price, security_deposit, weekend_price}}))
    }

    //listings dropdown options
    let ListingId = listings.map((each) => {
        const { title, listing_id } = each;
        return { value: title, id: listing_id };
    });
    
    
   //overides onChanges
    function handleOveridesOnChanges(e, key) {
       let value = e.target.value
        // Regex to check if the input is a valid number or empty (to allow clearing the input)
        if (/^\d*\.?\d*$/.test(value) || value === "") {
            switch (key) {
                case 'base_price':
                    setPayload((prev) => ({...prev, overrides:{...prev.overrides, base_price:+value}}));
                    break
    
                case 'security_deposit':
                    setPayload((prev) => ({...prev, overrides:{...prev.overrides, security_deposit:+value}}));
                    break
    
                case 'weekend_price':
                    setPayload((prev) => ({...prev, overrides:{...prev.overrides, weekend_price:+value}}));
                    break 
    
                default:
                    break
            }
        }
    }

   


    return (
        <div className='px-4 py-4'>
             <h1 className='text-gray-900 text-[18px] mb-4 font-bold text-center'>STEP-1</h1>
            <div className='flex items-center justify-center w-full gap-x-3 '>
            <div className=' w-full'>
              <div className="flex gap-2">
                <SelectDropdown
                    options={ListingId}
                    onChange={(item) => handleOnChangeChange(item)}
                    placeholder='Search Items'
                    label={"Listing ID"}
                    className={"w-full z-10"}
                />
               </div>

               <div className="flex flex-col z-10 gap-2 mt-4">
                <Guests payload={payload} setPayload={setPayload} />
               </div>

              <div className="flex flex-col z-10 gap-2 mt-4">
                 <Addons addons={addons} payload={payload} setPayload={setPayload}/>
              </div>

             
                <div className='border mt-4 rounded-md'>
                    <Calendar
                        numberOfMonths={2}
                        startDate={payload?.checkin}
                        endDate={payload?.checkout}
                        setStartDate={(value) => setPayload((prev) => { return { ...prev, checkin: value } })}
                        setEndDate={(value) => setPayload((prev) => { return { ...prev, checkout: value } })}
                    />
                   <div className='flex items-center justify-end mx-2'> <button className='cursor-pointer border rounded-md p-1 mb-2 border-black' onClick={() => setPayload((prev) => { return { ...prev, checkin:null, checkout:null } })}>Reset</button></div>
                </div>

                <div className='flex gap-2 mt-4'>
                    <Input
                        value={payload.options.currency}
                        onChange={(e) => setPayload(prev => { return { ...prev, options: { ...prev.option, currency: e.target.value.split(" ")[0] } } })}
                        required={true}
                        ReadInput={`w-full mt-1 h-[50px]`}
                        className={"w-full flex flex-col justify-center mb-4"}
                        label={"Currency"}
                        type={"currency"}
                        defaultValue={(currency) => payload.user_currency === currency ? true : false}
                    />
                </div>
        
            <div className='flex flex-col gap-y-2 mt-3'>
                <label className='text-slate-600 text-base'>Base Price</label>
                <input  onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()} className={`${INPUT_STYLE} !p-4`}  value={payload?.overrides?.base_price} onChange={(e) => handleOveridesOnChanges(e, 'base_price')}  />
            </div>

            <div className='flex flex-col gap-y-2 mt-3'>
                <label className='text-slate-600 text-base'>Weekend price</label>
                <input  onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()} className={`${INPUT_STYLE} !p-4`}  value={payload?.overrides?.weekend_price} onChange={(e) => handleOveridesOnChanges(e, 'weekend_price')}  />
            </div>

            <div className='flex flex-col gap-y-2 mt-3'>
                <label className='text-slate-600 text-base'>Security deposit</label>
                <input  onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()} className={`${INPUT_STYLE} !p-4`}  value={payload?.overrides?.security_deposit}  onChange={(e) => handleOveridesOnChanges(e, 'security_deposit')}  />
            </div>

            </div>

            </div>
        </div>
    )
}

export default Step1