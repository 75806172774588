import React, { useState, useEffect } from "react";
import { loaderStart, loaderSuccess, error} from "../../store/reducers/loaderSlice";
import { EditIcon, DeleteIcon } from "../../icons";
import {deleteUser,getAllUsers } from "../../services/userServices";
import { useDispatch } from "react-redux";
import { formatDate, formatInput } from "../../utils/common";
// import UsersForm from "../../components/forms/users";
import DeletePopup from "../../components/popup/deletePopup";
import PageHeader from "../../components/pageHeader";
import Table from "../../components/hkTable";
import Dialog from "../../ui/dialog";
// import UserForm from "./userForm";
import { useNavigate } from "react-router-dom";
import { userTypesConstants } from "../../constants/userConstants";
import User from "../../components/forms/users";
import { showNotifications } from "../../store/reducers/notificationsSlice";

const TABLE_COLUMNS = [
  {
    fieldName: "id",
    headName: "ID",
    sort: false,
    filter: false,
    className: "w-[100px]",
    filterFormat: "array",
    inputType: "text",
    hideColumn: false,
  },
  {
    fieldName: "name",
    headName: "Name",
    filter: false,
    sort: false,
    className: "w-[200px]",
    filterFormat: "string",
    inputType: "text",
    hideColumn: false,
  },
  {
    fieldName: "email",
    headName: "Email",
    filter: false,
    sort: false,
    className: "w-[180px]",
    filterFormat: "string",
    inputType: "text",
    hideColumn: false,
  },
  {
    fieldName: "dob",
    headName: "Date of birth",
    filter: false,
    sort: false,
    className: "w-[180px]",
    filterFormat: "string",
    inputType: "text",
    hideColumn: false,
  },
  {
    fieldName: "mobile",
    headName: "Mobile",
    filter: false,
    sort: false,
    className: "w-[200px]",
    filterFormat: "string",
    inputType: "text",
    hideColumn: false,
  },
  {
    fieldName: "user_role",
    headName: "User Role",
    filter: false,
    sort: false,
    className: "w-[200px]",
    filterFormat: "string",
    inputType: "text",
    hideColumn: false,
  },
  {
    fieldName: "username",
    headName: "UserName",
    filter: false,
    sort: false,
    className: "w-[200px]",
    filterFormat: "string",
    inputType: "text",
    hideColumn: false,
  },
  {
    fieldName: "gender",
    headName: "Gender",
    filter: false,
    sort: false,
    className: "w-[200px]",
    filterFormat: "string",
    inputType: "text",
    hideColumn: false,
  },
  // {
  //   fieldName: "total_orders",
  //   headName: "Total Orders",
  //   filter: false,
  //   sort: false,
  //   className: "w-[200px]",
  //   filterFormat: "string",
  //   inputType: "text",
  //   hideColumn: false,
  // },
  // {
  //   fieldName: "total_checkouts",
  //   headName: "Total Checkouts",
  //   filter: false,
  //   sort: false,
  //   className: "w-[200px]",
  //   filterFormat: "string",
  //   inputType: "text",
  //   hideColumn: false,
  // },
  // {
  //   fieldName: "amount_paid",
  //   headName: "Amount Paid",
  //   filter: false,
  //   sort: false,
  //   className: "w-[200px]",
  //   filterFormat: "string",
  //   inputType: "text",
  //   hideColumn: false,
  // },
  // {
  //   fieldName: "login_attempts",
  //   headName: "Login Attempts",
  //   filter: false,
  //   sort: false,
  //   className: "w-[200px]",
  //   filterFormat: "string",
  //   inputType: "text",
  //   hideColumn: false,
  // },
  // {
  //   fieldName: "reset_attempts",
  //   headName: "Reset Attempts",
  //   filter: false,
  //   sort: false,
  //   className: "w-[200px]",
  //   filterFormat: "string",
  //   inputType: "text",
  //   hideColumn: false,
  // },
  {
    fieldName: "created_at",
    headName: "Created At",
    filter: false,
    className: "w-[200px]",
    filterFormat: "string",
    inputType: "text",
    // selectData: [{ New: 1 }, { Completed: 2 }, { Empty: 0 }],
    hideColumn: false,
  },
  {
    fieldName: "updated_at",
    headName: "Updated At",
    filter: false,
    className: "w-[200px]",
    filterFormat: "string",
    inputType: "text",
    // selectData: [{ New: 1 }, { Completed: 2 }, { Empty: 0 }],
    hideColumn: false,
  },
  {
    fieldName: "edit",
    headName: "Edit",
    filter: false,
    className: "w-[100px]",
    filterFormat: "string",
    inputType: "text",
    // selectData: [{ New: 1 }, { Completed: 2 }, { Empty: 0 }],
    hideColumn: false,
  },
  {
    fieldName: "delete",
    headName: "Delete",
    filter: false,
    className: "w-[100px]",
    filterFormat: "string",
    inputType: "text",
    // selectData: [{ New: 1 }, { Completed: 2 }, { Empty: 0 }],
    hideColumn: false,
  },
];

function Users() {
  const dispatch = useDispatch();
  const [rows, setRows] = useState();
  const [pageLoad, setPageLoad] = useState(false);
  const [body, setBody] = useState({
    sort: { col: "id", orderby: "desc" },
    pagination: { page: 1, limit: 20 },
    filters: [{ col: "is_deleted", type: "number", val: "0" }],
  })

  const navigate = useNavigate();
  const [pop, setPop] = useState({isDelete: false});
  const [deletedObj, setDeleteObj] = useState({});
 
  const [isOpen, setIsOpen] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [actionType, setActionType] = useState('create');
  const [editPayload, setEditPayload] = useState(null)


  useEffect(() => {
    getData();
  }, [pageLoad, body]);

  async function getData() {
    try {
      dispatch(loaderSuccess());
      const response = await getAllUsers(body);
      let res = response?.data;
      let result = transformRows(res);
      setRows(result);
    }
    catch (e) {
      dispatch(error(e?.message));
    }
  }

  function handleNavigate(res) {
    navigate(`/user/${res?.id}`);
  }


   //tranforms rows function
  function transformRows( response ) {
    return response?.map((res) => ({
     ...res, 
     created_at: formatDate(res.created_at),
     updated_at: formatDate(res.updated_at),
     edit: getEditComponent(res),
     delete:getDeleteComponent(res),
     id: <GetIdComponent res={res}/>,
     mobile: <GetPhoneNumber  res={res} />,
     user_role: <GetUserRole res={res}/>,
     name: <GetFullName res={res}/>,
     gender: <GetGender  res={res}/>,
     dob: <GetdateOfBirth res={res}/>
    }))
  }

  //TRANSFORM COMPONENTS
  function getEditComponent(item) {
    return (
      <div onClick={() => handleOpenEditPopup(item)}><EditIcon /></div>
    );
  }

  function getDeleteComponent(item) {
    return (
      <div
        onClick={() => {
          setPop((prev) => ({ ...prev, isDelete: true }));
          // console.log(item);
          setDeleteObj({
            id: item?.id,
            firstName: item?.fname,
            LastName: item?.lname,
          });
        }}
      >
        <DeleteIcon />
      </div>
    );
  }

  function GetPhoneNumber({res}) {
    return (
      <div>
        {res?.mobile_ext + " " + res?.mobile}
      </div>
    );
  }

  function GetUserRole({res}) {

    const renderUserRole = () => {
      switch (res.user_role) {
        case userTypesConstants.USER_ROLE_ADMIN:
          return "Admin"
        case userTypesConstants.USER_ROLE_HOST:
          return "Host"
        case userTypesConstants.USER_ROLE_CO_HOST:
          return "Co-Host"
        case userTypesConstants.USER_ROLE_CUSTOMER:
          return "Customer"
        default:
          return "_"
      }
    }


    return (
      <div className="ml-3">
        {renderUserRole()}
      </div>
    );
  }

  function GetdateOfBirth({res}) {
    return <>{res?.dob ? formatDate(res.dob) : ""}</>
  }

  function GetIdComponent({res}) {
    return (
      <div onClick={() => handleNavigate(res)} className="text-blue-500 cursor-pointer">
        {res.id}
      </div>
    );
  }

  function GetFullName({res}) {
    return (
      <div >
        {res?.fname + " " + res?.lname}
      </div>
    );
  }

  function GetGender({res}) {
    return (
      <div>
        {userTypesConstants?.USER_MALE === res?.gender && 'Male'}
        {userTypesConstants?.USER_FEMALE === res?.gender && 'Female'}
      </div>
    );
  }



  //Handle Delete
  const onDeleteList = async () => {
    dispatch(loaderStart());
    try {
      const response = await deleteUser(deletedObj.id);
      dispatch(loaderSuccess());
      setPageLoad((prev) => !prev);
      dispatch(showNotifications('Deleted Successfully'))
    } catch (e) {
      console.log(e);
      dispatch(error(e?.response?.data?.error?.message));
    }
  };

  //handle open create dialog 
  const handleOpenCreate = async () => {
    setActionType('create');
    setIsOpen((prev) => !prev);
  }

  //handle close create or edit popup
  const handleCloseCreate = async () => {
    setActionType('create');
    setIsOpen((prev) => !prev);
  }

  //handle open 
  function handleOpenEditPopup( item ) {
    const { id, fname, lname, dob, mobile_ext, mobile, user_role, username, email, bio, gender, languages, country_id, state_id } = item;
    setActionType('update');
    setIsOpen((prev) => !prev);
    setEditIndex(id);
    setEditPayload({ fname, lname, dob : dob ?  formatInput(dob) : '', mobile_ext, user_role, mobile, username, email, bio, gender, languages, country_id, state_id });
    setPop((prev) => ({ ...prev, isEdit: true }));
  }




  return (
    <>
      <PageHeader title="Users" onClick={handleOpenCreate} />

      {rows && (
        <Table  
          setQueryBody={setBody}  
          queryBody={body}
          rows={rows}
          columns={TABLE_COLUMNS}
          filter={false}
          pagiNationFilter={true}
        />
      )}

      <Dialog showCreateClick={false} title={`${actionType === 'create' ? 'Create': 'Update'} User`} isOpen={isOpen} closeModal={handleCloseCreate} childrenClass={"w-[45%] h-[75%] p-6  overflow-y-scroll rounded-md no-scrollbar dark-scrollbar"}>
        <User  updatePayload={editPayload} actionType={actionType} setPageLoad={setPageLoad} handleClose={handleCloseCreate} setIsOpen={setIsOpen} editId={editIndex}/>
      </Dialog>

      {pop.isDelete && (
        <DeletePopup
          title="User"
          setIsOpen={setPop}
          deletedObj={deletedObj}
          onDeleteList={onDeleteList}
        />
      )}
    </>
  );
}

export default Users;
