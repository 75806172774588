import api from '../api'
import endPoints from './endPoints';

export async function getAllGiftCard() {
  try  {
    const response = await api.get(endPoints.getAllGiftCards());
    return response
  }
  catch (e) {
    throw new Error(e?.message)
  }
}

export async function postGiftCard(body) {
  try  {
    const response = await api.post(endPoints.createGiftCard(), body);
    return response
  }
  catch (e) {
    throw new Error(e?.message)
  }
}

export async function putGiftCard(body, id) {
  try  {
    const response = await api.put(endPoints.updateGiftCard(), body);
    return response
  }
  catch (e) {
    throw new Error(e?.message)
  }
}

export async function deleteGiftCard(id) {
  try  {
    const response = await api.delete(endPoints.deleteGiftCard(id));
    return response
  }
  catch (e) {
    throw new Error(e?.message)
  }
}





