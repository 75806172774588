import api from '../api'
import endPoints from './endPoints';

export  const getAllPaymentGateway = async () => {
  try {
    const response = await api.post(endPoints.getAllPaymentGateway())
    return response
  }
  catch ( e ) {
    throw new Error(e?.response?.data?.error?.message)
  }
}

export const createPayment = async (body) => {
  try {
    const response = await api.post(endPoints.createPaymentGateway(), body)
    return response
  }
  catch ( e ) {
    throw new Error(e?.response?.data?.error?.message)
  }
}

export const updatePayment = async (id, body) => {
  try {
    const response = await api.put(endPoints.updatePaymentGateway(id), body)
    return response
  }
  catch ( e ) {
    throw new Error(e?.response?.data?.error?.message)
  }
}

export const deletePayment = async (id) => {
  try {
    const response = await api.delete(endPoints.deletePaymentGateway(id));
    return response
  }
  catch ( e ) {
    throw new Error(e?.response?.data?.error?.message)
  }
}
