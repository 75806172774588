export const INPUT_STYLE = "w-full placeholder:italic placeholder:text-slate-500 block bg-white border border-slate-300 rounded-md py-2 pl-4 pr-3  focus:outline-none focus:border-red-300 focus:ring-red-300 focus:ring-1 sm:text-sm";

export const AffiliateEarningsBookingStatus = [
    {key: 'Pending',value: 0},
    {key: 'Check In',value: 1},
    {key: 'Check Out',value: 2},
];


export const currencyData = {
  "USD": {
    "symbol": "$",
    "position": "left"
  },
  "AED": {
    "symbol": "د.إ",
    "position": "right"
  },
  "INR": {
    "symbol": "₹",
    "position": "left"
  },
  "GBP": {
    "symbol": "£",
    "position": "left"
  },
  "EUR": {
    "symbol": "€",
    "position": "right"
  },
  "SAR": {
    "symbol": "﷼",
    "position": "left"
  },
  "OMR": {
    "symbol": "ر.ع.",
    "position": "right"
  },
  "QAR": {
    "symbol": "ر.ق",
    "position": "right"
  },
  "KWD": {
    "symbol": "د.ك",
    "position": "right"
  },
  "BHD": {
    "symbol": "د.ب",
    "position": "right"
  },
  "RUB": {
    "symbol": "₽",
    "position": "right"
  },
  "TRY": {
    "symbol": "₺",
    "position": "right"
  },
  
  // "JPY": {
  //   "symbol": "¥",
  //   "position": "left"
  // },
  
  // "AUD": {
  //   "symbol": "$",
  //   "position": "left"
  // },
  // "CAD": {
  //   "symbol": "$",
  //   "position": "left"
  // },
  // "CHF": {
  //   "symbol": "Fr",
  //   "position": "left"
  // },
  // "CNY": {
  //   "symbol": "¥",
  //   "position": "right"
  // },
  // "NZD": {
  //   "symbol": "$",
  //   "position": "left"
  // },
  // "MXN": {
  //   "symbol": "$",
  //   "position": "left"
  // },
  // "SGD": {
  //   "symbol": "$",
  //   "position": "left"
  // },
  // "HKD": {
  //   "symbol": "$",
  //   "position": "left"
  // },
  // "NOK": {
  //   "symbol": "kr",
  //   "position": "right"
  // },
  // "KRW": {
  //   "symbol": "₩",
  //   "position": "right"
  // },
  // "BRL": {
  //   "symbol": "R$",
  //   "position": "left"
  // },
  // "ZAR": {
  //   "symbol": "R",
  //   "position": "right"
  // },
  // "COP": {
  //   "symbol": "$",
  //   "position": "left"
  // },
  // "PLN": {
  //   "symbol": "zł",
  //   "position": "right"
  // },
  // "PHP": {
  //   "symbol": "₱",
  //   "position": "right"
  // },
  // "THB": {
  //   "symbol": "฿",
  //   "position": "right"
  // },
  // "IDR": {
  //   "symbol": "Rp",
  //   "position": "left"
  // },
  // "HUF": {
  //   "symbol": "Ft",
  //   "position": "right"
  // },
  // "ILS": {
  //   "symbol": "₪",
  //   "position": "right"
  // },
  // "DKK": {
  //   "symbol": "kr",
  //   "position": "right"
  // },
  // "CZK": {
  //   "symbol": "Kč",
  //   "position": "right"
  // },
  // "CLP": {
  //   "symbol": "$",
  //   "position": "left"
  // },
  // "ARS": {
  //   "symbol": "$",
  //   "position": "left"
  // },
  // "MYR": {
  //   "symbol": "RM",
  //   "position": "right"
  // },
  // "RON": {
  //   "symbol": "lei",
  //   "position": "right"
  // },
  // "SEK": {
  // "symbol": "kr",
  // "position": "right"
  // },
  // "BGN": {
  // "symbol": "лв",
  // "position": "right"
  // },
  // "KES": {
  // "symbol": "KSh",
  // "position": "right"
  // },
  // "HRK": {
  // "symbol": "kn",
  // "position": "right"
  // },
  // "IQD": {
  // "symbol": "ع.د",
  // "position": "right"
  // },
  // "VND": {
  // "symbol": "₫",
  // "position": "right"
  // },
  // "PKR": {
  // "symbol": "₨",
  // "position": "right"
  // },
  // "EGP": {
  // "symbol": "£",
  // "position": "left"
  // },
  // "DZD": {
  // "symbol": "دج",
  // "position": "right"
  // },
}