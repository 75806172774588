import api from '../../api'
import endPoints from '../endPoints'

export const getAllOtherPlatformUsers = async () => {
    try {
      const response = await api.post(endPoints.getAllOtherPlatformUsers());
      return response.data
    }
    catch (e) {
        throw new Error(e?.response?.data?.error?.message)
    }
}

export const getOtherPlatformUsersById = async ( id ) => {
    try {
        const response = await api.get(endPoints.getOtherPlatformUsersById( id ));
        return response.data
    }
    catch (e) {
        throw new Error(e?.response?.data?.error?.message)
    }
}

export const createOtherPlatformUser = async ( body ) => {
    try {
        const response = await api.post(endPoints.createOtherPlatformUser(), body );
        return response.data
    }
    catch (e) {
        throw new Error(e?.response?.data?.error?.message)
    }
}

export const updateOtherPlatformUser = async ( id, body ) => {
    try {
       const response = await api.put(endPoints.updateOtherPlatformUser(id), body );
       return response.data
    }
    catch (e) {
        throw new Error(e?.response?.data?.error?.message)
    }
};


export const deleteOtherPlatformUser = async ( id ) => {
    try {
        const response = await api.delete(endPoints.deleteOtherPlatformUser(id));
        return response.data
    }
    catch (e) {
        throw new Error(e?.response?.data?.error?.message)
    }
}