import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getPageById, updatePage } from '../../services/pageConfig'
import Dialog from '../../ui/dialog'
import SearchOptions from '../../components/forms/pageConfig1/detailPageForms/searchOptions'
import { useDispatch } from 'react-redux'
import { error, loaderStart, loaderSuccess } from '../../store/reducers/loaderSlice'
import { DeleteIcon, EditIcon } from '../../icons'
import CommonDeletePopup from '../../components/popup/commonDeletePopup'
import FilterOptions from '../../components/forms/pageConfig1/detailPageForms/filterOptions'
import PageHeader from '../../components/pageHeader'
import PageConfigAmenitiesHighlightsForm from '../../components/forms/pageConfig/pageConfigDropdownOptions/pageConfigAmenitiesHighlightsForm'
import DeletePopup from '../../components/popup/deletePopup'
import PageConfigPropertyTypeForm from '../../components/forms/pageConfig/pageConfigDropdownOptions/pageConfigPropertyTypeForm'

function PageConfigDetail() {
  const [searchOptions, setSearchOptions] = useState();
  const [filterOptions, setFilterOptions] = useState();
  const [pageLoad, setPageLoad] = useState(false );
  const [data, setData] = useState([])
  const [activeItem, setActiveItem] = useState({});
  const [pop, setPop] = useState({
    isDelete: false,
    isEdit: false,
    isCreate: false,
  });
  const [amenitiesPop, setAmenitiesPop] = useState({
    isDelete: false,
    isEdit: false,
    isCreate: false,
  });
  const [propertyTypePop, setPropertyTypePop] = useState({
    isDelete: false,
    isEdit: false,
    isCreate: false,
  });
  const [filterPop, setFilterPop] = useState({
    isDelete: false,
    isEdit: false,
    isCreate: false,
  });

  //delete payload to show in ui
  const [deleteObj, setDeleteObj] = useState({});
  const [filterOptionsDeleteObj, setFilterOptionsDeleteObj] = useState({})
  

  //indexs
  const [deleteIndex, setDeleteIndex] =  useState(null);
  const [searchOptionsEditIndex, setSearchOptionsEditIndex] = useState(null);
  const [filterOptionsDeleteIndex, setFilterOptionsDeleteIndex] = useState(null);
  const [filterOptionsEditIndex, setFilterOptionsEditIndex] = useState(null);
 

  //dialogbox states 
  const [openSearchOptionsDialog, setShowSearchOptionsDialog] = useState(false);
  const [openShowSearchOptionsDeleteDialog, setShowSearchOptionsDeleteDialog] = useState(false);
  const [openFilterOptionsDialog, setShowFilterOptionsDialog] = useState(false);
  const [openFilterOptionsDeleteDialog, setShowFilterOptionsDeleteDialog] = useState(false);

  //payload 
  const [searchOptionsPayload, setShowOptionsPayload] = useState({item_type:'region',item_sequence:1, item_ref_id:'', item_label:'', item_image:''})
  const [filterOptionsPayload, setFilterOptionsPayload] = useState({type:'category', type_ref_id:'',sequence_no:'', name:'', image:''})


  const params = useParams()
  const dispatch = useDispatch()
  const paramsId = params?.id 

  useEffect(() => {
    getData()
  }, [pageLoad])

  
  async function getData() {
    try {
      dispatch(loaderStart())
      const [response] = await getPageById( paramsId );
      dispatch(loaderSuccess())
      setSearchOptions(response?.search_options)
      setFilterOptions(response?.filter_options)
      setData([response]);
    }
    catch (e) {
     dispatch(error(e?.messgae))
    }
  }


  //open edit and delete dialog boxes
  const handleShowEditDialog = ( data, idx ) => {
    const {item_ref_id, item_type, item_sequence, item_label, item_image} = searchOptions.items[idx];
    setShowOptionsPayload({item_ref_id, item_type, item_sequence, item_label, item_image});
    setShowSearchOptionsDialog((prev) => !prev);
    setSearchOptionsEditIndex(idx)
  }

  const handleShowDeleteDialog = ( data, idx ) => {
    setDeleteObj({item_type:data?.item_type, item_label:data?.item_label})
    setDeleteIndex(idx)
    setShowSearchOptionsDeleteDialog((prev) => !prev)
  }

  const handleShowFilterOptionsEditDialog = ( idx ) => {
    const {type, type_ref_id, sequence_no, name, image} = filterOptions[idx];
    setFilterOptionsPayload({type, type_ref_id, sequence_no, name, image});
    setShowFilterOptionsDialog((prev) => !prev)
    setFilterOptionsEditIndex( idx );
  }

  const handleShowFilterOptionsDeleteDialog = ( data, idx ) => {
    const {type, name} = data 
    setFilterOptionsDeleteObj({type, name})
    setFilterOptionsDeleteIndex( idx );
    setShowFilterOptionsDeleteDialog((prev) => !prev);
  }


  const handleCreate = async () => {
    try {
      dispatch(loaderStart())
      
      if ( searchOptionsEditIndex === null ) {
        let data = []
        if (searchOptions?.items) {
          data = [...searchOptions?.items]
        }
        data.push({...searchOptionsPayload});
        let updateBody = { heading: "Featured Destination", items:data};
        const updateData = await updatePage(paramsId, {search_options:updateBody});
      }
      else {
        let data = [...searchOptions.items]
        data[searchOptionsEditIndex] = {...searchOptionsPayload}
        let updateBody = { heading: "Featured Destination", items:data};
        const updateData = await updatePage( paramsId, {search_options:updateBody} )
      }
      dispatch(loaderSuccess())
      setShowOptionsPayload({item_ref_id:'', item_type:'region', item_sequence:'', item_label:'', item_image:''});
      setPageLoad((prev) => !prev)
      setShowSearchOptionsDialog((prev) =>!prev)
      setSearchOptionsEditIndex(null)
      
    }
    catch (e) {
      dispatch(error(e?.message))
    }
    
  }


  const handleDelete = async () => {
    try {
      dispatch(loaderStart())
      let data = [...searchOptions.items]
      data.splice(deleteIndex, 1)
      let updateBody = { heading: searchOptions.heading, items:data}
      const updateData = await updatePage( paramsId, {search_options:updateBody} )
      dispatch(loaderSuccess())
      setPageLoad((prev) => !prev)
      setDeleteIndex(null)
      setDeleteObj({})
      setShowSearchOptionsDeleteDialog((prev) => !prev)
    }
    catch (e) {
      dispatch(error(e?.message))
    }
  }


  const handleCreateFilterOptions = async () => {
    try{
      dispatch(loaderStart())

      if ( filterOptionsEditIndex === null ) {
        let data = []
        if ( filterOptions ) {
          data = [...filterOptions];
        }
        data.push({...filterOptionsPayload});
        await updatePage(paramsId, {filter_options:data});
      }
      else {
        let data = [...filterOptions]
        data[filterOptionsEditIndex] = {...filterOptionsPayload}
        await updatePage(paramsId, {filter_options:data});
      }

      dispatch(loaderSuccess())
      setFilterOptionsPayload({type:'category', type_ref_id:'',sequence_no:'', name:'', image:''});
      setPageLoad((prev) => !prev)
      setShowFilterOptionsDialog((prev) =>!prev)
      setFilterOptionsEditIndex(null)
    }
    catch (e) {
      dispatch(error(e?.message))
    }
  }


  const handleDeleteFilterOptions = async () => {
    try {
      dispatch(loaderStart())
      const data = [...filterOptions]
      data.splice(filterOptionsDeleteIndex, 1);
      await updatePage( paramsId, {filter_options: data});
      dispatch(loaderSuccess())
      setFilterOptionsDeleteObj({})
      setFilterOptionsDeleteIndex( null );
      setShowFilterOptionsDeleteDialog((prev) => !prev);
      setPageLoad((prev) => !prev)
    }
    catch (e) {
      dispatch(error(e?.message))
    }

  }



  //dialog opens / closes 
  //search options
  const handleOpenCreateSearchOptions = () => {
    setShowOptionsPayload({item_ref_id:'', item_type:'region', item_sequence:'', item_label:'', item_image:''});
    setShowSearchOptionsDialog((prev) => !prev)
    setSearchOptionsEditIndex(null);
  }

  const handleCloseCreateSearchOptions = () => {
    setShowOptionsPayload({item_ref_id:'', item_type:'region', item_sequence:'', item_label:'', item_image:''});
    setShowSearchOptionsDialog((prev) => !prev);
    setSearchOptionsEditIndex(null);
  }

  const handleCloseDeletePopUp = () => {
    setDeleteIndex(null);
    setShowSearchOptionsDeleteDialog((prev) => !prev)
  }

  const handleOpenCreateFilterOptions = () => {
    setFilterOptionsPayload({type:'category', type_ref_id:'',sequence_no:'', name:'', image:''})
    setShowFilterOptionsDialog((prev) => !prev)
    setSearchOptionsEditIndex(null);
  }

  const handleCloseCreateFilterOptions = () => {
    setFilterOptionsPayload({type:'category', type_ref_id:'',sequence_no:'', name:'', image:''})
    setShowFilterOptionsDialog((prev) => !prev)
    setSearchOptionsEditIndex(null);
  }

  const handleCloseDeleteFilterOptionsDialog = () => {
    setFilterOptionsDeleteIndex(null);
    setShowFilterOptionsDeleteDialog((prev) => !prev)
  }




 //merged code
 const handleAmenitiesCreateOpen = () => {
  setAmenitiesPop({ ...amenitiesPop, isCreate: true });
};

const handleAmenitiesCreateClose = () => {
  setAmenitiesPop({ ...amenitiesPop, isCreate: false });
};

const handleAmenitiesEditClose = () => {
  setAmenitiesPop({ ...amenitiesPop, isEdit: false });
};

const handleAmenitiesEditOpen = (item, ind) => {
  // console.log("handleEdit Open--------", item);
  // console.log("item props id", item?.id?.props?.children);
  setActiveItem({ ...item, index: ind });
  setAmenitiesPop({ ...amenitiesPop, isEdit: true });
};

const handleAmenitiesDeleteOpen = (item, ind) => {
  // console.log("handle Open--------", item);
  setActiveItem({ ...item, index: ind });
  setAmenitiesPop({ ...amenitiesPop, isDelete: true });
};

const handleAmenitiesHighlightsPutRequest = (formData) => {
  // console.log("put request", { ...formData });
  const updatedData = data[0]?.dropdown_options?.amenities_highlight.map(
    (eachItem, i) => {
      if (i === formData.index) {
        delete formData.index;
        return { ...formData };
      }
      return { ...eachItem };
    }
  );
  // console.log("update Data", updatedData);
  const dropdownOptions = {
    dropdown_options: {
      amenities_highlight: updatedData,
      property_type: [...data[0]?.dropdown_options?.property_type],
    },
  };
  dispatch(loaderStart());
  updatePage(params.id, dropdownOptions)
    .then(() => {
      dispatch(loaderSuccess());
      setPageLoad((prev) => !prev);
      console.log("success");
    })
    .catch((e) => {
      // console.log("========================", e);
      dispatch(error(e.response.data.error.message));
    });
};

function handleAmenitiesHighlightsPostRequest(formData) {
  const dropdownOptions = {
    dropdown_options: {
      amenities_highlight:
        data[0]?.dropdown_options?.amenities_highlight?.length > 0
          ? [
              ...data[0]?.dropdown_options?.amenities_highlight,
              { ...formData },
            ]
          : [{ ...formData }],
      property_type:
        data[0]?.dropdown_options?.property_type?.length > 0
          ? [...data[0]?.dropdown_options?.property_type]
          : [],
    },
  };
  // console.log("dropdownOptions-----------", dropdownOptions);
  dispatch(loaderStart());
  updatePage(params.id, dropdownOptions)
    .then(() => {
      dispatch(loaderSuccess());
      setPageLoad((prev) => !prev);
      console.log("success");
    })
    .catch((e) => {
      dispatch(error(e));
      // console.log(e);
    });
  console.log("post req");
}

const onDeleteAmenitiesHighlightsList = () => {
  dispatch(loaderStart());
  const updatedData = data[0]?.dropdown_options?.amenities_highlight.filter(
    (_, i) => i !== activeItem.index
  );
  const dropdownOptions = {
    dropdown_options: {
      amenities_highlight: updatedData,
      property_type: [...data[0]?.dropdown_options?.property_type],
    },
  };
  updatePage(params.id, dropdownOptions)
    .then(() => {
      dispatch(loaderSuccess());
      setPageLoad((prev) => !prev);
      console.log("success");
    })
    .catch((e) => {
      dispatch(error(e?.message));
    });
};


  // Property Type

  const handlePropertyTypeCreateOpen = () => {
    setPropertyTypePop({ ...propertyTypePop, isCreate: true });
  };

  const handlePropertyTypeCreateClose = () => {
    setPropertyTypePop({ ...propertyTypePop, isCreate: false });
  };

  const handlePropertyTypeEditClose = () => {
    setPropertyTypePop({ ...propertyTypePop, isEdit: false });
  };

  const handlePropertyTypeEditOpen = (item, ind) => {
    // console.log("handleEdit Open--------", item);
    // console.log("item props id", item?.id?.props?.children);
    setActiveItem({ ...item, index: ind });
    setPropertyTypePop({ ...propertyTypePop, isEdit: true });
  };

  const handlePropertyTypeDeleteOpen = (item, ind) => {
    // console.log("handle Open--------", item);
    setActiveItem({ ...item, index: ind });
    setPropertyTypePop({ ...propertyTypePop, isDelete: true });
  };

  const handlePropertyTypePutRequest = (formData) => {
    // console.log("put request", { ...formData });
    const updatedData = data[0]?.dropdown_options?.property_type.map(
      (eachItem, i) => {
        if (i === formData.index) {
          delete formData.index;
          return { ...formData };
        }
        return { ...eachItem };
      }
    );

    const dropdownOptions = {
      dropdown_options: {
        amenities_highlight: [
          ...data[0]?.dropdown_options?.amenities_highlight,
        ],
        property_type: updatedData,
      },
    };
    console.log("update Data", updatedData, dropdownOptions);
    dispatch(loaderStart());
    updatePage(params.id, dropdownOptions)
      .then(() => {
        dispatch(loaderSuccess());
        setPageLoad((prev) => !prev);
        console.log("success");
      })
      .catch((e) => {
        // console.log("========================", e);
        dispatch(error(e.response.data.error.message));
      });
  };

  function handlePropertyTypePostRequest(formData) {
    // console.log(
    //   "PropertyType formData---------",
    //   formData
    // );
    const dropdownOptions = {
      dropdown_options: {
        amenities_highlight:
          data[0]?.dropdown_options?.amenities_highlight?.length > 0
            ? [...data[0]?.dropdown_options?.amenities_highlight]
            : [],
        property_type:
          data[0]?.dropdown_options?.property_type?.length > 0
            ? [...data[0]?.dropdown_options?.property_type, { ...formData }]
            : [{ ...formData }],
      },
    };
    // console.log("dropdownOptions-----------", dropdownOptions);
    dispatch(loaderStart());
    updatePage(params.id, dropdownOptions)
      .then(() => {
        dispatch(loaderSuccess());
        setPageLoad((prev) => !prev);
        console.log("success");
      })
      .catch((e) => {
        dispatch(error(e));
        // console.log(e);
      });
    console.log("post req");
  }

  const onDeletePropertyTypeList = () => {
    dispatch(loaderStart());
    const updatedData = data[0]?.dropdown_options?.property_type.filter(
      (_, i) => i !== activeItem.index
    );
    const dropdownOptions = {
      dropdown_options: {
        amenities_highlight: [
          ...data[0]?.dropdown_options?.amenities_highlight,
        ],
        // data[0]?.dropdown_options?.amenities_highlight?.length > 0
        //   ? [...data[0]?.dropdown_options?.amenities_highlight]
        //   : [],
        property_type: updatedData,
      },
    };
    updatePage(params.id, dropdownOptions)
      .then(() => {
        dispatch(loaderSuccess());
        setPageLoad((prev) => !prev);
        console.log("success");
      })
      .catch((e) => {
        // console.log(e);
        dispatch(error(e));
      });
    console.log("deleted");
  };


  

  return (
    <div className='min-h-screen mt-10'>

      {/* Search Options */}
     <div className='flex items-center justify-between px-3'>
        <h1 className='text-2xl font-bold'>Search Options</h1>
        <button className='bg-black text-white p-2 rounded-md cursor-pointer' onClick={handleOpenCreateSearchOptions}>Create</button>
     </div>

     <div>
          <div className="flex m-3 p-3 text-xl">
            <div className="w-[200px]">Item Type</div> <div>Label</div>
          </div>
          {searchOptions && searchOptions?.items?.map((e, i) => (
            <div key={i} className="border m-3 flex items-center justify-between p-3">
              <div className="flex">
                <div className="w-[200px]">{e.item_type}</div>
                <img src={`${process.env.REACT_APP_CDN_URL}${e.item_image}`} className='w-10 h-10'/>
                <div>{e.item_label}</div>
              </div>
              <div className="flex justify-around w-[200px] ">
                <div>
                  <EditIcon onClick={() => handleShowEditDialog(e, i)} />
                </div>
                <div>
                  <DeleteIcon onClick={() => handleShowDeleteDialog(e, i)} />
                </div>
              </div>
            </div>
          ))}
     </div>


      {/* Filter Options */}
      <div className='flex items-center justify-between px-3 mt-10'>
        <h1 className='text-2xl font-bold'>Filter Options</h1>
        <button className='bg-black text-white p-2 rounded-md cursor-pointer' onClick={handleOpenCreateFilterOptions}>Create</button>
     </div>

     <ul>
          <li className="flex m-3 p-3 text-xl">
            <div className="w-[200px]">Type</div> <div>Label</div>
          </li>
          { filterOptions?.length > 0 && filterOptions?.map((e, i) => (
            <li
              key={i}
              className="border m-3 flex items-center justify-between p-3"
            >
              <div className="flex">
                <div className="w-[200px]">{e.type}</div>
                <div className="flex">
                  <img src={`${process.env.REACT_APP_CDN_URL}${e?.image}`}  alt={`${e.name}`} className="w-[20px] h-[20px] mr-1 self-center"/>
                  <p className="ml-2">{e.name}</p>
                </div>
              </div>
              <div className="flex justify-around w-[200px] ">
                <div onClick={() => handleShowFilterOptionsEditDialog(i)}>
                  <EditIcon />
                </div>
                <div onClick={() => handleShowFilterOptionsDeleteDialog(e,i)}>
                  <DeleteIcon />
                </div>
              </div>
            </li>
          ))}
     </ul>



      {/* Amenities Highlight */}
      <div className="m-8">
        <h1 className="text-2xl font-semibold">Dropdown Options</h1>
        <PageHeader
          title="Amenities Highlights"
          buttonName="Add"
          onClick={handleAmenitiesCreateOpen}
        />

        {amenitiesPop.isCreate && (
          <Dialog
            closeModal={handleAmenitiesCreateClose}
            isOpen={amenitiesPop.isCreate}
            createClick={amenitiesPop.isCreate}
            title="Page Config"
            childrenClass={"w-[50%] p-6 rounded-md no-scrollbar dark-scrollbar"}
          >
            <PageConfigAmenitiesHighlightsForm
              isEdit={amenitiesPop.isCreate}
              close={handleAmenitiesCreateClose}
              editData={{
                ref_id: "",
                name: "",
                sequence_no: "",
              }}
              handleRequest={handleAmenitiesHighlightsPostRequest}
              value="Create"
              createClick={amenitiesPop.isCreate}
            />
          </Dialog>
        )}

        {amenitiesPop.isEdit && (
          <Dialog
            closeModal={handleAmenitiesEditClose}
            isOpen={amenitiesPop.isEdit}
            title="Page Config"
            childrenClass={
              "w-[50%]  p-6 rounded-md no-scrollbar dark-scrollbar"
            }
          >
            <PageConfigAmenitiesHighlightsForm
              isEdit={amenitiesPop.isEdit}
              close={handleAmenitiesEditClose}
              editData={{
                index: activeItem.index,
                ref_id: activeItem.ref_id,
                sequence_no: activeItem.sequence_no,
                name: activeItem.name,
              }}
              handleRequest={handleAmenitiesHighlightsPutRequest}
              value="Update"
            />
          </Dialog>
        )}

        {amenitiesPop.isDelete && (
          <DeletePopup
            deletedObj={{ name: activeItem?.name }}
            setIsOpen={setAmenitiesPop}
            title="Page Config"
            onDeleteList={onDeleteAmenitiesHighlightsList}
          />
        )}

        <ul>
          {/* <li className="flex m-3 p-3 text-xl">
            <div className="w-[200px]">Item Type</div> <div>Label</div>
          </li> */}
          {data[0]?.dropdown_options?.amenities_highlight?.map((e, i) => (
            <li
              key={i}
              className="border m-3 flex items-center justify-between p-3"
            >
              <div className="flex">
                <div className="w-[200px]">{e.name}</div>
                <div>{e.sequence}</div>
              </div>
              <div className="flex justify-around w-[200px] ">
                <div onClick={() => handleAmenitiesEditOpen(e, i)}>
                  <EditIcon />
                </div>
                <div onClick={() => handleAmenitiesDeleteOpen(e, i)}>
                  <DeleteIcon />
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>


      <div className="m-8">
        <PageHeader
          title="Property Types"
          buttonName="Add"
          onClick={handlePropertyTypeCreateOpen}
        />

        {propertyTypePop.isCreate && (
          <Dialog
            closeModal={handlePropertyTypeCreateClose}
            isOpen={propertyTypePop.isCreate}
            createClick={propertyTypePop.isCreate}
            title="Property Type"
            childrenClass={"w-[50%] p-6 rounded-md no-scrollbar dark-scrollbar"}
          >
            <PageConfigPropertyTypeForm
              isEdit={propertyTypePop.isCreate}
              close={handlePropertyTypeCreateClose}
              editData={{
                type: "category",
                type_ref_id: "",
                sequence_no: "",
                name: "",
                image: "",
              }}
              handleRequest={handlePropertyTypePostRequest}
              value="Create"
              createClick={propertyTypePop.isCreate}
            />
          </Dialog>
        )}

        {propertyTypePop.isEdit && (
          <Dialog
            closeModal={handlePropertyTypeEditClose}
            isOpen={propertyTypePop.isEdit}
            title="Property Type"
            childrenClass={
              "w-[50%]  p-6 rounded-md no-scrollbar dark-scrollbar"
            }
          >
            <PageConfigPropertyTypeForm
              isEdit={propertyTypePop.isEdit}
              close={handlePropertyTypeEditClose}
              editData={{
                index: activeItem.index,
                type: "category",
                type_ref_id: activeItem.type_ref_id,
                sequence_no: activeItem.sequence_no,
                name: activeItem.name,
                image: activeItem.image,
              }}
              handleRequest={handlePropertyTypePutRequest}
              value="Update"
            />
          </Dialog>
        )}

        {propertyTypePop.isDelete && (
          <DeletePopup
            deletedObj={{ name: activeItem?.name }}
            setIsOpen={setPropertyTypePop}
            title="Property Type"
            onDeleteList={onDeletePropertyTypeList}
          />
        )}

        <ul>
          {/* <li className="flex m-3 p-3 text-xl">
            <div className="w-[200px]">Item Type</div> <div>Label</div>
          </li> */}
          {data[0]?.dropdown_options?.property_type?.map((e, i) => (
            <li
              key={i}
              className="border m-3 flex items-center justify-between p-3"
            >
              <div className="flex">
                <div className="w-[200px]">{e.name}</div>
                <div>{e.sequence}</div>
              </div>
              <div className="flex justify-around w-[200px] ">
                <div onClick={() => handlePropertyTypeEditOpen(e, i)}>
                  <EditIcon />
                </div>
                <div onClick={() => handlePropertyTypeDeleteOpen(e, i)}>
                  <DeleteIcon />
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>



      <Dialog createClick={searchOptionsEditIndex === null} closeModal={handleCloseCreateSearchOptions} childrenClass={"w-[50%] p-6 rounded-md no-scrollbar dark-scrollbar"} isOpen={openSearchOptionsDialog} >
        <SearchOptions closeModal={handleCloseCreateSearchOptions} searchOptionsEditIndex={searchOptionsEditIndex} searchOptionsPayload={searchOptionsPayload} handleCreate={handleCreate}  setShowOptionsPayload={setShowOptionsPayload}/>
      </Dialog>

      <Dialog createClick={filterOptionsEditIndex === null} closeModal={handleCloseCreateFilterOptions} childrenClass={"w-[50%] p-6 rounded-md no-scrollbar dark-scrollbar"} isOpen={openFilterOptionsDialog}>
        <FilterOptions closeModal={handleCloseCreateFilterOptions} editIndex={filterOptionsEditIndex} filterOptionsPayload={filterOptionsPayload} handleCreate={handleCreateFilterOptions} setFilterOptionsPayload={setFilterOptionsPayload} />
      </Dialog>

      {openShowSearchOptionsDeleteDialog && (
        <CommonDeletePopup
          title={'Search Options'}
          deleteObj={deleteObj}
          handleDeleteData={handleDelete}
          setPageLoad={setPageLoad}
          onClose={handleCloseDeletePopUp}
        />
      )}


      {openFilterOptionsDeleteDialog && (
        <CommonDeletePopup
          title={"Filter Options"}
          deleteObj={filterOptionsDeleteObj}
          setPageLoad={setPageLoad}
          handleDeleteData={handleDeleteFilterOptions}
          onClose={handleCloseDeleteFilterOptionsDialog}
        />
      )}

    </div>
   
  )
}

export default PageConfigDetail