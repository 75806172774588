import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { getAllReservationById, updateReservations } from '../../services/reservations';
import { useDispatch } from 'react-redux';
import { error, loaderStart, loaderSuccess } from '../../store/reducers/loaderSlice';
import { ChevronDownIcon } from '../../icons';
import PriceBreakUp from '../../components/forms/checkout/priceBreakUp';
import { useAddonsHashMapSelector, useExtraServicesHashMapSelector } from '../../store/selectors';
import {showNotifications} from '../../store/reducers/notificationsSlice'

function ReservationDetail() {
  const [reservationsData, setReservationData] = useState({});
  const [basePriceOpen, setBasePriceOpen] = useState(false);
  const [showAddons, setShowAddons] = useState(false);
  const [showServices, setShowServices] = useState( false);
  const [showTax, setShowTax] = useState(false);
  const [showUpdateStatus, setShowUpdateStatus] = useState(false);
  const [pageLoad, setPageLoad] = useState(false);
  const [updatePayload, setUpdatePayload] = useState({booking_status:''})
  const pricing_data = reservationsData?.pricing_data && (JSON.parse(reservationsData?.pricing_data) || {});

 
  const params = useParams();
  const dispatch = useDispatch()

  const addonsHashMap = useAddonsHashMapSelector();
  const extraServicesHashMap = useExtraServicesHashMapSelector();

  //getAllReservationById
  useEffect(() => {
    getData()
  }, [pageLoad]);

  async function getData() {
    try {
      dispatch(loaderStart())
      let id = params?.id
      const response = await getAllReservationById(id);
      console.log('response id', response)
      setReservationData( response?.data )
      dispatch(loaderSuccess())
    }
    catch (e) {
      dispatch(error(e?.message))
    }
  }


const handleUpdateStatus = async () => {
    try {
        if (!updatePayload.booking_status) {
          throw new Error('Please send Status');
        }
       dispatch(loaderStart())
       const data = await updateReservations(params?.id, updatePayload)
       setPageLoad((prev) => !prev);
       dispatch(loaderSuccess());
       dispatch(showNotifications('Updated Successfully'))
    }
    catch (e) {
        dispatch(error(e?.message))
    }
}


  return (
    <div className='h-screen  overflow-y-scroll bg-slate-100'>
        <div className='mt-10'>Order ( #{reservationsData?.id} )</div>

        <div className='flex flex-col items-center justify-center'>
            <p className='self-start'>User Details</p>
            <div className='flex items-center   justify-between shadow-lg p-2 mb-6 border rounded-lg w-[75%] mx-3 '>
               <div className="flex flex-col w-[25%]">
                    <p className="text-base text-slate-600">Id</p>
                    <p className="text-base truncate text-slate-600">{reservationsData?.customer_id}</p>
                </div>

                <div className="flex flex-col w-[25%]">
                    <p className="text-base text-slate-600">First Name</p>
                    <p className="text-base truncate text-slate-600">{reservationsData?.user_fname}</p>
                </div>
                <div className="flex flex-col w-[25%]">
                    <p className="text-base text-slate-600">Last Name</p>
                    <p className="text-base truncate text-slate-600">{reservationsData?.user_lname}</p>
                </div>
                <div className="flex flex-col w-[25%]">
                    <p className="text-base text-slate-600">Email</p>
                    <p className="text-base truncate text-slate-600">{reservationsData?.user_email}</p>
                </div>
                <div className="flex flex-col w-[25%]">
                    <p className="text-base text-slate-600">Mobile</p>
                    <p className="text-base truncate text-slate-600">{reservationsData?.user_ext} {reservationsData?.user_mobile}</p>
                </div>
            </div>
        </div>

        <div className='flex flex-col items-center justify-center'>
             <p className='self-start'>Checkout Details</p>
            <div className='flex flex-col   justify-between shadow-lg p-2 mb-6 border rounded-lg w-[75%] mx-3 '>
               <div className="flex items-center justify-between">
                   <p>Order Id</p>
                   <p>{reservationsData?.id}</p>
               </div>
               <div className="flex items-center justify-between">
                   <p>Migration Order Id</p>
                   <p>{reservationsData?.migration_reservation_id}</p>
               </div>

               <div className="flex items-center justify-between">
                   <p>Checkout Id</p>
                   <p>{reservationsData?.checkout_id}</p>
               </div>

               <div className="flex items-center justify-between">
                   <p className='flex gap-x-2 items-center'> <p className={``}>Base Price</p><ChevronDownIcon onClick={() => setBasePriceOpen((prev) => !prev)}  className={`${basePriceOpen ? "rotate-180" : ""} mt-1 cursor-pointer`} /></p>
                   <p>{pricing_data?.base_price_total}</p>
               </div>

               {basePriceOpen && <PriceBreakUp className={``} priceObj={pricing_data?.base_price_detail} />}

               <div className="flex items-center justify-between">
                   <p className={``}>Security Deposit</p>
                   <p>{pricing_data?.security_deposit_total}</p>
               </div>

               <div className='flex items-center justify-between mt-3'>
                    <div className='flex gap-x-2 items-center'> <p className={` `}>Addons</p><ChevronDownIcon onClick={() => setShowAddons((prev) => !prev)}  className={`${showAddons ? "rotate-180" : ""} mt-1 cursor-pointer`} /></div>
                     <p className={``}>{pricing_data?.add_ons_price_total}</p>
                </div>

                    <div className={`${showAddons ? 'block' : "hidden"}`}>
                        { pricing_data && Object.keys(pricing_data?.add_ons_price_key_detail)?.length > 0 && (
                           <>
                             {Object.keys(pricing_data?.add_ons_price_key_detail)?.map((data, idx) => (
                                 <div key={idx}>
                                    <div  className='flex items-center justify-between ml-3 mt-1'>
                                        <p className={``}>{addonsHashMap[data]?.name} </p>
                                        <p className={``}>{pricing_data?.add_ons_price_key_detail[data]}</p>
                                   </div>
                                 </div>
                             ))}
                           </>
                       )}
                    </div>

                <div className='flex items-center justify-between mt-3'>
                    <div className='flex gap-x-2 items-center'> <p className={` `}>Extra Services</p><ChevronDownIcon onClick={() => setShowServices((prev) => !prev)}  className={`${showServices ? "rotate-180" : ""} mt-1 cursor-pointer`} /></div>
                      <p className={``}>{pricing_data?.extra_service_price_total}</p>
                    </div>
                <div className={`${showServices ? 'block' : "hidden"}`}>
                        {pricing_data && Object.keys(pricing_data?.extra_service_price_key_detail)?.length > 0 && (
                            <>
                              {Object.keys(pricing_data?.extra_service_price_key_detail)?.map((data, idx) => (
                                 <div key={idx}>
                                     <div className='flex items-center justify-between ml-3 mt-1'>
                                        <p className={``}>{extraServicesHashMap[data]?.name} </p>
                                        <p className={``}>{pricing_data?.extra_service_price_key_detail[data]}</p>
                                     </div>
                                 </div>
                              ))}
                            </>
                        )}
                </div>

                <div className='flex items-center justify-between mt-3'>
                    <div className='flex gap-x-2 items-center'> <p className={``}>Tax</p><ChevronDownIcon onClick={() => setShowTax((prev) => !prev)}  className={`${showTax ? "rotate-180" : ""} mt-1 cursor-pointer`} /></div>
                      <p className={``}>{pricing_data?.tax_price_total}</p>
                    </div>

                    <div className={`${showTax ? 'block' : "hidden"}`}>
                        {pricing_data && Object.keys(pricing_data?.tax_price_detail)?.length > 0 && (
                            <>
                                {Object.keys(pricing_data?.tax_price_detail)?.map((data, idx) => (
                                    <div key={idx} className='flex items-center justify-between ml-3 mt-1'>
                                        <p className={``}>{data}</p>
                                        <p className={``}>{pricing_data?.tax_price_detail[data]}</p>
                                    </div>
                                ))}
                            </>
                        )}
                    </div>

               <div className="flex items-center justify-between">
                   <p>Total Amount</p>
                   <p>{reservationsData?.total_amount}</p>
               </div>

               <div className="flex items-center justify-between">
                   <p>Total Discount</p>
                   <p>{reservationsData?.total_discount}</p>
               </div>

               <div className="flex items-center justify-between">
                   <p>Balance Amount</p>
                   <p>{reservationsData?.balance_amount}</p>
               </div>

               <div className="flex items-center justify-between">
                   <p>Amount paid</p>
                   <p>{reservationsData?.amount_paid}</p>
               </div>
            </div>
        </div>
    </div>
  )
}

export default ReservationDetail