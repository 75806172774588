import api from '../api'
import endPoints from './endPoints';

export async function getAllFaqs() {
  try  {
    const response = await api.get(endPoints.getAllFaqs());
    return response
  }
  catch (e) {
    throw new Error(e?.message)
  }
}

export async function getFaqsById(id) {
  try  {
    const response = await api.get(endPoints.getFaqById(id));
    return response
  }
  catch (e) {
    throw new Error(e?.message)
  }
}

export async function postFaqs(body) {
  try  {
    const response = await api.post(endPoints.createFaq(), body);
    return response
  }
  catch (e) {
    throw new Error(e?.message)
  }
}


export async function putFaqs(body, id) {
  try  {
    const response = await api.put(endPoints.updateFaq(id), body);
    return response
  }
  catch (e) {
    throw new Error(e?.message)
  }
}

export async function deleteFaqs(id) {
  try  {
    const response = await api.delete(endPoints.deleteFaq(id));
    return response
  }
  catch (e) {
    throw new Error(e?.message)
  }
}



