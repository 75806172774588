import api from '../api'
import endPoints from './endPoints';

export async function getProcessingFee(){
    try {
        const response = await api.get(endPoints.getAllProcessingFee())
        if ( response.data.error ) {
          throw new Error( response?.data?.message)
        }
        return response?.data?.data
      }
      catch ( e ) {
        throw new Error( e )
      }
}

export async function createProcessingFee(body){
    try {
        const response = await api.post(endPoints.createProcessingFee(), body)
        if ( response.data.error ) {
          throw new Error( response?.data?.message)
        }
        return response?.data?.data
      }
      catch ( e ) {
        throw new Error( e )
      }
}

export async function updateProcessingFee(body){
    try {
        const response = await api.put(endPoints.updateProcessingFee(), body)
        if ( response.data.error ) {
          throw new Error( response?.data?.message)
        }
        return response?.data?.data
      }
      catch ( e ) {
        throw new Error( e )
      }
}

export async function deleteProcessingFee(id){
    try {
        const response = await api.delete(endPoints.deleteProcessingFee(id))
        if ( response.data.error ) {
          throw new Error( response?.data?.message)
        }
        return response?.data?.data
    }
    catch ( e ) {
        throw new Error( e )
    }
}
