
export const validateUserPayload = ( payload ) => {
    if (!payload?.fname) {
       throw new Error('First Name Required')
    }
    if (!payload?.lname) {
        throw new Error('Last Name Required')
    }
    // if (!payload?.dob) {
    //     throw new Error('Date of birth Required')
    // }
    // if (!payload?.password) {
    //     throw new Error('Password Required')
    // }
    if (!payload?.email) {
        throw new Error('Email Required')
    }
    if (!payload?.mobile_ext) {
        throw new Error('Country code Required')
    }
    if (!payload?.mobile) {
        throw new Error('Mobile Required')
    }
    if (!payload?.gender) {
        throw new Error('Gender Required')
    }
    if (!payload?.user_role) {
        throw new Error('User Role Required')
    }
}