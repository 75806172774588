import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { BOOKING_CHANNEL_ARR } from '../../../constants/otherPlatforms';
import { AddIcon } from '../../../icons';
import MyComboBox from '../../myComboBox';
import { loaderStart, loaderSuccess, error } from '../../../store/reducers/loaderSlice';
import { createOtherPlatformBooking, getAllOtherPlatformBookingsById } from '../../../services/otherPlatforms/booking';
import { getAllOtherPlatformUsers , createOtherPlatformUser} from '../../../services/otherPlatforms/users';

const INPUT_CLASS =
  "w-full placeholder:italic placeholder:text-slate-500 block bg-white border border-slate-300 rounded-md py-1 pl-3 pr-3 shadow-sm focus:outline-none focus:border-red-300 focus:ring-red-300 focus:ring-1 sm:text-sm";


function ChannelBooking({
  setPageLoad,
  closeModal,
  users,
  listings,
  request,
  setRequest,
  pageConfig,
  setPageConfig,
}) {
  const [user, setUser] = useState({
    isUser: true,
    fname: "",
    lname: "",
    email_id: "",
    mobile: "",
    channel_name: "",
  });
  const [bookingData, setBookingData] = useState({});
  const [bookingInput, setBookingInput] = useState({});
  const [showAddIcon, setShowAddIcon] = useState(true);
  const [showFields, setShowFields] = useState(true);

  const dispatch = useDispatch();



  useEffect(() => {
    fillUserDetails();
  }, [bookingData.id]);

  useEffect(() => {
    getNewUserId();
  }, [request]);

  function handleInput(e, type) {
    switch (type) {
      case "fname": {
        setUser({ ...user, fname: e.target.value });
        break;
      }
      case "lname": {
        setUser({ ...user, lname: e.target.value });
        break;
      }
      case "channel_name": {
        setUser({ ...user, user_channel: e.target.value });
        break;
      }
      case "email_id": {
        setUser({ ...user, email_id: e.target.value });
        break;
      }
      case "mobile": {
        setUser({ ...user, mobile: e.target.value });
        break;
      }
      default:
        return '';
    }
  }

  function fillUserDetails() {
    if (bookingData.id) {
      setUser(users.find((user) => user?.id === bookingData?.id));
    }
  }

  function getNewUserId() {
    let uid = users?.find((myUser) => myUser?.email_id === user?.email_id);
   
  }

  function addNewChannelUser() {
   
    dispatch(loaderStart());
    createOtherPlatformUser({
      fname: user?.fname,
      lname: user?.lname,
      email_id: user?.email_id,
      mobile: user?.mobile,
      user_channel: user?.user_channel,
    })
      .then((res) => {
        dispatch(loaderSuccess());
        console.log("user crteated: ", res);
        setShowFields((prev) => !prev);
        setRequest((prev) => !prev);
        setUser({ ...user, isUser: true });
        console.log('userss', user)
      })
      .catch((err) => {
        console.log("error creating user", err);
        dispatch(err(err?.message));
      });
    setShowAddIcon((prev) => !prev);
  }

  
  async function createNewBooking() {
  //  console.log('email id', user)
    try {
      dispatch(loaderStart());
      let id = users.filter((all) => all?.email_id === user?.email_id);
      let newUser = users.find((user) => user?.id === id);
      setUser(newUser);
      // console.log('id', id)
      // setBookingData({ ...bookingData, id: id });

      // console.log('booking data', bookingData)

      const response = await createOtherPlatformBooking({
        customer_id: bookingData?.id,
        property_id: bookingData.listing_id,
        booking_number: bookingInput.booking_number,
        booking_type: bookingInput.booking_type,
        booking_date: bookingInput.booking_date,
        checkin: bookingInput.checkin,
        checkout: bookingInput.checkout,
        total_adults: bookingInput.total_adults,
        total_childrens: bookingInput.total_childrens,
        total_infants: bookingInput.total_infants,
        promo_code: bookingInput.promo_code,
        total_amount: bookingInput.total_amount,
        discount_amount: bookingInput.discount_amount,
        grand_total: bookingInput.grand_total,
        amount_paid: bookingInput.amount_paid,
        due_amount: bookingInput.due_amount,
        booking_status: bookingInput.booking_status,
        payment_status: bookingInput.payment_status,
        is_partpayment: bookingInput.is_partpayment,
        payment_method: bookingInput.payment_method,
        booking_channel: bookingInput.booking_channel,
        deduction_amount:bookingInput.deduction_amount,
        deduction_reason: bookingInput.deduction_reason,
      })

      dispatch(loaderSuccess());
      setPageLoad((prev) => !prev)
      closeModal();
    }
    catch (e) {
      dispatch(error(e?.message));
      console.log("you have a error in bookings", e);
    }
  
   
  }

  function handleBookingInput(e, type) {
    console.log(type, e.target.value);
    let val = e.target.value;
    switch (type) {
      case "booking_date": {
        setBookingInput({ ...bookingInput, booking_date: e.target.value });
        break;
      }
      case "channel_name": {
        
        setBookingInput({ ...bookingInput, booking_channel: e.target.value });
        break;
      }
      case "checkin": {
        setBookingInput({ ...bookingInput, checkin: e.target.value });
        break;
      }
      case "checkout": {
        setBookingInput({ ...bookingInput, checkout: e.target.value });
        break;
      }
      case "total_adults": {
        val = Number.parseInt(val);
        setBookingInput({ ...bookingInput, total_adults: val });
        break;
      }
      case "total_childrens": {
        val = Number.parseInt(val);
        setBookingInput({ ...bookingInput, total_childrens: val });
        break;
      }
      case "total_infants": {
        val = Number.parseInt(val);
        setBookingInput({ ...bookingInput, total_infants: val });
        break;
      }
      case "booking_number": {
        
        setBookingInput({ ...bookingInput, booking_number: val });
        break;
      }
      case "booking_type": {
        
        val = Number.parseInt(val);
        setBookingInput({ ...bookingInput, booking_type: val });
        break;
      }
      case "booking_status": {
       
        val = Number.parseInt(val);
        setBookingInput({ ...bookingInput, booking_status: val });
        break;
      }
      case "payment_method": {
       
        val = Number.parseInt(val);
        setBookingInput({ ...bookingInput, payment_method: val });
        break;
      }
      case "payment_status": {
        
        val = Number.parseInt(val);
        setBookingInput({ ...bookingInput, payment_status: val });
        break;
      }
      case "is_partpayment": {
        
        val = Number.parseInt(val);
        setBookingInput({ ...bookingInput, is_partpayment: val });
        break;
      }

      case "promo_code": {
        setBookingInput({ ...bookingInput, promo_code: e.target.value });
        break;
      }
      case "total_amount": {
        val = Number.parseInt(val);
        setBookingInput({ ...bookingInput, total_amount: val });
        break;
      }
      case "deduction_amount": {
        val = Number.parseInt(val);
        setBookingInput({ ...bookingInput, deduction_amount: val });
        break;
      }
      case "deduction_reason": {
        // val = Number.parseInt(val);
        setBookingInput({ ...bookingInput, deduction_reason: val });
        break;
      }

      case "discount_amount": {
        val = Number.parseInt(val);
        setBookingInput({ ...bookingInput, discount_amount: val });
        break;
      }
      case "grand_total": {
        val = Number.parseInt(val);
        setBookingInput({ ...bookingInput, grand_total: val });
        break;
      }
      case "amount_paid": {
        val = Number.parseInt(val);
        setBookingInput({ ...bookingInput, amount_paid: val });
        break;
      }
      case "due_amount": {
        val = Number.parseInt(val);
        setBookingInput({ ...bookingInput, due_amount: val });
        break;
      }
      default:
        return;
    }
  }

  function handleAddIconNavigation() {
    setShowFields((prev) => !prev);
  }


  

  return (
    <div>
       <div className="mt-2">
                  {/* property combo-box */}
                    <div className="mt-4 flex flex-row flex-wrap  shadow-lg border py-4 px-3 rounded-xl">
                      <div className="w-[47%] mt-3  mr-5">
                        <label className="pb-2 ml-1 block text-slate-500">
                          checkin <sup className="text-danger">*</sup>
                        </label>
                        <input
                          type="Date"
                          className={`${INPUT_CLASS}`}
                          onChange={(e) => handleBookingInput(e, "checkin")}
                        />
                      </div>
                      <div className="w-[47%] mt-3 mr-5">
                        <label className="pb-2 ml-1 block text-slate-500">
                          checkout <sup className="text-danger">*</sup>
                        </label>
                        <input
                          type="Date"
                          className={`${INPUT_CLASS}`}
                          onChange={(e) => handleBookingInput(e, "checkout")}
                        />
                      </div>
                      <div className="w-[100%] mt-3 mr-5 z-10">
                        <label className="pb-1 ml-1 block text-slate-500">
                          Select Property <sup className="text-danger">*</sup>
                        </label>
                        <MyComboBox
                          data={listings}
                          title="title"
                          label="Select Property"
                          myKey="listing_id"
                          newData={bookingData}
                          setNewData={setBookingData}
                        />
                      </div>
                      <div className="w-[30%] mt-3 mr-5">
                        <label className="pb-2 ml-1 block text-slate-500">
                          Booking Date <sup className="text-danger">*</sup>
                        </label>
                        <input
                          type="Date"
                          className={`${INPUT_CLASS}`}
                          onChange={(e) =>
                            handleBookingInput(e, "booking_date")
                          }
                        />
                      </div>
                      <div className="w-[30%] mt-3 mr-5">
                        <label className="pb-2 ml-1 block text-slate-500">
                          Booking Channel
                        </label>
                        <select
                          className={`${INPUT_CLASS}`}
                          onChange={(e) =>
                            handleBookingInput(e, "channel_name")
                          }
                        >
                          <option value="">Choose Booking Channel</option>
                          {BOOKING_CHANNEL_ARR.map((channel) => (
                            <option value={channel?.value}>
                              {" "}
                              {channel?.name}{" "}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="w-[30%] mt-3 mr-5">
                        <label className="pb-1 ml-1 block text-slate-500">
                          Booking Number <sup className="text-danger">*</sup>
                        </label>
                        <input
                          type="text"
                          onChange={(e) =>
                            handleBookingInput(e, "booking_number")
                          }
                          className={`${INPUT_CLASS}`}
                        />
                      </div>
                      <div className="w-[30%] mt-3 mr-5">
                        <label className="pb-1 ml-1 block text-slate-500">
                          Booking Type <sup className="text-danger">*</sup>
                        </label>
                        <select
                          className={`${INPUT_CLASS}`}
                          onChange={(e) =>
                            handleBookingInput(e, "booking_type")
                          }
                        >
                          <option>select Booking Type: </option>
                          <option value="1">On-line</option>
                          <option value="2">Off-line</option>
                        </select>
                      </div>
                      <div className="w-[30%] mt-3 mr-5">
                        <label className="pb-1 ml-1 block text-slate-500">
                          Booking Status <sup className="text-danger">*</sup>
                        </label>
                        <select
                          className={`${INPUT_CLASS}`}
                          onChange={(e) =>
                            handleBookingInput(e, "booking_status")
                          }
                        >
                          <option>select Booking Status: </option>
                          <option value="0">Pending</option>
                          <option value="3">Processing</option>
                          <option value="5">Completed</option>
                          <option value="7">Refunded</option>
                          <option value="10">Cancelled</option>
                        </select>
                      </div>
                    </div>

                    {/* user personal detail */}
                    <div className="shadow-lg border mt-4 p-4 rounded-xl">
                      <div className="flex flex-row items-center justify-between">
                        <div
                          className={`${
                            showFields ? "w-full" : "w-[30%] mt-4"
                          }`}
                        >
                          <label className="pb-1 block ml-1 text-slate-500">
                            Select User <sup className="text-danger">*</sup>
                          </label>
                          <div className="flex flex-row items-center">
                            <MyComboBox
                              data={users}
                              firstOption={`"email_id": "abc@x.com"`}
                              title="email_id"
                              myKey="id"
                              label="Select User"
                              newData={bookingData}
                              setNewData={setBookingData}
                              user={user}
                              setUser={setUser}
                            />

                            <AddIcon onClick={handleAddIconNavigation} />
                          </div>
                        </div>
                        <div
                          className={`${
                            showFields ? "hidden" : "mt-4 w-[30%]"
                          }`}
                        >
                          <label className="pb-1 block ml-1  text-slate-500">
                            First Name <sup className="text-danger">*</sup>
                          </label>
                          <input
                            className={`${INPUT_CLASS}`}
                            onChange={(e) => handleInput(e, "fname")}
                            value={user?.fname ? user.fname : ""}
                          />
                        </div>
                        <div
                          className={`${
                            showFields ? "hidden" : "mt-4 w-[30%]"
                          }`}
                        >
                          <label className="pb-1 ml-1 block text-slate-500">
                            Last Name
                          </label>
                          <input
                            className={`${INPUT_CLASS}`}
                            onChange={(e) => handleInput(e, "lname")}
                            value={user?.lname}
                          />
                        </div>
                      </div>
                      <div
                        className={`${
                          showFields ? "hidden" : ""
                        } flex flex-row items-center justify-between`}
                      >
                        <div
                          className={`${
                            showFields ? "hidden " : "w-[30%] mt-4"
                          }`}
                        >
                          <label className="pb-1 ml-1 block text-slate-500">
                            Channel
                          </label>
                          <select
                            className={`${INPUT_CLASS}`}
                            onChange={(e) => handleInput(e, "channel_name")}
                            value={user?.user_channel ? user.user_channel : ""}
                          >
                            <option value="">Choose Booking Channel</option>
                            {BOOKING_CHANNEL_ARR.map((channel) => (
                              <option value={channel?.value}>
                                {" "}
                                {channel?.name}{" "}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div
                          className={`${
                            showFields ? "hidden " : "w-[30%] mt-4"
                          }`}
                        >
                          <label className="pb-1 ml-1 block text-slate-500">
                            Email <sup className="text-danger">*</sup>
                          </label>

                          <input
                            className={`${INPUT_CLASS}`}
                            onChange={(e) => handleInput(e, "email_id")}
                            value={user?.email_id ? user.email_id : ""}
                          />
                        </div>

                        <div className="w-[30%] mt-4">
                          <label className="pb-1  ml-1 block text-slate-500">
                            Mobile
                          </label>
                          <input
                            className={`${INPUT_CLASS}`}
                            onChange={(e) => handleInput(e, "mobile")}
                            value={user?.mobile ? user.mobile : ""}
                          />
                        </div>
                      </div>
                      <div className="flex justify-between mr-4">
                        <button className={`${showFields ? "hidden" : ""}  mt-8`}  onClick={handleAddIconNavigation}>Cancel</button>
                        <button  className={`${showFields ? "hidden" : ""}  mt-8`}  onClick={addNewChannelUser}>Add User</button>
                        {/* <Button
                          type="cancel"
                          size="sm"
                          value="Cancel"
                          className={`${showFields ? "hidden" : ""}  mt-8`}
                          onClick={handleAddIconNavigation}
                        /> */}
                        {/* <Button
                          type="primary"
                          size="sm"
                          value="Add User"
                          className={`${showFields ? "hidden" : ""}  mt-8`}
                          onClick={addNewChannelUser}
                        /> */}
                      </div>

                      
                    </div>

                    <div className="shadow-lg border mt-4 p-3 rounded-xl">
                      <div className="flex flex-row items-center justify-between">
                        <div className="mt-2  w-[30%]">
                          <label className="pb-1 ml-1 block text-slate-500">
                            Adults <sup className="text-danger">*</sup>
                          </label>
                          <input
                            type="Number"
                            className={`${INPUT_CLASS}`}
                            min="1"
                            onChange={(e) =>
                              handleBookingInput(e, "total_adults")
                            }
                          />
                        </div>
                        <div className="mt-2 w-[30%]">
                          <label className="pb-1 ml-1 block text-slate-500">
                            Kids
                          </label>
                          <input
                            type="Number"
                            onChange={(e) =>
                              handleBookingInput(e, "total_childrens")
                            }
                            className={`${INPUT_CLASS}`}
                            min="0"
                          />
                        </div>
                        <div className="mt-2  w-[30%]">
                          <label className="pb-1 ml-1 block text-slate-500">
                            Infants
                          </label>
                          <input
                            type="Number"
                            onChange={(e) =>
                              handleBookingInput(e, "total_infants")
                            }
                            className={`${INPUT_CLASS}`}
                            min="0"
                          />
                        </div>
                      </div>
                    </div>

                    {/* payment-details */}
                    <div className="shadow-lg border mt-4 p-4 rounded-xl">
                      <div className="flex flex-row items-center justify-between">
                        <div className="mt-4  w-[30%]">
                          <label className="pb-1 ml-1 block text-slate-500">
                            Promo Code
                          </label>
                          <input
                            className={`${INPUT_CLASS}`}
                            onChange={(e) =>
                              handleBookingInput(e, "promo_code")
                            }
                          />
                        </div>
                        <div className="mt-4  w-[30%]">
                          <label className="pb-1 ml-1 block text-slate-500">
                            Total Amount <sup className="text-danger">*</sup>
                          </label>
                          <input
                            type="number"
                            className={`${INPUT_CLASS}`}
                            onChange={(e) =>
                              handleBookingInput(e, "total_amount")
                            }
                          />
                        </div>
                        <div className="mt-4  w-[30%]">
                          <label className="pb-1 ml-1 block text-slate-500">
                            Discount
                          </label>
                          <input
                            type="number"
                            className={`${INPUT_CLASS}`}
                            onChange={(e) =>
                              handleBookingInput(e, "discount_amount")
                            }
                          />
                        </div>
                      </div>

                      <div className="flex flex-row items-center justify-between">
                        <div className="mt-4  w-[30%]">
                          <label className="pb-1 ml-1 block text-slate-500">
                            Grand Total
                          </label>
                          <input
                            type="number"
                            className={`${INPUT_CLASS}`}
                            onChange={(e) =>
                              handleBookingInput(e, "grand_total")
                            }
                          />
                        </div>
                        <div className="mt-4  w-[30%]">
                          <label className="pb-1 ml-1 block text-slate-500">
                            Amount Paid
                          </label>
                          <input
                            type="number"
                            className={`${INPUT_CLASS}`}
                            onChange={(e) =>
                              handleBookingInput(e, "amount_paid")
                            }
                          />
                        </div>
                        <div className="mt-4  w-[30%]">
                          <label className="pb-1 ml-1 block text-slate-500">
                            Due Amount
                          </label>
                          <input
                            type="number"
                            className={`${INPUT_CLASS}`}
                            onChange={(e) =>
                              handleBookingInput(e, "due_amount")
                            }
                          />
                        </div>
                      </div>

                      <div className="mb-2 flex flex-row items-center justify-between">
                        <div className="mt-4  w-[30%]">
                          <label className="pb-1 ml-1 block text-slate-500">
                            Part Payment <sup className="text-danger">*</sup>
                          </label>
                          <select
                            className={`${INPUT_CLASS}`}
                            onChange={(e) =>
                              handleBookingInput(e, "is_partpayment")
                            }
                          >
                            <option>select payment Type: </option>
                            <option value="2">Part</option>
                            <option value="1">Full</option>
                          </select>
                        </div>
                        <div className="mt-4  w-[30%]">
                          <label className="pb-1 ml-1 block text-slate-500">
                            Payment Status <sup className="text-danger">*</sup>
                          </label>
                          <select
                            className={`${INPUT_CLASS}`}
                            onChange={(e) =>
                              handleBookingInput(e, "payment_status")
                            }
                          >
                            <option>select Payment Status: </option>
                            <option value="0">Pending</option>
                            <option value="3">Processing</option>
                            <option value="5">Completed</option>
                            <option value="7">Refund</option>
                            <option value="10">Cancelled</option>
                            <option value="11">Failed</option>
                            <option value="13">Abandoned</option>
                          </select>
                        </div>
                        <div className="mt-4  w-[30%]">
                          <label className="pb-1 ml-1 block text-slate-500">
                            Payment Method
                          </label>
                          <select
                            className={`${INPUT_CLASS}`}
                            onChange={(e) =>
                              handleBookingInput(e, "payment_method")
                            }
                          >
                            <option>select Payment Method: </option>
                            <option value="1">Cash</option>
                            <option value="2">credit-card</option>
                            <option value="3">paypal</option>
                          </select>
                        </div>
                        
                      </div>
                      <div className="flex  items-start gap-x-3 w-full">
                      <div className="mt-4  w-[30%]">
                          <label className="pb-1 ml-1 block text-slate-500">
                            Deduction Amount
                          </label>

                          <input
                            type="number"
                            className={`${INPUT_CLASS}`}
                            onChange={(e) =>
                              handleBookingInput(e, "deduction_amount")
                            }
                          />
                         
                        </div>
                      
                        <div className="mt-4  w-[50%]">
                          <label className="pb-1 ml-1 block text-slate-500">
                           Deduction Reason
                          </label>

                          <textarea onChange={(e) => handleBookingInput(e, "deduction_reason")} className={`${INPUT_CLASS}`}>

                          </textarea>
                          
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* buttons */}
                  <div className="mt-12  flex justify-between">
                    <button  onClick={closeModal}>Cancel</button>
                    <button onClick={createNewBooking}>Add</button>
                    {/* <Button
                      type="cancel"
                      value="Cancel"
                      size="sm"
                      onClick={closeModal}
                    />
                    <Button
                      type="success"
                      value="Add"
                      size="sm"
                      onClick={createNewBooking}
                    /> */}
                  </div>
    </div>
  )
}

export default ChannelBooking