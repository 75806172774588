import React, { useState } from 'react'
import { useDestinationHashMapSelector, useDestinationsSelector, useRegionsHashMapSelector, useRegionsSelector } from '../../../../store/selectors';
import Dialog from '../../../../ui/dialog';
import UploadImage from '../../../../ui/input/uploadImage';
import { INPUT_STYLE } from '../../../../constants'

function SearchOptions({searchOptionsPayload, setShowOptionsPayload, handleCreate, searchOptionsEditIndex, closeModal}) {
 
  const [showUploadDialog, setShowUploadDialog] = useState(false)

  const regionsData = useRegionsSelector();
  const destinationsData = useDestinationsSelector();
  const regionHashMap  = useRegionsHashMapSelector();
  const destinationHashMap = useDestinationHashMapSelector();


  const handleOnChange = (e, key ) => {
    let value = e.target.value 
    if ( key === 'item_type' ) {
      setShowOptionsPayload((prev) => ({...prev,  [key]:value, item_ref_id:'', item_label:''}))
    }
    else if ( key === 'item_ref_id') {
        let item_label 
        let item_image

        if ( searchOptionsPayload?.item_type === 'region' ) {
            item_label = regionHashMap[value]?.name
            item_image = regionHashMap[value]?.icon_path
        }
        else if ( searchOptionsPayload?.item_type === 'destination' ) {
            item_label = destinationHashMap[value]?.name
            item_image = destinationHashMap[value]?.icon_path
        }

        setShowOptionsPayload((prev) => ({...prev,  [key]:value, item_label, item_image}))
    }
    else {
      setShowOptionsPayload((prev) => ({...prev,  [key]:value}))
    }
  }

  const handleRenderOptions = () => {
    switch ( searchOptionsPayload?.item_type ) {
        case 'region':
          return regionsData?.map((region) => <option value={region.id}>{region?.name}</option>);

        case 'destination':
            return destinationsData?.map((destination) => <option value={destination?.id}>{destination?.name}</option>)

        default:
            break 
    }

  }

  const handleIcon = (url) => {
    setShowOptionsPayload((prev) => ({...prev,  item_image:url}))
  }



  
  

  return (
    <>
     <div className='flex flex-col'>
        <label className='text-slate-600'>Type</label>
        <select className={INPUT_STYLE} value={searchOptionsPayload?.item_type} onChange={(e) => handleOnChange(e, 'item_type')}>
            <option value="region">Region</option>
            <option value="destination">Destination</option>
        </select>
     </div>


     <div className='flex flex-col mt-4'>
        <label  className='text-slate-600'>Reference</label>
        <select className={INPUT_STYLE} value={searchOptionsPayload?.item_ref_id}  onChange={(e) => handleOnChange(e, 'item_ref_id')} >
            <option>Choose {searchOptionsPayload?.item_type}</option>
            {handleRenderOptions()}
        </select>
     </div>

     <div className='flex flex-col mt-4'>
        <label  className='text-slate-600'>Sequence</label>
        <input className={INPUT_STYLE} value={searchOptionsPayload?.item_sequence} onChange={(e) => handleOnChange(e, 'item_sequence')}  type="number"/>
     </div>

     <div className='flex items-center mt-4 w-full gap-x-2'>
        <div className='flex flex-col w-full'>
          <label  className='text-slate-600'>Image</label>
          <input className={INPUT_STYLE} disabled  value={searchOptionsPayload?.item_image}/>
        </div>
        <button className='bg-black text-white px-3 py-2 rounded-md hover:bg-white hover:text-black border border-black mt-3' onClick={() => setShowUploadDialog((prev) => !prev)}>Upload</button>
     </div>


     <Dialog showCreateClick={false}  childrenClass={"w-[30%] p-6 rounded-md no-scrollbar dark-scrollbar"} isOpen={showUploadDialog} closeModal={() => setShowUploadDialog((prev) => !prev)}>
        <UploadImage setShowUploadDialog={setShowUploadDialog} setIconPath={handleIcon} title={searchOptionsPayload?.item_label}/>
     </Dialog>

     
     
     <div className='mt-10 flex items-center justify-between'>
        <button className='border border-black rounded-md px-3 py-2 hover:bg-black hover:text-white' onClick={closeModal}>Close</button>
        <button className='bg-black text-white px-3 py-2 rounded-md' onClick={handleCreate}>{searchOptionsEditIndex ===  null ? "Create" : "Update"}</button>
     </div>

    </>
  )
}

export default SearchOptions