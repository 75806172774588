import React, { useEffect, useState } from "react";
import Button from "../../button/index";
import FormInputs from "../../popup/formInputs";
import Button2 from "../button";
import Dialog from "../../../ui/dialog";
import UploadImage from "../../../ui/input/uploadImage";

const RoomArrangeMentForm = ({ setPop, setPayload, editData, eventType ,payload}) => {
  const [uploadState, setUploadState] = useState({ index: null, url: "" });
  const resetFormData = {
    name: "",
    details: [
      {
        name: "",
        description: "",
        price: "",
        additional_info: "",
        sequence: 1,
        image: "",
      },
    ],
  };
  const [formData, setFormData] = useState({ ...resetFormData });
  // const [edit, setEditData] = useState([]);
  const [showUploadDialog, setShowUploadDialog] = useState(false);

  useEffect(() => {
    if(eventType ==="Edit")setFormData(editData);
  }, []);

  const updateRoomArrangements = () => {
    const index = editData.index;
    // console.log(payload.room_arrangement,"--------------payload",index)
    // console.log(formData,"formData-------------------")
    // console.log("update", payload.room_arrangement[index]);
    delete formData.index;
    setPayload(prev => {
      prev.room_arrangement[index] = formData;
      return prev;
    })
    setPop((prev) => ({ ...prev, isEdit: false }));
    setFormData(resetFormData);
  };


  const createNewRoomArrangements = () => {
    console.log("Create");
    // payload.room_arrangement.push(formData);
    setPayload((prev) => {
      prev.room_arrangement.push(formData);
      // const temp = { ...prev, room_arrangement: [formData] };
      console.log(prev.room_arrangement);
      return prev;
    });
    setPop((prev) => ({ isCreate: false, ...prev }));
    setFormData(resetFormData);
  };

  const hidePopup = () => {
    // setBasicDialogOpen(false);
    eventType === "Create" ?
      setPop((prev) => ({ ...prev, isCreate: false })) :
      setPop((prev) => ({ ...prev, isEdit: false }));
      
  };
  // console.log(payload.room_arrangement,"-------------");

  const clone = (items) => structuredClone(items);

  const setNewFormData = (index, fieldName, value) => {
    const formDetails = clone({ ...formData });
    formDetails.details[index][fieldName] = value;
    // console.log(formDetails);
    setFormData(formDetails);
  };

  const addFormFields = () => {
    let formDetails = clone(...resetFormData.details);
    formDetails.sequence = formData.details.length + 1;
    setFormData((prev) => {
      // prev.details.push(formDetails);
        const newDetails = [...prev.details, formDetails];
      return { name: prev.name, details: newDetails };
    });
    console.log(formData);
  };

  const removeFormFields = (index) => {
    console.log("removeFormFields", index);
    setFormData((prev) => {
      const newDetails = prev.details.filter((elm, inx) => inx !== index);
      newDetails.map((elm, index) => (elm.sequence = index + 1));
      //  console.log(newDetails);
      return { ...prev, details: newDetails };
    });
  };
  

  function handleImage(url) {
    formData.details.map((el, index) => {
      if (index === uploadState.index) {
        setUploadState({ ...uploadState, url });
        el.image = url;
      }
    });
    console.log(formData, "--------------");
    //   setFormData(prev=> {...prev,details:newDetails})
    // setFormData({...formData,formData.details.})
  }

  return (
    <div className="max-h-[400px]">
      <div>
        <FormInputs
          type="text"
          label="Heading"
          className="w-[100%]"
          value={formData.name}
          onChange={(e) => {
            e.preventDefault();
            setFormData({ ...formData, name: e.target.value });
          }}
        />
        {formData?.details?.map((eachItem, index) => (
          <div key={index} className="flex flex-col gap-4 my-2">
            <div className="flex justify-between items-center">
              <div className="mt-2">item - {index + 1}</div>
              {formData?.details.length > 1 && (
                <div
                  onClick={() => removeFormFields(index)}
                  className="cursor-pointer"
                >
                  ❌
                </div>
              )}
            </div>
            <div className="flex justify-between flex-wrap">
              <FormInputs
                type="text"
                label="Name"
                value={formData.details[index].name}
                className="w-[49%]"
                onChange={(e) => {
                  e.preventDefault();
                  setNewFormData(index, "name", e.target.value);
                }}
              />
              <FormInputs
                type="text"
                label="Description"
                value={formData.details[index].description}
                className="w-[49%]"
                onChange={(e) =>
                  setNewFormData(index, "description", e.target.value)
                }
              />
            </div>
            <div className="flex justify-between flex-wrap">
              <FormInputs
                type="number"
                label="Price"
                value={formData.details[index].price}
                className="w-[49%]"
                onChange={(e) => {
                  e.preventDefault();
                  setNewFormData(index, "price", e.target.value);
                }}
              />
              <FormInputs
                type="text"
                label="Additional Info"
                value={formData.details[index].additional_info}
                className="w-[49%]"
                onChange={(e) => {
                  e.preventDefault();
                  setNewFormData(index, "additional_info", e.target.value);
                }}
              />
              <div className="flex justify-between !items-center flex-wrap w-[100%]">
                <FormInputs
                  type="text"
                  label="Icon Path"
                  value={formData.details[index].image}
                  className="w-[49%]"
                  readInput={"cursor-not-allowed bg-slate-300"}
                  disabled
                  // onChange={setUploadState.url}
                />
                <Button2
                  className="mt-6"
                  onClick={() => {
                    setUploadState({ index, ...setUploadState });
                    setShowUploadDialog((prev) => !prev);
                  }}
                >
                  Upload
                </Button2>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-end mt-4">
        <Button
          className="p-2"
          type="secondary"
          value={"Add"}
          onClick={(e) => {
            e.preventDefault();
            addFormFields();
          }}
        />
      </div>
      <div className="flex justify-between mt-4">
        <Button
          buttonType="button"
          className="p-2"
          type="outline"
          value={"Cancel"}
          onClick={hidePopup}
        />
        {eventType === "Create" && (
          <Button
            className="p-2"
            type="secondary"
            value={"Create"}
            onClick={(e) => {
              e.preventDefault();
              hidePopup();
              createNewRoomArrangements()
            }}
          />
        )}
    
        {eventType==="Edit" && 
              <Button
                className="p-2"
                type="secondary"
                value={"Update"}
                onClick={(e) => {
                  hidePopup();
                  e.preventDefault();
                 updateRoomArrangements()
                }}
              />
            }
      </div>
      <Dialog
        parentClass={"no-scrollbar"}
        childrenClass="w-[400px] h-[280px]"
        showCreateClick={false}
        isOpen={showUploadDialog}
        closeModal={() => setShowUploadDialog((prev) => !prev)}
      >
        <UploadImage
          setShowUploadDialog={setShowUploadDialog}
          setIconPath={handleImage}
          title=""
        />
      </Dialog>
    </div>
  );
};

export default RoomArrangeMentForm;
