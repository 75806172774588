import React, { useEffect, useState } from "react";
import Title from "../title";
import Button2 from "../button";
import Dialog from "../../../ui/dialog";
import NearByForm from "./nearByForm";
import DeletePopup from "../../popup/deletePopup";

const NearBy =({ payload, setPayload }) => {
  const [pageLoad, setPageLoad] = useState(false);
  const [pop, setPop] = useState({
    isCreate: false,
    isEdit: false,
    isDelete: false,
  });

  const [deletedObj, setDeletedObj] = useState(null);
  const [edit, setEditData] = useState([]);

  useEffect(() => {
    // console.log("useEffect");
  }, [payload]);

  const hidePopup = (item) => {
    if (item === "Create") setPop({ ...pop, isCreate: false });
    if (item === "Edit") setPop({ ...pop, isEdit: false });
    if (item === "Delete") setPop({ ...pop, isDelete: false });
  };
  const visibleCreatePopup = () => {
    // console.log("open--", pop);
    setPop((prev) => ({ ...prev, isCreate: true }));
  };

  const setUpdateHandler = (index) => {
    // console.log(payload.room_arrangement[index]);
    const object = payload.nearby[index];
    // console.log(object)
    setEditData({ ...object, index });
    setPop((prev) => ({ ...prev, isEdit: true }));
    // console.log(object);
  };

  const deleteHandler = () => {
    const index = deletedObj.index;
    // console.log(index,payload.room_arrangement);
    setPayload((prev) => {
      prev.nearby.splice(index, 1);
      // console.log(prev.room_arrangement)
      return prev;
    });
    setPageLoad((prev) => !prev);
  };

  // console.log(payload);
  return (
    <div className="">
      <div className="flex items-center justify-between">
        <Title>NearBy</Title>
        <Button2 onClick={visibleCreatePopup}>Add</Button2>
      </div>
      {/* all room_arrangement list */}
      <div className="mt-10">
        {payload.nearby &&
          payload?.nearby.map((elm, index) => (
            <div className="flex flex-col my-2 rounded-lg p-3" key={index}>
              <div className="flex justify-between">
                <span className="text-xl font-semibold ">{elm?.name}</span>
                <div className="flex gap-2 ">
                  <div className="flex flex-col gap-y-2">
                    <Button2 onClick={() => setUpdateHandler(index)}>
                      Edit
                    </Button2>
                  </div>

                  <div className="flex flex-col gap-y-2">
                    <Button2
                      onClick={() => {
                        setDeletedObj({
                          index: index,
                          Description: `${elm.name}`,
                        });
                        setPop({ ...pop, isDelete: true });
                        // deleteHandler(index);
                      }}
                    >
                      Delete
                    </Button2>
                  </div>
                </div>
              </div>
              {/* details */}
              <div className="flex gap-8 flex-wrap lg p-2 ">
                {elm?.details.map((detail, inx) => (
                  <div
                    key={`${detail.name}`}
                    className="flex p-4  w-[500px] border rounded-lg"
                  >
                    <div className="w-[80%] flex justify-between">
                      <span className="w-[30%] text-lg font-medium">
                        {detail.name}
                      </span>
                      <span className="">{detail.value}</span>
                    </div>
                    {/* <div></div> */}
                  </div>
                ))}
              </div>
            </div>
          ))}
      </div>

      {/* //Delete popup */}
      {pop.isDelete && (
        <DeletePopup
          deletedObj={deletedObj}
          setIsOpen={setPop}
          // close={handleClose}
          title="Room-Arrangements"
          onDeleteList={deleteHandler}
        />
      )}

      <Dialog
        closeModal={() => hidePopup("Create")}
        isOpen={pop.isCreate}
        createClick={pop.isCreate}
        childrenClass="bg-white p-5 rounded-lg w-[50%]"
      >
        <NearByForm
          payload={payload}
          eventType="Create"
          setPop={setPop}
          setPayload={setPayload}
          editData={{
            name: "",
            details: [
              {
                name: "",
                value: "",
                sequence: 1,
              },
            ],
          }}
          // setShowUploadDialog={setShowUploadDialog}
        />
      </Dialog>
      <Dialog
        closeModal={() => hidePopup("Edit")}
        isOpen={pop.isEdit}
        createClick={pop.isEdit}
        childrenClass="bg-white p-5 rounded-lg w-[50%]"
      >
        <NearByForm
          payload={payload}
          eventType="Edit"
          setPop={setPop}
          setPayload={setPayload}
          editData={edit}
          // fun={fun}
          // setShowUploadDialog={setShowUploadDialog}
        />
      </Dialog>
    </div>
  );
};

export default NearBy;
