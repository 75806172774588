import { useSelector } from 'react-redux';

//country
export const useCountrySelector = () => useSelector((state) =>   state?.country?.countryList);
export const useCountryHashMapSelector = () => useSelector((state) => state?.country?.countryHashMap)

//region
export const useRegionsSelector = () => useSelector((state) => {return  state?.regions?.regions?.data});
export const useRegionsHashMapSelector = () =>  useSelector((state) =>  state?.regions?.regions?.hashMap)

//destination
export const useDestinationsSelector = () => useSelector((state) =>  state?.destinations?.destinations?.data)
export const useDestinationHashMapSelector = () => useSelector((state) => state?.destinations?.destinations?.hashMap)

//categories
export const useCategoriesSelector = () => useSelector((state) => state?.attributes?.categories?.data);
export const useCategoriesHashMapSelector = () =>  useSelector((state) => state?.attributes?.categories?.hashMap);

//amenities
export const useAmenitiesSelector = () => useSelector((state) => state?.attributes?.amenities?.data);
export const useAmenitiesHashMapSelector = () => useSelector((state) => state?.attributes?.amenities?.hashMap);

//activities
export const useActivitiesAttractionsSelector = () => useSelector((state) => state?.attributes?.activitiesAttractions?.data);
export const useActivitiesAttractionsHashMapSelector = () => useSelector((state) => state?.attributes?.activitiesAttractions?.hashMap);

//pets
export const usePetsSelector = () => useSelector((state) => state.attributes.pets);

//addons
export const useAddonsSelector = () => useSelector((state) => state.attributes.addons.data);
export const useAddonsHashMapSelector = () => useSelector((state) => state.attributes.addons.hashMap);

//EXTRA SERVICES
export const useExtraServicesSelector = () => useSelector((state) => state.attributes.extraServices.data);
export const useExtraServicesHashMapSelector = () => useSelector((state) => state.attributes.extraServices.hashMap);


//LISTINGS 
export const useListingsSelector = () => useSelector((state) => state.attributes.listings.data);
export const useListingsHashMapSelector = () => useSelector((state) => state.attributes.listings.hashMap);
