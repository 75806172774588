import React, { useEffect, useState } from "react";
import PageHeader from "../../../components/pageHeader";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  loaderStart,
  loaderSuccess,
} from "../../../store/reducers/loaderSlice";
import { DeleteIcon, EditIcon } from "../../../icons";
import Dialog from "../../../ui/dialog";
import DetailsPageForm from "../../../components/forms/listingAttributes/faqs/detailsPageForm";
import { getAllFaqs, getFaqsById, putFaqs } from "../../../services/faqsServices";
import DeletePopup from "../../../components/popup/deletePopup";

const FaqsDetailsPage = () => {
  const { id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const [deletedObj, setDeleteDetails] = useState(null);
  const [errorInApi, setErrorInApi] = useState(false);
  const [pop, setPop] = useState({
    isDelete: false,
    isEdit: false,
    isCreate: false,
  });
  const [pageLoad, setPageLoad] = useState(false);
  const [data, setData] = useState([]);
  const [object, setObject] = useState({});
  const [editData, setEditData] = useState([]);

  useEffect(() => {
    // setData(location.state?.details);
    getData();
  }, [pageLoad]);

  
  const handleOpen = (index,item) => {
    const { title} = item;
    setDeleteDetails({ ID: id,  title ,index});
    setPop({ ...pop, isDelete: true });
  };
  const hideCreatePopup = () => {
    setPop({ ...pop, isCreate: false });
  };
  const handleClose = () => {
    setPop({ ...pop, isDelete: false });
  }
 

  const hideEditPopup = () => {
    setPop({ ...pop, isEdit: false });
  };

  const getData = async () => {
    dispatch(loaderStart());
    try {
      const response = await getFaqsById(id);
      // console.log(response.data.data)
      setObject(response.data.data);
      setData(response.data.data.details);
      // setPageLoad(prev => !prev);
      dispatch(loaderSuccess());
      // console.log("this is faqs checking", response);
      // setData(response.data.data[id]);
    } catch (error) {
      dispatch(error(error?.response?.data?.error?.message));
    }
  };


  const setUpdateHandler = async (index) => {
    // console.log(object.details[index]);
    const obj = [];
    obj.title = object.details[index].title;
    obj._id = object.details[index]._id;
    obj.faqs = [];
    // obj.sequence = object.details[index].sequence;
    // obj.faqs.push()
    object.details[index].faqs.map(elm => { 
      // console.log(elm);
      const t = {
        question:elm.question,
        answer: elm.answer,
        link:elm.link
      }
      obj.faqs.push(t);

    })
    setEditData(obj);
  }


  
  const createDetails = () => {
    setPop({ ...pop, isCreate: true });
  };

  // api calls-----------------

  const updateDetails = async (payload,id) => {
    try {
      const response = await putFaqs(payload, id);
      console.log(response);
      dispatch(loaderSuccess());
      setPageLoad((prev) => !prev);
    } catch (error) {
      dispatch(error(error?.response?.data?.error?.message));
    }
  };


  const deleteFaqHandler = async (index) => {
     dispatch(loaderStart());
    try {
      const newData = data.filter((elm, inx) => index !== inx);
      console.log("delte", index);
      const response = await putFaqs({details : newData}, id);
      // console.log(response);
      setPageLoad(prev => !prev);
      dispatch(loaderSuccess());
    } catch (error) {
      dispatch(error(error?.response?.data?.error?.message));
    }
    
  }

 

  // console.log(location)

  return (
    <div className="h-screen">
      <div className="m-8 ">
        <PageHeader title="Details" onClick={createDetails} />
      </div>
      <div className="text-start mx-10">
        {/* all title with question box */}
        <div className="text-lg ">
          {data &&
            data?.map((elm, index) => (
              <div key={elm.title} className="flex border-b w-[100%]">
                <h2 className="py-4 font-semibold  w-[30%]">{elm.title}</h2>
                <div className="flex flex-col  items-center w-[70%]">
                  {/* <div className="bg-white drop-shadow-md w-[90%] p-2 rounded-md flex flex-col gap-4"> */}
                    <div className="flex items-center justify-between w-[100%] text-left">
                    {/* ques, ans ,link */}
                      <div className="mt-6 flex flex-col gap-4">
                        {elm.faqs?.map((el, index) => (
                          <div key={el.id} className="">
                            <div className="flex  flex-col ">
                              <span className="font-semibold">{el.question}</span>
                              <span className="">{el.answer}</span>
                              {/* <span className="">{el.link}</span> */}
                            </div>
                          </div>
                        ))}
                      </div>
                      {/* Delte and Edit Icon */}
                      <div className="flex justify-between gap-2">
                        <EditIcon
                          className="!text-black !font-[1000]"
                          onClick={() => {
                            setPop((prev) => ({ ...prev, isEdit: true }));
                            setUpdateHandler(index);
                          }}
                        />
                        {/* {console.log(elm.title, "------------")} */}
                        <DeleteIcon
                          className="!text-black !font-[1000]"
                          onClick={() => {
                            // deleteFaqHandler(index);
                            handleOpen(index, elm);
                            // setPop({ ...pop, isDelete: true });
                          }}
                        />
                      </div>
                    </div>
                  {/* </div> */}
                </div>
              </div>
            ))}
        </div>
        {/* =====================||======================= */}
        {pop.isCreate && (
          <Dialog
            closeModal={hideCreatePopup}
            isOpen={pop.isCreate}
            createClick={pop.isCreate}
            title="Details"
            childrenClass={
              "w-[50%]  p-6 rounded-md no-scrollbar dark-scrollbar"
            }
          >
            <DetailsPageForm
              object={object}
              id={id}
              setPageLoad={setPageLoad}
              btn={pop}
              isEdit={pop.isEdit}
              hidePopup={hideCreatePopup}
              updateData={{
                title: "",
                faqs: [
                  {
                    question: "",
                    answer: "",
                    link: "",
                  },
                ],
                // sequence: 1,
                //  _id: generateUUID(),
              }}
              handleRequest={updateDetails}
            />
          </Dialog>
        )}

        {pop.isEdit && (
          <Dialog
            closeModal={hideEditPopup}
            isOpen={pop.isEdit}
            createClick={pop.isEdit}
            title="Details"
            childrenClass={
              "w-[50%]  p-6 rounded-md no-scrollbar dark-scrollbar"
            }
          >
            <DetailsPageForm
              object={object}
              id={id}
              setPageLoad={setPageLoad}
              btn={pop}
              isEdit={pop.isEdit}
              hidePopup={hideEditPopup}
              updateData={editData}
              handleRequest={updateDetails}
            />
          </Dialog>
        )}

        {pop.isDelete  && (
          <DeletePopup
            isOpen={pop.isOpen}
            deletedObj={deletedObj}
            setIsOpen={setPop}
            close={handleClose}
            title="Faqs"
            onDeleteList={()=>deleteFaqHandler(deletedObj.index)}
          />
      )}
      </div>
    </div>
  );
};

export default FaqsDetailsPage;
