import api from '../api'
import endPoints from "./endPoints";

export async function getAllAddons() {
  try {
    const response = await api.get(endPoints.getAllAddons())
    return response
  }
  catch (e) {
    throw new Error(e)
  }
}

export async function postAddons(formData) {
  try {
    let response = await api.post(endPoints.postAddons(), formData);
    return response 
  }
  catch (e) {
    throw new Error(e)
  }
}

export async function putAddons(formData, id) {
  try {
    let response = await api.put(endPoints.putAddons(), formData);
    return response
  }
  catch (e) {
    throw new Error(e)
  }
}

export async function deleteAddons(id) {
  try {
    let response = await api.put(endPoints.deleteAddons(id));
    return response
  }
  catch (e) {
   throw new Error(e)
  }
}
