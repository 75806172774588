import axios from "axios";
import api from '../api'
import endPoints from "./endPoints";


export const getAllDestinations = async () =>  {
  try {
    const response = await api.get(endPoints.getAllDestinations())
    return response
  }
  catch ( e ) {
    throw new Error( e )
  }
}

export const getDestinationsData = async () => {
    try {
      const response = await api.get(endPoints.getAllDestinations())
      if ( response.data.error ) {
        throw new Error( response.data.message)
      }
      return response.data.data
    }
    catch ( e ) {
      throw new Error( e )
    }
}

export const createDestination = async ( payload ) => {
  try {
    const response = await api.post(endPoints.createDestination(), payload);
    if ( response.data.error ) {
      throw new Error( response.data.message)
    }
    return response.data.data
  }
  catch (e) {
    throw new Error( e )
  }
}


export const updateDestination =  async ( payload ) => {
  try {
    const response = await api.put(endPoints.updateDestination(), payload);

    if ( response.data.error ) {
      throw new Error( response.data.message)
    }
    return response.data.data
  }
  catch (e) {
    throw new Error( e )
  }
}

export const deleteDestination = async (id) => {
  try {
    const response = await api.delete(endPoints.deleteDestination(id));

    if ( response.data.error ) {
      throw new Error( response.data.message)
    }
    return response.data.data
  }
  catch (e) {
    throw new Error( e )
  }
}





