export const BOOKING_CHANNEL_ARR = [
    { name:"Airbnb", value:"Airbnb" },
    { name: "Vrbo", value:"Vrbo", },
    { name: "Booking.com", value:"Booking.com" },
    { name: "TripAdvisor", value:"TripAdvisor" },
    { name: "Plumguide" , value:"Plumguide"},
    { name: "Expedia" , value:"Expedia"},
    { name: "Ab S" , value:"Ab S"},
    { name: "Ab SS" , value:"Ab SS" },
    { name: "Ab E" , value:"Ab E"},
    { name: "Ab R" , value:"Ab R"},
    { name: "Ab K" , value:"Ab K"},
    { name: "HK" , value:"HK"},
    { name : "AB E NEW", value:"AB E NEW"},
    { name: "New VRBo", value:"New VRBo"},
    { name: "AB P", value:"AB P"},
    { name: "Direct Bookings", value:"Direct Bookings"}
  ];

  

export const BOOKING_TYPE_ARR = [{ online: 1 }, { offline: 2 }];

export const BOOKING_STATUS_ARR = [
  { pending: 0 },
  { processing: 3 },
  { completed: 5 },
  { refunded: 7 },
  { cancelled: 10 },
];

export const PAYMENT_STATUS_ARR = [
  { pending: 0 },
  { processing: 3 },
  { completed: 5 },
  { refund: 7 },
  { cancelled: 10 },
  { failed: 11 },
  { abandoned: 13 },
];

export const ORDER_STATUS_ARR = [
  { pending: 0 },
  { processing: 3 },
  { completed: 5 },
  { refund: 7 },
  { cancelled: 10 },
];

export const IS_PART_PAYMENT_ARR = [{ full: 1 }, { part: 2 }];

export const PAYMENT_METHOD_ARR = [
  { cash: 1 },
  { "credit-card": 2 },
  { paypal: 3 },
];


