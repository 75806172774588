export const times = [
    { key: "12:00 AM", value: 0 },
    { key: "1:00 AM", value: 1 },
    { key: "2:00 AM", value: 2 },
    { key: "3:00 AM", value: 3 },
    { key: "4:00 AM", value: 4 },
    { key: "5:00 AM", value: 5 },
    { key: "6:00 AM", value: 6 },
    { key: "7:00 AM", value: 7 },
    { key: "8:00 AM", value: 8 },
    { key: "9:00 AM", value: 9 },
    { key: "10:00 AM", value: 10 },
    { key: "11:00 AM", value: 11 },
    { key: "12:00 PM", value: 12 },
    { key: "1:00 PM", value: 13 },
    { key: "2:00 PM", value: 14 },
    { key: "3:00 PM", value: 15 },
    { key: "4:00 PM", value: 16 },
    { key: "5:00 PM", value: 17 },
    { key: "6:00 PM", value: 18 },
    { key: "7:00 PM", value: 19 },
    { key: "8:00 PM", value: 20 },
    { key: "9:00 PM", value: 21 },
    { key: "10:00 PM", value: 22 },
    { key: "11:00 PM", value: 23 },
  ];
  