import api from '../api'
import endPoints from './endPoints';

export async function getHouseRules() {
  try  {
    const response = await api.get(endPoints.getAllHouseRules());
    return response
  }
  catch (e) {
    throw new Error(e?.message)
  }
}

export async function postHouseRules(body) {
  try  {
    const response = await api.post(endPoints.createHouseRule(), body);
    return response
  }
  catch (e) {
    throw new Error(e?.message)
  }
}

export async function editHouseRules(body) {
  try  {
    const response = await api.put(endPoints.updateHouseRule(), body);
    return response
  }
  catch (e) {
    throw new Error(e?.message)
  }
}

export async function deleteHouseRules(id) {
  try  {
    const response = await api.delete(endPoints.deleteHouseRule(id));
    return response
  }
  catch (e) {
    throw new Error(e?.message)
  }
}





