const statusTypes = {
    "primary": "bg-[#c6ecf5] text-[#0097E6] ",
    "secondary": "bg-[#fcc2e8] text-[#E31C9E] ",
    "danger": "bg-[#fae6ea] text-white text-[#D3232F] ",
    "warning": "bg-[#fcd386] text-[#FF8000]",
    "success": "text-[#20A548] bg-[#e6f8eb]",
    "warning-outline": "bg-[#f5ca9f] text-[#FF8000]",
    "success-outline" : "text-[#20A548] bg-[#e6f8eb]"

  };
  
  const sizes = {
     "sm": "px-3 ",
    "lg": "px-4 py-3",
  };


function Status(props){
  const { type, value, size, className} = props;
  let backgroundColor = statusTypes[type];
  let statusSize = sizes[size];
  return(
    <div className={` ${backgroundColor} ${statusSize}   rounded min-w-max ${className}`}>
    {value}
    </div>
  )
}
export default Status