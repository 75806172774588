import React, { useState } from "react";
import { useSelector } from "react-redux";
import Button from "../../../components/button";
import FormInputs from "../../../components/popup/formInputs";
import { INPUT_STYLE, currencyData } from "../../../constants";

const formList = [
  {
    id: 1,
    type: "text",
    label: "Name",
    apiKey: "name",
    optionsList: "",
  },
  {
    id: 2,
    type: "text",
    label: "Label",
    apiKey: "label",
    optionsList: "",
  },
  {
    id: 3,
    type: "select",
    label: "Type",
    apiKey: "type",
    optionsList: [
      {
        id: 1,
        optionValue: "flat",
        name: "Flat",
      },
      {
        id: 2,
        optionValue: "percentage",
        name: "Percentage",
      },
    ],
  },
  {
    id: 6,
    type: "number",
    label: "Value",
    apiKey: "value",
    optionsList: "",
  },
  {
    id: 4,
    type: "number",
    label: "Min",
    apiKey: "min",
    optionsList: "",
  },
  {
    id: 5,
    type: "number",
    label: "Max",
    apiKey: "max",
    optionsList: "",
  },
  {
    id: 7,
    type: "select",
    label: "Per Person",
    apiKey: "per_person",
    optionsList: [
      {
        id: 1,
        optionValue: false,
        name: "false",
      },
      {
        id: 2,
        optionValue: true,
        name: "true",
      },
    ],
  },
  {
    id: 8,
    type: "select",
    label: "Per Night",
    apiKey: "per_night",
    optionsList: [
      {
        id: 1,
        optionValue: false,
        name: "false",
      },
      {
        id: 2,
        optionValue: true,
        name: "true",
      },
    ],
  },
  {
    id: 9,
    type: "select",
    label: "Per Bedroom",
    apiKey: "per_bedroom",
    optionsList: [
      {
        id: 1,
        optionValue: false,
        name: "false",
      },
      {
        id: 2,
        optionValue: true,
        name: "true",
      },
    ],
  },
];

function TaxForm({ isEdit, close, editData, handleRequest, value, createClick,}) {
  const [formData, setFormData] = useState({ ...editData });
  const { countryList } = useSelector((state) => state.country);

  const handleSubmit = (e) => {
    e.preventDefault();
    close();
    handleRequest(formData);
    Object.keys(formData).forEach((e) => (formData[e] = ""));
    setFormData(formData);
  };
  
  
  return (
    <div>
      {isEdit && (
        <form onSubmit={handleSubmit}>
          <div className="flex justify-between flex-wrap">
            <FormInputs
              objKey="id"
              optionsData={countryList}
              label="Country Name"
              type="select"
              className="w-[49%] mt-2"
              value={formData.country_id}
              onChange={(e) =>
                setFormData({ ...formData, country_id: e.target.value })
              }
            />

           <div className="flex flex-col w-[49%] mt-2">
             <label className="text-slate-600  text-md mb-1">Choose Curreny</label>
              <select className={`${INPUT_STYLE}`} value={formData?.currency} onChange={(e) => setFormData({...formData, currency:e.target.value})}>
                {/* <option>Choose Curreny</option> */}
                {Object.keys(currencyData)?.map((data, idx) => (
                  <option key={idx} value={data}>{data}  {currencyData[data]?.symbol} </option>
                ))}
              </select>
           </div>

            {formList.map((eachItem) => (
              <FormInputs
                objKey="optionValue"
                optionsData={eachItem.optionsList}
                key={eachItem.id}
                label={eachItem.label}
                type={eachItem.type}
                value={formData[eachItem.apiKey]}
                className="w-[49%] mt-2"
                onChange={(e) =>
                  setFormData(
                    createClick
                      ? { ...formData, [eachItem.apiKey]: e.target.value }
                      : eachItem.apiKey !== "slug"
                      ? { ...formData, [eachItem.apiKey]: e.target.value }
                      : { ...formData }
                  )
                }
              />
            ))}
          </div>
          <div className="flex justify-between mt-10">
            <Button type="outline" value="Cancel" onClick={close} />
            <Button buttonType="submit" value={value} type="secondary" />
          </div>
        </form>
      )}
    </div>
  );
}
export default TaxForm;
