import React, { useEffect, useState } from "react";
import Button from "../../button/index";
import FormInputs from "../../popup/formInputs";
import Button2 from "../button";
import Dialog from "../../../ui/dialog";
import UploadImage from "../../../ui/input/uploadImage";

const ShortDescriptionForm = ({
  setPop,
  setPayload,
  editData,
  eventType,
  payload,
}) => {
  const [uploadState, setUploadState] = useState({ index: null, url: "" });
  const resetFormData = {
    name: "",
    description: "",
    items: [
      {
        name: "",
        image: "",
        description: "",
        sequence: 1,
      },
    ],
  };
  const [formData, setFormData] = useState(editData);
  const [showUploadDialog, setShowUploadDialog] = useState(false);

  // useEffect(() => {
  //   setFormData(editData);
  // }, []);
  // console.log(formData);

  const updateNearBy = () => {
    const index = editData.index;
    // console.log(payload.room_arrangement,"--------------payload",index)
    // console.log("update", payload.room_arrangement[index]);
    delete formData.index;
    // console.log(formData, "formData-------------------")
    setPayload((prev) => {
      prev.nearby[index] = formData;
      return prev;
    });
    setPop((prev) => ({ ...prev, isEdit: false }));
    setFormData(resetFormData);
  };
  // console.log(payload.nearby,"-------");

  const createShortDescription = () => {
      console.log("Create",formData);
      
    // payload.room_arrangement.push(formData);
    setPayload((prev) => ({ ...prev, short_description : formData}));
    setPop((prev) => ({ isCreate: false, ...prev }));
    // setFormData(resetFormData);
  };

  const hidePopup = () => {
    // setBasicDialogOpen(false);
    setPop((prev) => ({ ...prev, isCreate: false }));
  };

  const clone = (items) => structuredClone(items);

  const setNewFormData = (index, fieldName, value) => {
    const formDetails = clone({ ...formData });
    formDetails.items[index][fieldName] = value;
    // console.log(formDetails);
    setFormData(formDetails);
  };

  const addFormFields = () => {
    let formDetails = clone(...resetFormData.items);
    // formDetails.sequence = formData.details.length + 1;
    setFormData((prev) => {
      // prev.details.push(formDetails);
      const newDetails = [...prev.items, formDetails];
      // console.log(prev);
      return {
        name: prev.name,
        description: prev.description,
        items: newDetails,
      };
    });
    console.log(formData);
  };

  const removeFormFields = (index) => {
    // console.log("removeFormFields", index,formData);

    const newDetails = formData.items.filter((elm, inx) => inx !== index);
    setFormData((prev) => {
      // newDetails.map((elm, index) => (elm.sequence = index + 1));
      const obj = { ...prev, items: newDetails };
      //   console.log(obj);
      return obj;
    });
  };

  function handleImage(url) {
    formData.items.map((el, index) => {
      if (index === uploadState.index) {
        setUploadState({ ...uploadState, url });
        el.image = url;
      }
    });
  }

  return (
    <div className="max-h-[400px]">
      <div>
        {/* <FormInputs
          type="text"
          label="Heading"
          className="w-[100%]"
          value={formData.name}
          onChange={(e) => {
            e.preventDefault();
            setFormData({ ...formData, name: e.target.value });
          }}
        />
        <FormInputs
          type="text"
          label="Description"
          className="w-[100%]"
          value={formData.description}
          onChange={(e) => {
            e.preventDefault();
            setFormData({ ...formData, description: e.target.value });
          }}
        /> */}
        {formData?.items?.map((eachItem, index) => (
          <div key={index} className="flex flex-col gap-4 my-2">
            <div className="flex justify-between items-center">
              <div className="mt-2 font-semibold">Item - {index + 1}</div>
              {formData?.items.length > 1 && (
                <div
                  onClick={() => removeFormFields(index)}
                  className="cursor-pointer"
                >
                  ❌
                </div>
              )}
            </div>
            <div className="flex justify-between flex-wrap">
              <FormInputs
                type="text"
                label="Name"
                labelStyle="text-lg font-semibold"
                value={formData.items[index].name}
                className="w-[49%]"
                onChange={(e) => {
                  e.preventDefault();
                  setNewFormData(index, "name", e.target.value);
                }}
              />
              <FormInputs
                type="text"
                label="Description"
                labelStyle="text-lg font-semibold"
                value={formData.items[index].description}
                className="w-[49%]"
                onChange={(e) =>
                  setNewFormData(index, "description", e.target.value)
                }
              />
            </div>
            <div className="flex justify-between">
              <FormInputs
                type="number"
                label="Sequence"
                labelStyle="text-lg font-semibold"
                value={formData.items[index].sequence}
                className="w-[49%]"
                onChange={(e) =>
                  setNewFormData(index, "sequence", Number(e.target.value))
                }
              />
              <FormInputs
                type="text"
                label="Image"
                labelStyle="text-lg font-semibold"
                value={formData.items[index].image}
                className="w-[49%]"
                onChange={(e) => setNewFormData(index, "image", e.target.value)}
                readInput={"cursor-not-allowed bg-slate-300"}
                disabled
              />
            </div>
            {/* upload field */}
            <div className="">
              <Button
                className="p-2 mt-6"
                type="secondary"
                value={"Upload"}
                onClick={() => {
                  console.log(index);
                  setUploadState({ index, ...setUploadState });
                  setShowUploadDialog((prev) => !prev);
                }}
              />
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-end mt-4">
        <Button
          className="p-2"
          type="secondary"
          value={"Add"}
          onClick={(e) => {
            addFormFields();
          }}
        />
      </div>
      <div className="flex justify-between mt-4">
        <Button
          buttonType="button"
          className="p-2"
          type="outline"
          value={"Cancel"}
          onClick={hidePopup}
        />
        {eventType === "Create" && (
          <Button
            className="p-2"
            type="secondary"
            value={"Create"}
            onClick={(e) => {
              hidePopup();
              createShortDescription();
            }}
          />
        )}

        {eventType === "Edit" && (
          <Button
            className="p-2"
            type="secondary"
            value={"Update"}
            onClick={(e) => {
              hidePopup();
              e.preventDefault();
              updateNearBy();
            }}
          />
        )}
      </div>
      <Dialog
        parentClass={"no-scrollbar"}
        childrenClass="w-[400px] h-[280px]"
        showCreateClick={false}
        isOpen={showUploadDialog}
        closeModal={() => setShowUploadDialog((prev) => !prev)}
      >
        <UploadImage
          setShowUploadDialog={setShowUploadDialog}
          setIconPath={handleImage}
          title=""
        />
      </Dialog>
    </div>
  );
};

export default ShortDescriptionForm;
