import React from 'react'
import { INPUT_STYLE } from '../../../constants'
import {BOOKING_CHANNEL_ARR} from '../../../constants/otherPlatforms'

function UserForm({ actionType, payload, setPayload, handleAction, handleCloseDialog  }) {

  const handleOnChange = (e, key) => {
    let value = e.target.value
    setPayload((prev) => ({...prev, [key]:value}))
  }
  

  return (
    <>
    <div className='flex justify-between flex-wrap'>

     <div className='flex flex-col w-[49%]'>
        <label>First Name</label>
        <input className={INPUT_STYLE} value={payload?.fname} onChange={(e) => handleOnChange(e, 'fname')}/>
     </div>

     <div className='flex flex-col w-[49%]'>
        <label>Last Name</label>
        <input className={INPUT_STYLE} value={payload?.lname} onChange={(e) => handleOnChange(e, 'lname')}/>
     </div>

     <div className='flex flex-col w-[49%]'>
        <label>Email Id</label>
        <input className={INPUT_STYLE} value={payload?.email_id} onChange={(e) => handleOnChange(e, 'email_id')}/>
     </div>

     <div className='flex flex-col w-[49%]'>
        <label>Mobile</label>
        <input className={INPUT_STYLE} value={payload?.mobile} onChange={(e) => handleOnChange(e, 'mobile')}/>
     </div>

     <div className='flex flex-col w-[49%] mt-1'>
        <label>Channel Name</label>
        <select onChange={(e) => handleOnChange(e, 'user_channel')} value={payload?.user_channel} className={INPUT_STYLE} >
            <option value="">Choose Channel</option>
            {BOOKING_CHANNEL_ARR?.map((channelName, idx) => (
                <option key={idx} value={channelName?.value}>{channelName?.name}</option>
            ))}
        </select>
     </div>

    </div>

    <div className='mt-10 flex items-center justify-between'>
        <button className='border border-black rounded-md px-3 py-2 hover:bg-black hover:text-white' onClick={handleCloseDialog}>Close</button>
        <button className='bg-black text-white px-3 py-2 rounded-md' onClick={handleAction}>{actionType === 'CREATE' ? 'Create' : "Update"}</button>
    </div>

    </>
  )
}

export default UserForm