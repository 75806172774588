import React from 'react'
import { INPUT_STYLE, currencyData } from '../../../constants'
import { useCountrySelector } from '../../../store/selectors'

function PlatformFeeForm({ actionType, payload, setPayload, handleAction, handleCloseDialog }) {

   const countries = useCountrySelector();

   const handleOnChange = (e, key) => {
      let value = e.target.value
      setPayload((prev) => ({...prev, [key]:value}))
   }

   const handleNumberChange = (e) => {
      const val = e.target.value;
      // Regex to check if the input is a valid number or empty (to allow clearing the input)
      if (/^\d*\.?\d*$/.test(val) || val === "") {
         setPayload((prev) => ({...prev, fee_amount:+val}))
      }
    };

   const handleSubmit = (e) => {
     e.preventDefault()
     handleAction()
   }

  return (
    <form onSubmit={handleSubmit} className='flex items-center justify-between flex-wrap'>
         <div className='w-[49%] mt-2'>
            <label className='text-slate-600'>Country</label>
            <select value={payload?.country_id} onChange={(e) => handleOnChange(e, 'country_id')} className={`${INPUT_STYLE}`}>
               <option>Choose an option</option>
               {countries?.map((country, idx) => (
                  <option key={idx} value={country?.id}>{country?.name}</option>
               ))}
            </select>
         </div>

         <div className='w-[49%] mt-2'>
            <label className='text-slate-600'>Name</label>
            <input value={payload?.name} onChange={(e) => handleOnChange(e, 'name')} className={`${INPUT_STYLE}`} />
         </div>

         <div className='w-[49%] mt-2'>
            <label className='text-slate-600'>Slug</label>
            <input value={payload?.slug} onChange={(e) => handleOnChange(e, 'slug')} className={`${INPUT_STYLE}`} />
         </div>

         <div className='w-[49%] mt-2'>
            <label className='text-slate-600'>Fee Type</label>
            <select value={payload?.fee_type} onChange={(e) => handleOnChange(e, 'fee_type')} className={`${INPUT_STYLE}`}>
                <option value="">Choose an Option</option>
                <option value="flat">Flat</option>
                <option value="percentage">Percentage</option>
            </select>
         </div>

         <div className='w-[49%] mt-2'>
            <label className='text-slate-600'>Currency</label>
            <select value={payload?.currency} onChange={(e) => handleOnChange(e, 'currency')} className={`${INPUT_STYLE}`}>
                <option value="">Choose an Option</option>
                {Object.keys(currencyData)?.map((data, idx) => (
                  <option key={idx} value={data}>{data} {currencyData[data]?.symbol}</option>
                ))}
            </select>
         </div>

         <div className='w-[49%] mt-2'>
            <label className='text-slate-600'>Fee Amount</label>
            <input  onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()} value={payload?.fee_amount} onChange={(e) => handleNumberChange(e)} className={`${INPUT_STYLE}`} />
         </div>

         <div className='w-[49%]'>
            <label className='text-slate-600'>Per person</label>
            <select value={payload?.per_person} onChange={(e) => handleOnChange(e, 'per_person')} className={`${INPUT_STYLE}`}>
                <option value={true}>True</option>
                <option value={false}>False</option>
            </select>
         </div>

         <div className='w-[49%] mt-2'>
            <label className='text-slate-600'>Per Night</label>
            <select value={payload?.per_night} onChange={(e) => handleOnChange(e, 'per_night')} className={`${INPUT_STYLE}`}>
                <option value={true}>True</option>
                <option value={false}>False</option>
            </select>
         </div>
        
         <div className='mt-10 flex items-center justify-between  w-full'>
            <button type='button' className='border border-black rounded-md px-3 py-2 hover:bg-black hover:text-white' onClick={handleCloseDialog}>Close</button>
            <button type='submit' className='bg-black text-white px-3 py-2 rounded-md'>{actionType === 'CREATE' ? 'Create' : "Update"}</button>
         </div>
    </form>
  )
}

export default PlatformFeeForm