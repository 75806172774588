import React, { useState } from 'react'
import { INPUT_STYLE } from '../../../../constants';
import Dialog from '../../../../ui/dialog';
import UploadImage from '../../../../ui/input/uploadImage';
import { useAmenitiesHashMapSelector, useAmenitiesSelector, useCategoriesHashMapSelector, useCategoriesSelector } from '../../../../store/selectors';

function FilterOptions({closeModal, editIndex, filterOptionsPayload, handleCreate, setFilterOptionsPayload}) {
  const [showUploadDialog, setShowUploadDialog] = useState(false)

  const categories = useCategoriesSelector();
  const categoriesHashMap = useCategoriesHashMapSelector();

  const amenities = useAmenitiesSelector();
  const amenitiesHashMap = useAmenitiesHashMapSelector();

  const handleOnChange = (e, key) => {
    let value = e.target.value 

    if ( key === 'type_ref_id' ) {
      if ( filterOptionsPayload?.type === 'category' ) {
        let url = categoriesHashMap[value]?.icon_path;
        let name = categoriesHashMap[value]?.name;
        setFilterOptionsPayload((prev) => ({...prev, [key]:value, image:url, name}));
      }
      else {
        let url = amenitiesHashMap[value]?.icon_path;
        let name = amenitiesHashMap[value]?.name;
        setFilterOptionsPayload((prev) => ({...prev, [key]:value, image:url, name}));
      }

    }
    else {
      setFilterOptionsPayload((prev) => ({...prev, [key]:value}));
    }
    
    
  }

  const handleOnChangeImage = ( url ) => {
    setFilterOptionsPayload((prev) => ({...prev, image:url}));
  }

  const renderOptions = () => {
    switch ( filterOptionsPayload?.type ) {
        case 'category':
          return categories?.map((category, idx) => <option key={idx} value={category?.id} >{category?.name}</option>);
        case 'amneties':
          return amenities?.map((category, idx) => <option key={idx} value={category?.id} >{category?.name}</option>);
        default:
          break
    } 
  }

  return (
    <>
    <div>
        <div className='flex flex-col'>
            <label className='text-slate-600'>Type</label>
            <select className={INPUT_STYLE} value={filterOptionsPayload?.type} onChange={(e) => handleOnChange(e, 'type')}>
                <option value="category">Category</option>
                <option value="amneties">Amneties</option>
            </select>
        </div>

        <div className='flex flex-col'>
            <label className='text-slate-600'>Type</label>
            <select className={INPUT_STYLE} value={filterOptionsPayload?.type_ref_id} onChange={(e) => handleOnChange(e, 'type_ref_id')}>
                <option value="">Choose {filterOptionsPayload?.type}</option>
                {renderOptions()}
            </select>
        </div>

        <div className='flex flex-col'>
            <label className='text-slate-600'>Sequence No</label>
            <input type="number" onWheel={(e) => e.target.blur()} className={INPUT_STYLE} value={filterOptionsPayload?.sequence_no} onChange={(e) => handleOnChange(e, 'sequence_no')}/>
        </div>



        <div className='flex flex-col'>
             <label className='text-slate-600'>Upload</label>
             <div>
               <input disabled className={`${INPUT_STYLE} cursor-not-allowed !bg-slate-100`} value={filterOptionsPayload?.image}/>
               <button onClick={() => setShowUploadDialog((prev) => !prev)}>Upload</button>
             </div>
        </div>

    </div>

    <Dialog showCreateClick={false}  childrenClass={"w-[30%] p-6 rounded-md no-scrollbar dark-scrollbar"} isOpen={showUploadDialog} closeModal={() => setShowUploadDialog((prev) => !prev)}>
        <UploadImage setShowUploadDialog={setShowUploadDialog} setIconPath={handleOnChangeImage} title={filterOptionsPayload?.name}/>
     </Dialog>

     <div className='mt-10 flex items-center justify-between'>
        <button className='border border-black rounded-md px-3 py-2 hover:bg-black hover:text-white' onClick={closeModal}>Close</button>
        <button className='bg-black text-white px-3 py-2 rounded-md' onClick={handleCreate}>{editIndex ===  null ? "Create" : "Update"}</button>
     </div>


    </>
  )
}

export default FilterOptions