import api from '../api'
import endPoints from './endPoints';

export async function getAllContinent() {
  try {
    const response = await api.get(endPoints.getAllContinents())
    return response
  }
  catch ( e ) {
    throw new Error( e )
  }
}
export async function createContinent(body) {
  try {
    const response = await api.post(endPoints.createContinent(), body)
    return response
  }
  catch ( e ) {
    throw new Error( e )
  }
}
export async function updateContinent(body) {
  try {
    const response = await api.put(endPoints.updateContinent(), body)
    return response
  }
  catch ( e ) {
    throw new Error( e )
  }
}

export async function deleteContinent(id) {
  try {
    const response = await api.delete(endPoints.deleteContinent(id))
    return response
  }
  catch ( e ) {
    throw new Error( e )
  }
}


