import api from '../api'
import endPoints from "./endPoints";

export const getImagesQuery = async ( payload ) => {
  try{
    const response  = await api.post(endPoints.getImagesQuery(), payload);
    return response
  }
  catch ( e ){
    throw new Error( e )
  }
}

export const uploadSingleImage = async ( payload ) => {
  try {
    let imageFormData  = new FormData();
    imageFormData.append( "image", payload );
    const response = await api.post( endPoints.uploadSingleImage(), imageFormData, {headers: {'Content-Type':'multipart/form-data'}} );
    return response
  }
  catch (e) {
    throw new Error(e)
  }
}

export const singleImageUpload = async (payload) => {
  try {
    let imageFormData = new FormData();
    imageFormData.append("image", payload);
    const response = await api.post(endPoints.singleImageUpload(), imageFormData, { headers: { "Content-Type": "multipart/form-data"}});
    return response;
  }
  catch (e) {
    throw new Error(e)
  }
}

export const uploadMultipleImages = async ( payload ) => {
  try {
   let imageFormData = new FormData();
   imageFormData.append('image', payload);
   const response = await api.post(endPoints.uploadMultipleImages(), imageFormData, {headers: {'Content-Type':'multipart/form-data'}})
   return response
  }
  catch ( e ) {
    throw new Error(e)
  }
} 

export const uploadMultipleListingImages = async ( payload ) => {
  try {
   let imageFormData = new FormData();
   imageFormData.append('image', payload);
   const response = await api.post(endPoints.uploadMultipleListingImages(), imageFormData, {headers: {'Content-Type':'multipart/form-data'}})
   return response
  }
  catch ( e ) {
    throw new Error(e)
  }
} 