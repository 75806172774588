import api from '../api'
import endPoints from "./endPoints";

export async function getAllUsersData() {
  try {
    let body = { filters: [{ col: "is_deleted", type: "number", val: "0" }], pagination:{limit:2000, page:1} }
    const response = await api.post( endPoints.getAllUsers(), body )
    return response.data
  }
  catch (e) {
    throw new Error(e)
  } 
}

export async function getAllUsers( body ) {
  try {
    const data = await api.post(endPoints.getAllUsers(), body);
    return data
  }
  catch(e) {
    throw new Error(e?.response?.data?.error?.message)
  }
}

export async function getUserById(id) {
  try {
    const data = await api.get(endPoints.getUserById(id));
    return data
  }
  catch(e) {
   throw new Error(e?.response?.data?.error?.message)
  }
}

export async function createUser(body) {
  try {
    const data = await api.post(endPoints.createUser(), body);
    return data
  }
  catch(e) {
   throw new Error(e?.response?.data?.error?.message)
  }
}

export async function updateUser(body, id) {
  try {
    const data = await api.put(endPoints.updateUser(id), body);
    return data
  }
  catch(e) {
    throw new Error(e?.response?.data?.error?.message)
  }
}


export async function deleteUser(id) {
  try {
    const data = await api.delete(endPoints.deleteUser(id));
    return data
 }
 catch(e) {
   throw new Error(e?.response?.data?.error?.message)
 }
}


