import React, { useState } from 'react'
import Step1 from './step1'
import Button from '../../button'
import Step2 from './step2'
import Step3 from './step3'
import { formatCheckoutDates } from '../../../utils/common'
import { error, loaderStart, loaderSuccess } from '../../../store/reducers/loaderSlice'
import { createCheckout, updateCheckoutSteps, getCheckoutByEid, makeManualPayment } from '../../../services/checkoutServices'
import { useDispatch } from 'react-redux'
import { validateMakePayment, validateStepOnePayload, validateStepThreePayload, validateStepTwoPayload } from '../../../validators/checkout'
import Step4 from './step4'
import ThankYouPage from './thankYouPage'

const CheckoutCreateForm = ({}) => {
  const [stepper, setStepper] = useState( 1 );
  const [button, setButton] = useState(1);
  const [payload1, setPayload1] = useState({
    listing_id: "", checkin: null, checkout: null,
    guests: [
      { "total": 0, "name": "Adults", "slug": "adults", "description": "adults" },
      { "total": 0, "name": "Children", "slug": "children", "description": "children" },
      { "total": 0, "name": "Infants", "slug": "infants", "description": "infants" }
    ],
    options: { currency: "USD" },
    overrides: {base_price:0, security_deposit:0, weekend_price:0},
    addons: []
  });

  const [payload2, setPayload2] = useState({ fname: "", lname: "",  email: "",  step: 1,  phone_ext: "", mobile: ""});
  const [payload3, setPayload3] = useState({ need_insurance: 0, agreement_status: 0, step: 2})
  const [payload4, setPayload4] = useState({vendor:'', payment_method:'', amount:'', currency: '', payment_date: '', txn_id1 :'', txn_id2: '', description: '',  meta: {part_payment: ''}})

  const [checkoutEid, setCheckoutEid] = useState(null)
  const [checkoutData, setCheckoutData] = useState(null);
  const [reservationData, setShowReservationData] = useState(null)

  const dispatch = useDispatch();

  const steps = [
    { step: 1, component: <Step1 payload={payload1} setPayload={setPayload1} /> },
    { step: 2, component: <Step2 checkoutData={checkoutData} payload={payload2} setPayload={setPayload2} /> },
    { step: 3, component: <Step3 payload={payload3} setPayload={setPayload3} /> },
    { step: 4, component: <Step4 payload2={payload2} checkoutData={checkoutData} payload={payload4} setPayload={setPayload4} /> },
    { step: 5, component: <ThankYouPage payload2={payload2} reservationData={reservationData} checkoutData={checkoutData}/> },
  ]

  const handleBack = () => {
     if (stepper > 1 && stepper < 5) {
       setStepper((prev) => prev - 1);
     }
   }

  //create checkout fisrt step
  const handleStepOne = async () => {
    try {
      if ( !checkoutEid && !checkoutData ) {
        //validation
        validateStepOnePayload(payload1);
        dispatch(loaderStart())
        let body = {...payload1, checkin:formatCheckoutDates(payload1?.checkin), checkout:formatCheckoutDates(payload1?.checkout)}
        const response = await createCheckout( body );

        //in response we will get checkout eid
        setCheckoutEid(response?.data?.checkout_eid);

        //get checkout data by eid for price break up
        const checkoutByEidResponse = await getCheckoutByEid(response?.data?.checkout_eid);
        setCheckoutData(checkoutByEidResponse?.data);

        //get checkout data by eid
        dispatch(loaderSuccess());
        setStepper(2)
      }
      else {
        setStepper(2)
      }


    }
    catch (e) {
      //show error popup
      dispatch(error(e?.message))
    }
  }

  const handleStepTwo = async () => {
    try {
       
      if ( checkoutEid && checkoutData) {
         validateStepTwoPayload(payload2);
         //checkout checkout eid is exists in state or not
          if ( !checkoutEid ) {
            throw new Error('Checkout Eid not found');
          }
          //update step 2 with basic user details like fname, lname, email, mobile
          dispatch(loaderStart());
          const response = await updateCheckoutSteps( payload2, checkoutEid );
          dispatch(loaderSuccess());
          setStepper(3)
      }
      else {
        setStepper(2)
      }
    }
    catch (e) {
      //show-error-popup
      dispatch(error(e?.message))
    }
  }

  const handleStepthree = async () => {
    try {

      if ( checkoutEid && checkoutData ) {
        validateStepThreePayload( payload3 );

        //checkout checkout eid is exists in state or not
        if ( !checkoutEid ) {
          throw new Error('Checkout Eid not found');
        };
  
        //update step 2 with basic user details like fname, lname, email, mobile
        dispatch(loaderStart());
        const response = await updateCheckoutSteps( payload3, checkoutEid );
        dispatch(loaderSuccess());
        setStepper(4)
      }
     
    }
    catch (e) {
      //show error popup
      dispatch(error(e?.message))
    }
  }

  const handleStepFour = async () => {
    try {
      //
      let payload = {...payload4, 
        user: {email_id:checkoutData.email, mobile:checkoutData.mobile, mobile_ext:checkoutData.phone_ext, fname:checkoutData.fname, lname:checkoutData.lname},
        source: {eid: checkoutData.eid, type:"checkout"},
        action:{type: checkoutData.reservation_id ? "repayment" : "create_order"},
        meta: {...payload4.meta, custom_amount:payload4.amount, final_amount:checkoutData.total_amount}
      }
      
      validateMakePayment(payload, checkoutData);
      dispatch(loaderStart())
      const response = await makeManualPayment({request_data:payload})
      setShowReservationData(response?.data)
      dispatch(loaderSuccess());
      setStepper(5)
    }
    catch (e) {
      dispatch(error(e?.message))
    }
  }


  const handlePublish = async (e) => {
    e.preventDefault();

    if (stepper == 1 ) {
      await handleStepOne()
      return
    }
    else if (stepper == 2) {
      await handleStepTwo()
      return
    }
    else if ( stepper == 3 ) {
      await handleStepthree()
      return
    }
    else if ( stepper === 4 ) {
      await handleStepFour();
      return
    }
  }

  return (
    <>
        <div>
          {steps?.map((each, idx) => (
            each.step === stepper && <div key={idx}>{each.component}</div>
          ))}
        </div>

        <div className=' flex items-center justify-between bottom-0 my-4'>
          {stepper !== 5 &&  <button onClick={handleBack} className={`${(stepper === 1 || stepper === 5) ? "bg-slate-200 cursor-not-allowed border bg-grey-100 " : "hover:bg-black hover:text-white"} px-3  py-2 border border-black rounded-md`}>Back</button>}
         
          {stepper !== 5 && <Button type="secondary" buttonType={"button"} value={"Next"} onClick={handlePublish} />}
        </div>
    </>
  )
}

export default CheckoutCreateForm