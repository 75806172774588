import api from '../api'
import endPoints from './endPoints';

export async function getAmenties() {
  try {
    const response = await api.get(endPoints.getAllAmenities())
    return response
  }
  catch ( e ) {
    throw new Error( e )
  }
}

export async function getAmenitiesGroup() {
  try {
    const response = await api.get(endPoints.getAllAmenitiesGroupQuery())
    return response
  }
  catch ( e ) {
    throw new Error( e )
  }
}

export async function createAmenties(body) {
  try {
    const response = await api.post(endPoints.createAmenities(), body)
    return response
  }
  catch ( e ) {
    throw new Error( e )
  }
}

export async function updateAmenties(body) {
  try {
    const response = await api.put(endPoints.updateAmenities(), body)
    return response
  }
  catch ( e ) {
    throw new Error( e )
  }
}

export async function deleteAmenties(id) {
  try {
    const response = await api.delete(endPoints.deleteAmenities(id))
    return response
  }
  catch ( e ) {
    throw new Error( e )
  }
}
