import React, { useState } from 'react'
import { uploadMultipleListingImages } from '../../services/uploads.js'

function PhotosUpload({count, setPayload}) {
  const [uploadImages, setUploadImages] = useState([])

  const input = React.useRef(null);

  const handleOnchangeImages = async (e) => {
    
    try {
      const response = await uploadMultipleListingImages( e.target.files[0] )
      let imageData = response?.data[0]
      setPayload((prev) => ({...prev, media: [...prev.media, {id:imageData?.id, sequence:1, title:'', description: '', is_highlighted:false, external_url:''} ]}))
    }
    catch ( ee ) {
      console.log('e', ee)
    }
  }

  const formats=["jpg", "png", "svg", "webp"]
  const openFileDialog = () => {
    input && input.current?.click();
  };

  

  return (
    <div>
        <h1 className='leading-[38.73px] text-[32px] font-medium'>Photos</h1>
        <div    onClick={openFileDialog} className='relative mt-10 border-dashed border flex flex-col justify-center items-center  p-5 text-[#00000099] font-base leading-5'>
            <p>Click to Upload</p>
            <span>Or</span>
            <p>Images Drag and Drop</p>
            <input 
             ref={input}
            multiple
            formats={formats.map((format) => `.${format}`).join(", ")}
            
             onChange={(e) => handleOnchangeImages(e)} type="file" className='absolute h-full w-full opacity-0'/>
        </div>

       
    </div>
  )
}

export default PhotosUpload