import api from '../api'
import endPoints from "./endPoints";

export async function getAllCheckout( body ) {
  try {
     const data = await api.post(endPoints.getAllCheckout(), body);
     return data
  }
  catch(e) {
    throw new Error(e?.response?.data?.error?.message)
  }
}

export async function getCheckoutByEid(id) {
  try {
   const response = await api.get(endPoints.getCheckoutById(id));
   return response
  }
  catch (e) {
    throw new Error(e?.response?.data?.error?.message)
  }
   
}


export async function createCheckout( body ) {
  try {
    const data = await api.post(endPoints.createCheckout(), body);
    return data
 }
 catch(e) {
   throw new Error(e?.response?.data?.error?.message)
 }
}

export async  function putCheckout(formData, id) {
  try {
    const data = await api.put(endPoints.updateCheckout(id), formData);
    return data
  }
  catch (e) {
    throw new Error(e?.response?.data?.error?.message)
  }
}

export async function updateCheckoutSteps(formData, id) {
  try {
    const data = await api.put(endPoints.createCheckoutSteps(id), formData);
    return data
  }
  catch (e) {
    throw new Error(e?.response?.data?.error?.message)
  }
}



export async function deleteCheckout(id) {
  try {
    const data = await api.delete(endPoints.deleteCheckout(id));
    return data
 }
 catch(e) {
   throw new Error(e?.response?.data?.error?.message)
 }
}


export async function makeManualPayment( body ) {
  try {
    const response = await api.post(endPoints.createManualPayment(), body)
    return response
  }
  catch (e) {
    throw new Error(e?.response?.data?.error?.message)
  }
}

