import React, { useEffect, useState } from "react";
import { loaderStart, loaderSuccess, error } from "../../../src/store/reducers/loaderSlice";
import { useDispatch } from "react-redux";
import { DeleteIcon, EditIcon } from "../../icons";
import DeletePopup from "../../components/popup/deletePopup";
import { formatDate, } from "../../utils/common";
import Table from "../../components/hkTable";
import PageHeader from "../../components/pageHeader";
import { deleteReservations, getAllReservations, updateReservations,} from "../../services/reservations";
import Dialog from "../../ui/dialog";
import { useNavigate } from "react-router-dom";
import {showNotifications} from '../../store/reducers/notificationsSlice'
import { RESERVATION_COMPLETED, RESERVATION_PENDING, RESERVATION_CANCELLED } from "../../constants/reservation";
import {useListingsHashMapSelector} from '../../store/selectors'
import { INPUT_STYLE } from "../../constants";

const TABLE_COLUMNS = [
  {
    fieldName: "id",
    headName: "Order Id",
    sort: false,
    filter: false,
    className: "w-[100px]",
    filterFormat: "array",
    inputType: "text",
    hideColumn: false,
  },
  {
    fieldName: "checkout_id",
    headName: "Checkout Id",
    filter: false,
    sort: false,
    className: "w-[200px]",
    filterFormat: "string",
    inputType: "text",
    hideColumn: false,
  },
  {
    fieldName: "user_info",
    headName: "User Info",
    filter: false,
    sort: false,
    className: "w-[200px]",
    filterFormat: "string",
    inputType: "text",
    hideColumn: false,
  },
  {
    fieldName: "listing_id",
    headName: "Property",
    filter: false,
    sort: false,
    className: "w-[300px]",
    filterFormat: "string",
    inputType: "text",
    hideColumn: false,
  },
  {
    fieldName: "checkin",
    headName: "Checkin",
    filter: false,
    sort: false,
    className: "w-[200px]",
    filterFormat: "string",
    inputType: "text",
    hideColumn: false,
  },
  {
    fieldName: "checkout",
    headName: "Checkout",
    filter: false,
    sort: false,
    className: "w-[200px]",
    filterFormat: "string",
    inputType: "text",
    hideColumn: false,
  },
  {
    fieldName: "total_guests",
    headName: "Total Guests",
    filter: false,
    sort: false,
    className: "w-[200px]",
    filterFormat: "string",
    inputType: "text",
    hideColumn: false,
  },
  {
    fieldName: "total_amount",
    headName: "Total Amount",
    filter: false,
    sort: false,
    className: "w-[200px]",
    filterFormat: "string",
    inputType: "text",
    hideColumn: false,
  },
  {
    fieldName: "amount_paid",
    headName: "Amount Paid",
    filter: false,
    sort: false,
    className: "w-[200px]",
    filterFormat: "string",
    inputType: "text",
    hideColumn: false,
  },
  {
    fieldName: "balance_amount",
    headName: "Balance Amount",
    filter: false,
    sort: false,
    className: "w-[200px]",
    filterFormat: "string",
    inputType: "text",
    hideColumn: false,
  },
  {
    fieldName: "payment_type",
    headName: "Payment Type",
    filter: false,
    sort: false,
    className: "w-[200px]",
    filterFormat: "string",
    inputType: "text",
    hideColumn: false,
  },
  {
    fieldName: "payment_method",
    headName: "Payment Method",
    filter: false,
    sort: false,
    className: "w-[200px]",
    filterFormat: "string",
    inputType: "text",
    hideColumn: false,
  },
  {
    fieldName: "payment_status",
    headName: "Payment Status",
    filter: false,
    sort: false,
    className: "w-[200px]",
    filterFormat: "string",
    inputType: "text",
    hideColumn: false,
  },
  {
    fieldName: "booking_status",
    headName: "Booking Status",
    filter: false,
    sort: false,
    className: "w-[200px]",
    filterFormat: "string",
    inputType: "text",
    hideColumn: false,
  },
  // {
  //   fieldName: "booked_by",
  //   headName: "Booked By",
  //   filter: false,
  //   sort: false,
  //   className: "w-[200px]",
  //   filterFormat: "string",
  //   inputType: "text",
  //   hideColumn: false,
  // },
  // {
  //   fieldName: "customer_id",
  //   headName: "Customer Id",
  //   filter: false,
  //   sort: false,
  //   className: "w-[200px]",
  //   filterFormat: "string",
  //   inputType: "text",
  //   hideColumn: false,
  // },
  {
    fieldName: "platform_commision",
    headName: "Platform Commision",
    filter: false,
    sort: false,
    className: "w-[200px]",
    filterFormat: "string",
    inputType: "text",
    hideColumn: false,
  },
  {
    fieldName: "host_earning",
    headName: "Host Earning",
    filter: false,
    sort: false,
    className: "w-[200px]",
    filterFormat: "string",
    inputType: "text",
    hideColumn: false,
  },
  {
    fieldName: "platform_earning",
    headName: "Platform Earning",
    filter: false,
    sort: false,
    className: "w-[200px]",
    filterFormat: "string",
    inputType: "text",
    hideColumn: false,
  },
  {
    fieldName: "host_info",
    headName: "Host Info",
    filter: false,
    sort: false,
    className: "w-[200px]",
    filterFormat: "string",
    inputType: "text",
    hideColumn: false,
  },
  {
    fieldName: "is_deleted",
    headName: "Deleted",
    filter: false,
    sort: false,
    className: "w-[150px]",
    filterFormat: "string",
    inputType: "text",
    hideColumn: false,
  },
  {
    fieldName: "created_at",
    headName: "Created At",
    filter: false,
    sort: false,
    className: "w-[200px]",
    filterFormat: "string",
    inputType: "text",
    hideColumn: false,
  },
  {
    fieldName: "updated_at",
    headName: "Updated At",
    filter: false,
    sort: false,
    className: "w-[200px]",
    filterFormat: "string",
    inputType: "text",
    hideColumn: false,
  },
  {
    fieldName: "edit",
    headName: "Edit",
    filter: false,
    className: "w-[100px]",
    filterFormat: "string",
    inputType: "text",
    hideColumn: false,
  },
  {
    fieldName: "delete",
    headName: "Delete",
    filter: false,
    className: "w-[100px]",
    filterFormat: "string",
    inputType: "text",
    hideColumn: false,
  },
];


const Reservations = () => {
  const [rows, setRows] = useState([]);
  const [body, setBody] = useState({
    sort: { col: "id", orderby: "desc" },
    pagination: { page: 1, limit: 20 },
    filters: [],
  })
  const [pageLoad, setPageLoad] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [updatePayload, setUpdatePayload] = useState({booking_status:''});
  const [deleteObj, setDeleteObj] = useState({});
  const [pop, setPop] = useState({
    isDelete: false,
    isEdit: false,
    isCreate: false,
  });


  const dispatch = useDispatch();
  const navigate = useNavigate();

  const listingHashmap = useListingsHashMapSelector();
  
  

  useEffect(() => {
    getData();
  }, [body, pageLoad, listingHashmap]);

  async function getData() {
    try {
      dispatch(loaderStart());
      const res = await getAllReservations(body);
      dispatch(loaderSuccess());
      let response = res.data;
      response = transformRows(response);
      setRows(response);
    }
    catch (e) {
      dispatch(error(e?.message));
    }
  }

    //HANDLE NAVIGATION TO DETAIL PAGE
    function handleNavigate(url="/") {
      navigate(url)
    }



  
    

   //tranforms rows function
   function transformRows( response ) {
    return response?.map((res) => ({
     ...res, 
     created_at: formatDate(res.created_at),
     updated_at: formatDate(res.updated_at),
     checkin: formatDate(res.checkin),
     checkout: formatDate(res.checkout),
     is_deleted: <GetActiveComponent check={res.is_deleted}/>,
     id: <GetIdComponent id={res.id}/>,
     delete: <GetDeletComponent item={res}/>,
     edit: <GetEditComponent item={res}/>,
     checkout_id: <GetCheckoutIdComponent id={res?.checkout_id}/>,
     user_info: <UserInfo res={res} />,
     host_info: <HostInfo res={res?.hostDetails}/>,
     listing_id: <GetListingName listing_id={res?.listing_id}/>
    }))
  }


  //transform delete active component
  function GetActiveComponent({check}) {
    return <>{check ? "true" : "false"}</>;
  }

  //transform id component
  function GetIdComponent({id}) {
    return <div onClick={() => handleNavigate(`/reservations/${id}`)} className="text-blue-500 cursor-pointer">{id}</div>
  }

  function GetCheckoutIdComponent({id}) {
    return <div onClick={() => handleNavigate(`/checkout/${id}`)} className="text-blue-500 cursor-pointer">{id}</div>
  }

  function UserInfo({res}) {
    return (
       <div onClick={() => handleNavigate(`/user/${res?.customer_id}`)}  className="text-blue-500 cursor-pointer">
           <p>{res?.user_fname} {res?.user_lname}</p>
           <p>{res?.user_email}</p>
           <p>{res?.user_ext} {res?.user_mobile}</p>
       </div>
    )
  }

  function HostInfo({res}) {
    return (
       <div onClick={() => handleNavigate(`/user/${res?.id}`)}  className="text-blue-500 cursor-pointer">
           <p>{res?.fname} {res?.lname}</p>
           <p>{res?.email}</p>
           <p>{res?.mobile_ext} {res?.mobile}</p>
       </div>
    )
  }

  function GetListingName({listing_id}) {
    return <div>{listingHashmap[listing_id]?.title ? listingHashmap[listing_id]?.title : listing_id}</div>
  }

  function GetEditComponent({item}) {
    return <EditIcon onClick={() => handleShowEditStatus(item)}/>
  }

  function GetDeletComponent({item}) {
    return <DeleteIcon onClick={() => handleShowDeleteDialog(item)} />
  }

  


  function handleShowDeleteDialog(item) {
    setDeleteObj({OrderId: item?.id, UserName:`${item?.user_fname} ${item?.user_lname}`})
    setPop((prev) => ({...prev, isDelete:true}))
  }

  function handleShowEditStatus(item) {
    setIsOpen((prev) => !prev);
    setUpdatePayload(item)
  }


  const handleUpdateBookingStatus = async () => {
    try {
      dispatch(loaderStart())
      const response = await updateReservations({booking_status:updatePayload.booking_status}, updatePayload.id);
      console.log('response', response)
      dispatch(loaderSuccess());
      setIsOpen((prev) => !prev);
      setPageLoad((prev) => !prev);
      dispatch(showNotifications('Updated Successfully'));
      setUpdatePayload({booking_status:''});
    }
    catch (e) {
      dispatch(error(e?.message))
    }
  };

  const handleDeleteBooking = async () => {
    try {
      dispatch(loaderStart())
      const response = await deleteReservations(deleteObj?.OrderId);
      console.log('response', response)
      dispatch(loaderSuccess());
      setPop((prev) => ({...prev, isDelete:false}))
      setPageLoad((prev) => !prev);
      dispatch(showNotifications('Deleted Successfully'));
      setDeleteObj({});
    }
    catch (e) {
      dispatch(error(e?.message))
    }
  };





  return (
    <>
      <PageHeader title="Reservations" showCreate={false} className="p-3" />
      {rows && (
        <Table
        setQueryBody={setBody}
        queryBody={body}
          rows={rows}
          columns={TABLE_COLUMNS}
          filter={false}
          pagiNationFilter={true}
        />
      )}

      <Dialog showCreateClick={false} isOpen={isOpen} closeModal={() => setIsOpen((prev) => !prev)}  title="Update Order Status"
          childrenClass={
            "w-[30%] h-[45%] p-6 rounded-md no-scrollbar dark-scrollbar"
          }>
            <div>
                <div className="flex items-center justify-between">
                  <p className="text-slate-600">Order Id</p>
                  <p className="text-slate-600">{updatePayload?.id}</p>
                </div>
                <div className="flex items-center justify-between">
                  <p className="text-slate-600">Customer Name</p>
                  <p className="text-slate-600">{updatePayload?.user_fname} {updatePayload?.user_lname}</p>
                </div>
                <div className="mt-1">
                  <label>Booking Status</label>
                  <select className={`${INPUT_STYLE}`} onChange={(e) => setUpdatePayload((prev) => ({...prev, booking_status:e.target.value}))} value={updatePayload?.booking_status}>
                     <option value="">Choose an option</option>
                     <option value={RESERVATION_COMPLETED}>Completed</option>
                     <option value={RESERVATION_PENDING}>Pending</option>
                     <option value={RESERVATION_CANCELLED}>Cancelled</option>
                  </select>
                </div>
            </div>

            <div className="mt-10 flex items-center justify-between">
               <button className="border p-2 rounded-md border-black hover:text-white hover:bg-black" onClick={() => setIsOpen((prev) => !prev)}>Close</button>
               <button className="p-2 rounded-md bg-black text-white" onClick={handleUpdateBookingStatus}>Update</button>
            </div>
      </Dialog>

      {pop.isDelete  && (
        <DeletePopup
          title={"Reservation"}
          setIsOpen={setPop}
          deletedObj={deleteObj}
          onDeleteList={handleDeleteBooking}
        />
      )}
    </>
  );
};

export default Reservations;
