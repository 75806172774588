import api from "../api";
import endPoints from "./endPoints";

export async function getAllRegions() {
  try {
    const response = await api.get(endPoints.getAllRegions())
    return response
  }
  catch ( e ) {
    throw new Error( e )
  }
}

export async function postRegion(body) {
  try {
    const response = await api.post(endPoints.createRegion(),body)
    return response
  }
  catch ( e ) {
    throw new Error( e )
  }
}


export async function putRegion(formData, id) {
  try {
    const response = await api.put(endPoints.updateRegion(id), formData)
    return response
  }
  catch ( e ) {
    throw new Error( e )
  }
}


export async function deleteRegion(id) {
  try {
    const response = await api.delete(endPoints.deleteRegion(id))
    return response
  }
  catch ( e ) {
    throw new Error( e )
  }
}


//
export  const getALLRegionsData = async () => {
  try {
    const response = await api.get(endPoints.getAllRegions())
    return response.data.data
  }
  catch (e) {
     throw new Error(e)
  }
}