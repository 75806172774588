import React,{ useState} from 'react'
import Button from './button'
import BasicPropertyDetails from './basicPropertyDetails'
import Step2 from './step2'
import Categories from './categories'
import Amenities from './amenities'
import HouseRules from './houseRules'
import Photos from './photos'
import BasicPricing from './basicPricing'
import ExtraGuests from './extraGuests'
import ReservationLength from './reservationLength'
import Offers from './offers'
import Addons from './addons'
import LongTermDiscounts from './longTermDiscounts'
import ExtraServices from './extraServices'
import { addIcal, createListing, updateListing } from '../../services/listingsServices'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { loaderStart, loaderSuccess, error } from '../../store/reducers/loaderSlice'
import RoomArrangement from './roomArrangement/roomArrangeMent'
import NearBy from './nearBy/nearBy'
import ShortDescription from './shortDescription/shortDescription'
import Ical from './ical'
import { showNotifications } from '../../store/reducers/notificationsSlice'

function CreateListing({setPageLoad, isDefault=true, payload, setPayload,}) {
  const [stepper, setStepper] = useState( 0 );
  const [updateIcal, setUpdateIcal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const tabs = [ 
    {value: 0, component:<BasicPropertyDetails payload={payload} setPayload={setPayload}/>},
    {value:1, component: <Step2 payload={payload} setPayload={setPayload} />},
    {value:2, component: <Categories payload={payload} setPayload={setPayload}/>},
    {value:3, component: <Amenities payload={payload} setPayload={setPayload}/> },
    {value:4, component: <HouseRules payload={payload} setPayload={setPayload}/> },
    {value:5, component: <Photos payload={payload} setPayload={setPayload}/> },
    {value:6, component: <BasicPricing payload={payload} setPayload={setPayload}/>},
    {value:7, component: <ExtraGuests payload={payload} setPayload={setPayload}/>},
    {value:8, component: <ReservationLength payload={payload} setPayload={setPayload}/>},
    {value:9, component: <Offers payload={payload} setPayload={setPayload}/>},
    {value:10, component: <Addons payload={payload} setPayload={setPayload}/> },
    {value:11, component: <LongTermDiscounts payload={payload} setPayload={setPayload}/>},
    {value:12, component: <ExtraServices payload={payload} setPayload={setPayload}/>},
    { value: 13, component: <RoomArrangement payload={payload} setPayload={setPayload} /> },
    { value: 14, component: <NearBy payload={payload} setPayload={setPayload} /> },
    {value: 15, component: <ShortDescription payload={payload} setPayload={setPayload} /> },
  ]

  if ( params?.id !== 'create' ) {
    tabs.push({value: 16, component: <Ical setUpdateIcal={setUpdateIcal} payload={payload} setPayload={setPayload} />})
  }

  const handleNext = () => {
    if ( stepper === tabs.length -1) {
        //publish
        handlePublish()
    }
    else {
      //next
        setStepper((prev) => prev + 1)
    }
  }

  const handlePublish = async () => {
    try {
      if ( isDefault ) {
        dispatch(loaderStart())
        const create = await createListing( payload )
        dispatch(loaderSuccess())
        dispatch(showNotifications('Created Successfully'))
        navigate('/listings')
      }
      else {
        if ( params?.id ) {
          dispatch(loaderStart())
          const update = await updateListing(params?.id, payload)
          if (updateIcal) {
            const response = await addIcal({listing_id:params?.id})
            setUpdateIcal(false)
          }
          dispatch(loaderSuccess())
          setPageLoad((prev) => !prev)
          dispatch(showNotifications('Updated Successfully'))
        }
      }
    }
    catch ( err ) {
       console.log('error in apis')
    }
  }

  const handleBack = () => {
    if ( stepper > 0 ) {
      setStepper((prev) => prev - 1)
    }
    return
  }

  const handleUpdate = async () => {
    try {
      dispatch(loaderStart())
      const response = await updateListing(params?.id, payload)
      dispatch(loaderSuccess())
      setPageLoad((prev) => !prev)
      dispatch(showNotifications('Updated Successfully'))
    }
    catch (e) {
      console.log('e', e);
    }
  }


  return (
    <div className='relative'>
        <div className={``}>
          {(params?.id !== 'create' && tabs?.length - 1 !== stepper ) && <Button onClick={handleUpdate} className='absolute right-0 top-1'>Update</Button>}
        </div>

       <div className={`${(params?.id !== 'create' && tabs?.length - 1 !== stepper)  && 'mt-8'}`}>
        {tabs.map((tab, idx) => {
            return (
            <div key={idx} className={`${tab.value === stepper ? 'block' : 'hidden'}`}>
               {tab.component}
            </div>
            )
        })}
       </div>
        
        <div  className='flex items-center justify-between  my-10 '>
            <Button onClick={handleBack}>Back</Button>
            <Button onClick={handleNext}>{tabs.length -1 === stepper ? (params?.id !== 'create' ? 'Update' : 'Publish'): 'Next'}</Button>
        </div>
    </div>
  )
}

export default CreateListing