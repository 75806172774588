import api from '../api'
import endPoints from './endPoints';

export async function getFacilities() {
  try  {
    const response = await api.get(endPoints.getAllFacilities());
    return response
  }
  catch (e) {
    throw new Error(e?.message)
  }
}

export async function postFacilities( body ) {
  try  {
    const response = await api.post(endPoints.getAllFacilities(), body);
    return response
  }
  catch (e) {
    throw new Error(e?.message)
  }
}

export async function editFacilities(body) {
  try  {
    const response = await api.put(endPoints.updateFacilities(), body);
    return response
  }
  catch (e) {
    throw new Error(e?.message)
  }
}

export async function deleteFacilities(id) {
  try  {
    const response = await api.delete(endPoints.deleteFacilities(id));
    return response
  }
  catch (e) {
    throw new Error(e?.message)
  }
}

