import api from '../api'
import endPoints from './endPoints';

export async function getOffers() {
  try {
    const response = await api.get(endPoints.getAllOffers())
    return response
  }
  catch ( e ) {
    throw new Error( e )
  }
}

export async function postOffers(body) {
  try {
    const response = await api.post(endPoints.createOffer(), body)
    return response
  }
  catch ( e ) {
    throw new Error( e )
  }
}

export async function editOffers(body) {
  try {
    const response = await api.put(endPoints.updateOffer(), body)
    return response
  }
  catch ( e ) {
    throw new Error( e )
  }
}

export async function deleteOffers(id) {
  try {
    const response = await api.delete(endPoints.deleteOffer(id))
    return response
  }
  catch ( e ) {
    throw new Error( e )
  }
}



