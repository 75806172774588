import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { getAllOtherPlatformBookings, createOtherPlatformBooking, deleteOtherPlatformBooking } from '../../../services/otherPlatforms/booking';
import PageHeader from '../../../components/pageHeader';
import Table from '../../../components/hkTable';
import CommonDeletePopup from '../../../components/popup/commonDeletePopup';
import { loaderStart, loaderSuccess, error } from '../../../store/reducers/loaderSlice';
import { DeleteIcon } from '../../../icons';
import Dialog from '../../../ui/dialog';
import ChannelBooking from '../../../components/forms/otherPlatform/booking';
import { getAllListings } from '../../../services/listingsServices';
import { getAllOtherPlatformUsers } from '../../../services/otherPlatforms/users';
import { useNavigate } from 'react-router-dom';

const TABLE_COLUMNS = [
    {
      fieldName: "id",
      headName: "ID",
      sort: false,
      filter: false,
      className: "w-[100px]",
      filterFormat: "array",
      inputType: "text",
      hideColumn: false,
    },
    {
        fieldName: "booking_number",
        headName: "Booking Number",
        sort: false,
        filter: false,
        className: "w-[100px]",
        filterFormat: "array",
        inputType: "text",
        hideColumn: false,
    },
    {
      fieldName: "userinfo",
      headName: "User info",
      sort: false,
      filter: false,
      className: "w-[100px]",
      filterFormat: "array",
      inputType: "text",
      hideColumn: false,
    },
    {
      fieldName: "checkin",
      headName: "Check-in",
      sort: false,
      filter: false,
      className: "w-[100px]",
      filterFormat: "array",
      inputType: "text",
      hideColumn: false,
    },
    {
      fieldName: "checkout",
      headName: "Check-out",
      sort: false,
      filter: false,
      className: "w-[100px]",
      filterFormat: "array",
      inputType: "text",
      hideColumn: false,
    },
    {
      fieldName: "booking_channel",
      headName: "Booking Channel",
      sort: false,
      filter: false,
      className: "w-[100px]",
      filterFormat: "array",
      inputType: "text",
      hideColumn: false,
    },
    {
      fieldName: "booking_status",
      headName: "Booking Status",
      sort: false,
      filter: false,
      className: "w-[100px]",
      filterFormat: "array",
      inputType: "text",
      hideColumn: false,
    },
    {
      fieldName: "total_amount",
      headName: "Total Amount",
      filter: false,
      sort: false,
      className: "w-[200px]",
      filterFormat: "string",
      inputType: "text",
      hideColumn: false,
    },
    {
      fieldName: "deduction_amount",
      headName: "Deduction Amount",
      filter: false,
      sort: false,
      className: "w-[200px]",
      filterFormat: "string",
      inputType: "text",
      hideColumn: false,
    },
    {
        fieldName: "booking_date",
        headName: "Booking Date",
        filter: false,
        sort: false,
        className: "w-[200px]",
        filterFormat: "string",
        inputType: "text",
        hideColumn: false,
    },
    {
        fieldName: "property_id",
        headName: "Property",
        filter: false,
        sort: false,
        className: "w-[200px]",
        filterFormat: "string",
        inputType: "text",
        hideColumn: false,
    },
    {
        fieldName: "created_at",
        headName: "Created At",
        filter: false,
        sort: false,
        className: "w-[200px]",
        filterFormat: "string",
        inputType: "text",
        hideColumn: false,
    },
    {
        fieldName: "updated_at",
        headName: "Updated At",
        filter: false,
        sort: false,
        className: "w-[200px]",
        filterFormat: "string",
        inputType: "text",
        hideColumn: false,
    },
    {
      fieldName: "delete",
      headName: "Delete",
      filter: false,
      className: "w-[100px]",
      filterFormat: "string",
      inputType: "text",
      hideColumn: false,
    },
];


function OtherPlatformBookings() {
  const [rows, setRows] = useState([]);
  const [pageLoad, setPageLoad] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [deleteObj, setDeleteObj] = useState({});
  const [request, setRequest] = useState(false);
  const [users, setUsers] = useState([])
  let [publishedListings, setPublishedListings] = useState([]);
   
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    getData()
  }, [pageLoad])


  useEffect(() => {
    getUsersData();
  }, [request]);

  useEffect(() => {
    // if (listings.length > 0) {
    //   let publishedData = getPublishedProperties(listings);
      
    //   setPublishedListings(publishedData);
    // }
    getListing()
   
  }, []);


  async function getListing() {
    try {
       const response = await getAllListings()
       let data = response?.data?.data 
       setPublishedListings(data);
    }
    catch (e) {

    }
  }

  function getUsersData() {
    getAllOtherPlatformUsers().then((res) => {
      
      setUsers(res);
    });
  }



  async function getData() {
    try {
        dispatch(loaderStart())
        const response = await getAllOtherPlatformBookings();
        let result = transformRows(response)
        setRows(result)
        dispatch(loaderSuccess())
    }
    catch (e) {
        dispatch(error(e?.message))
    }
  }


  function transformRows(response) {
    return response?.map(res => ({
      ...res,
    //   edit: getEditComponent(res),
      id : <GetIdComponent id={res?.id}/>,
      delete: getDeleteComponent(res),
    }))
  }


  function GetIdComponent({id}) {
    
    const handleNavigate = (ID) => {
      navigate(`/other-platforms/bookings/${ID}`)
    }

    return (
      <div onClick={() => handleNavigate(id)} className='text-blue-500 cursor-pointer'>{id}</div>
    )
  }
  

  function getDeleteComponent(item) {
    return (
      <DeleteIcon onClick={() => handleOpenDeleteDialog(item)}/>
    );
  }
  


 function handleOpenDeleteDialog(item) {
    const {booking_number, booking_status, total_amount, fname, lname, id } = item
    setDeleteObj({booking_number, booking_status, total_amount, fname, lname, id});
    setIsOpenDeleteDialog((prev) => !prev)
 }


  async function handleDelete() {
    try {
       dispatch(loaderStart())
       await deleteOtherPlatformBooking(deleteObj?.id)
       dispatch(loaderSuccess())
       setPageLoad((prev) => !prev)
       setIsOpenDeleteDialog((prev) => !prev)
    }
    catch( e ) {
        dispatch(error(e?.message))
    }
  }


  
  return (
    <div>
       <PageHeader title="Otherplatform Users" onClick={() => setIsOpen((prev) => !prev)} />
       {rows && (
          <Table
            rows={rows}
            columns={TABLE_COLUMNS}
            filter={false}
            pagiNationFilter={false}
          />
        )}


        <Dialog createClick  isOpen={isOpen} closeModal={() => setIsOpen((prev) => !prev)} childrenClass={"w-[50%]  rounded-md  dark-scrollbar overflow-y-scroll h-[600px] max-w-3xl "}>
          <ChannelBooking  
          setPageLoad={setPageLoad}
          request={request}
          listings={publishedListings}
          setRequest={setRequest}
          users={users}
          closeModal={() => setIsOpen((prev) => !prev)} />
        </Dialog>

        {isOpenDeleteDialog && (
          <CommonDeletePopup
            title={'Booking'}
            deleteObj={deleteObj}
            handleDeleteData={handleDelete}
            setPageLoad={setPageLoad}
            onClose={() => setIsOpenDeleteDialog((prev) => !prev)}
          />
        )
        }
    </div>
  )
}

export default OtherPlatformBookings