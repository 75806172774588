import api from '../api'
import endPoints from './endPoints';

export async function getAffiliateEarnings( body ) {
  try {
    const response = await api.post(endPoints.getAllAffiliateEarning(), body );
    return response 
  }
  catch (e) {
    throw new Error(e?.response?.data?.error?.message)
  }
}

export async function postAffiliateEarnings( body ) {
  try {
    const response = await api.post(endPoints.createAffiliateEarning(), body );
    return response 
  }
  catch (e) {
    throw new Error(e?.response?.data?.error?.message)
  }
}

export async function editAffiliateEarnings(id, body) {
  try {
    const response = await api.put(endPoints.updateAffiliateEarning(id), body );
    return response 
  }
  catch (e) {
    throw new Error(e?.response?.data?.error?.message)
  }
}



export async function deleteAffiliateEarnings(id) {
  try {
    const response = await api.delete(endPoints.deleteAffiliateEarning(id));
    return response 
  }
  catch (e) {
    throw new Error(e?.response?.data?.error?.message)
  }
}





