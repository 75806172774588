import api from '../../api'
import endPoints from '../endPoints'

 
export const getAllOtherPlatformBookings = async () => {
    try {
      const response = await api.post(endPoints.getAllOtherPlatformBookings());
      return response.data
    }
    catch (e) {
        throw new Error(e?.response?.data?.error?.message)
    }
}

export const getAllOtherPlatformBookingsById = async ( id ) => {
    try {
      const response = await api.get(endPoints.getAllOtherPlatformBookingsById(id));
      return response.data
    }
    catch (e) {
        throw new Error(e?.response?.data?.error?.message)
    }
}

export const createOtherPlatformBooking = async ( body  ) => {
    try {
      const response = await api.post(endPoints.createOtherPlatformBooking(), body);
      return response.data
    }
    catch (e) {
        throw new Error(e?.response?.data?.error?.message)
    }
}

export const updateOtherPlatformBooking = async ( id , body ) => {
    try {
      const response = await api.put(endPoints.updateOtherPlatformBooking(id), body);
      return response.data
    }
    catch (e) {
        throw new Error(e?.response?.data?.error?.message)
    }
}

export const deleteOtherPlatformBooking = async ( id  ) => {
    try {
      const response = await api.delete(endPoints.deleteOtherPlatformBooking(id));
      return response.data
    }
    catch (e) {
        throw new Error(e?.response?.data?.error?.message)
    }
}
