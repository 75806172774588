import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import {getAllOtherPlatformUsers, createOtherPlatformUser, updateOtherPlatformUser, deleteOtherPlatformUser} from '../../services/otherPlatforms/users'
import { error, loaderStart, loaderSuccess } from '../../store/reducers/loaderSlice';
import { DeleteIcon, EditIcon } from '../../icons';
import PageHeader from '../../components/pageHeader';
import Table from '../../components/hkTable';
import CommonDeletePopup from '../../components/popup/commonDeletePopup';
import Dialog from '../../ui/dialog';
import UserForm from '../../components/forms/otherPlatform/user';
import { useNavigate } from 'react-router-dom';

const TABLE_COLUMNS = [
    {
      fieldName: "id",
      headName: "ID",
      sort: false,
      filter: false,
      className: "w-[100px]",
      filterFormat: "array",
      inputType: "text",
      hideColumn: false,
    },
    {
      fieldName: "fname",
      headName: "First Name",
      sort: false,
      filter: false,
      className: "w-[100px]",
      filterFormat: "array",
      inputType: "text",
      hideColumn: false,
    },
    {
      fieldName: "lname",
      headName: "Last Name",
      sort: false,
      filter: false,
      className: "w-[100px]",
      filterFormat: "array",
      inputType: "text",
      hideColumn: false,
    },
    {
      fieldName: "email_id",
      headName: "Email Id",
      sort: false,
      filter: false,
      className: "w-[100px]",
      filterFormat: "array",
      inputType: "text",
      hideColumn: false,
    },
    {
      fieldName: "mobile",
      headName: "Mobile",
      sort: false,
      filter: false,
      className: "w-[100px]",
      filterFormat: "array",
      inputType: "text",
      hideColumn: false,
    },
    {
      fieldName: "user_channel",
      headName: "User Channel",
      sort: false,
      filter: false,
      className: "w-[100px]",
      filterFormat: "array",
      inputType: "text",
      hideColumn: false,
    },
    {
      fieldName: "created_at",
      headName: "Created At",
      filter: false,
      sort: false,
      className: "w-[200px]",
      filterFormat: "string",
      inputType: "text",
      hideColumn: false,
    },
    {
      fieldName: "updated_at",
      headName: "Updated At",
      filter: false,
      sort: false,
      className: "w-[200px]",
      filterFormat: "string",
      inputType: "text",
      hideColumn: false,
    },
    {
      fieldName: "edit",
      headName: "Edit",
      filter: false,
      className: "w-[100px]",
      filterFormat: "string",
      inputType: "text",
      hideColumn: false,
    },
    {
      fieldName: "delete",
      headName: "Delete",
      filter: false,
      className: "w-[100px]",
      filterFormat: "string",
      inputType: "text",
      hideColumn: false,
    },
];

function OtherPlatformsUsers() {
  const [rows, setRows] = useState([]);
  const [pageLoad, setPageLoad] = useState(false);
  const [payload, setPayload] = useState({fname:'', lname:'', email_id:'', mobile:'', user_channel:''});
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false)
  const [deleteObj, setDeleteObj] = useState({})
  const [actionType, setActionType] = useState('CREATE')

  const dispatch = useDispatch();
  const navigate = useNavigate()

  useEffect(() => {
     getData()
  }, [pageLoad])

  async function getData() {
    try {
      dispatch(loaderStart())
      const response = await getAllOtherPlatformUsers();
      let result = transformRows(response)
      setRows(result)
      dispatch(loaderSuccess())
    }
    catch (e) {
      dispatch(error(e?.message))
    }
  }

  function transformRows(response) {
    return response?.map(res => ({
      ...res,
      edit: getEditComponent(res),
      delete: getDeleteComponent(res),
      id : <GetIdComponent id={res.id}/>
    }))
 }

 function GetIdComponent({id}) {
   const handleNavigate = ( userId ) => {
    navigate(`/other-platforms/users/${userId}`)
   }
   return (
     <div onClick={() => handleNavigate(id)} className='text-blue-500 cursor-pointer'>{id}</div>
   )
 }

  function getEditComponent(item) {
    return (
       <EditIcon onClick={() => handleOpenEditDialog(item)} />
    );
  }

  function getDeleteComponent(item) {
    return (
      <DeleteIcon onClick={() => handleOpenDeleteDialog(item)}/>
    );
  }

  const handleOpenCreateDialog = () => {
    setPayload({fname:'', lname:'', email_id:'', mobile:'', user_channel:''})
    setActionType('CREATE')
    setIsOpen((prev) => !prev)
  }

  const handleOpenEditDialog = ( item ) => {
    const {fname, lname, email_id, mobile, id, user_channel} = item
    setPayload({fname, lname, email_id, mobile,user_channel, id});
    setIsOpen((prev) => !prev)
    setActionType('UPDATE')
  }

  function handleOpenDeleteDialog( item ) {
    const {fname, lname, email_id, id} = item 
    setDeleteObj({id, fname, lname, email_id});
    setIsOpenDeleteDialog((prev) => !prev);
  }

  const handleCloseDialog = () => {
    setIsOpen((prev) => !prev)
    setActionType('CREATE')
  }

  const handleAction = async () => {
    if ( actionType === 'CREATE') {
      await handleCreate()
    }
    else {
      await handleEdit()
    }
  }


  async function handleEdit() {
    try {
        let body = {fname:payload?.fname, lname:payload?.lname, email_id:payload?.email_id, mobile:payload?.mobile, user_channel:payload?.user_channel}
        dispatch(loaderStart())
        const response = await updateOtherPlatformUser( payload?.id, body );
        dispatch(loaderSuccess())
        setIsOpen((prev) => !prev)
        setPageLoad((prev) => !prev)
    } 
    catch (e) {
      dispatch(error(e?.message))
    }
  }


  async function handleCreate() {
    try {
      dispatch(loaderStart())
      const response = await createOtherPlatformUser( payload )
      dispatch(loaderSuccess())
      setIsOpen((prev) => !prev)
      setPageLoad((prev) => !prev)
    }
    catch (e) {
      console.log('error create', e)
      dispatch(error(e?.message))
    }
  }


  async function handleDelete() {
    try {
      dispatch(loaderStart())
      const response = await deleteOtherPlatformUser( deleteObj?.id );
      dispatch(loaderSuccess())
      setPageLoad((prev) => !prev)
      setIsOpenDeleteDialog((prev) => !prev)
    }
    catch (e) {
      dispatch(error(e?.message))
    }
  }


  return (
    <div>
       <PageHeader title="Otherplatform Users" onClick={handleOpenCreateDialog} />
       {rows && (
          <Table
            rows={rows}
            columns={TABLE_COLUMNS}
            filter={false}
            pagiNationFilter={false}
          />
        )}

      <Dialog createClick={actionType === 'CREATE'} isOpen={isOpen} closeModal={handleCloseDialog} childrenClass={"w-[50%] p-6 rounded-md no-scrollbar dark-scrollbar"}>
          <UserForm actionType={actionType} payload={payload} setPayload={setPayload} handleAction={handleAction} handleCloseDialog={handleCloseDialog} />
      </Dialog>

        {isOpenDeleteDialog && (
          <CommonDeletePopup
            title={'User'}
            deleteObj={deleteObj}
            handleDeleteData={handleDelete}
            setPageLoad={setPageLoad}
            onClose={() => setIsOpenDeleteDialog((prev) => !prev)}
          />
        )
      }
    </div>
  )
}

export default OtherPlatformsUsers