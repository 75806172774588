import React, {useEffect, useState} from 'react'
import { getAllOtherPlatformBookingsById, updateOtherPlatformBooking } from '../../../services/otherPlatforms/booking'
import { loaderStart, loaderSuccess, error } from '../../../store/reducers/loaderSlice'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { BOOKING_TYPE_ARR, BOOKING_STATUS_ARR,
  PAYMENT_METHOD_ARR,
  PAYMENT_STATUS_ARR,
  IS_PART_PAYMENT_ARR,
  BOOKING_CHANNEL_ARR, } from '../../../constants/otherPlatforms'
import Status from '../../../ui/status'
import Button from '../../../ui/button'
import { formatDate } from '../../../utils/common'
import MyComboBox from '../../../components/myComboBox'
import { getAllListings } from '../../../services/listingsServices'

const INPUT_CLASS =
  "w-1/2 placeholder:italic placeholder:text-slate-500 block bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm shadow-primary text-primary focus:outline-none focus:border-red-300 focus:ring-red-300 focus:ring-1 sm:text-sm";

function BookingDetail() {
  const [bookingDetails, setBookingDetails] = useState();
  const [bookingData, setBookingData] = useState();
  let [newBookingData, setNewBookingData] = useState({});
  const [isEditable, setisEditable] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [request, setRequest] = useState(false);
  const [listings, setListing] = useState([])
  const [propertyId, setPropertyId] = useState();
  let params = useParams();
  let dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, [request]);

 


 useEffect(() => {
  getListing();
  }, []);

  async function getListing() {
    try {
       const response = await getAllListings()
      
       let data = response?.data?.data 
       setListing(data);
    }
    catch (e) {

    }
  }

  function getData() {
    
    dispatch(loaderStart());
    getAllOtherPlatformBookingsById(params.id)
      .then((res) => {
        dispatch(loaderSuccess());
        setBookingDetails(res);
        setBookingData(res);
        setPropertyId(res.property_id);
      })
      .catch((err) => {
        dispatch(error(err?.message));
        console.log("you have a error in api", err);
      });
  }

 

  function getStatusComponent(status) {
    let statusVal = "",
      statusType = "";
    if (status === 0) {
      statusVal = "Pending";
      statusType = "warning";
    } else if (status === 3) {
      statusVal = "Processing";
      statusType = "primary";
    } else if (status === 5) {
      statusVal = "Completed";
      statusType = "success";
    } else if (status === 7) {
      statusVal = "Refunded";
      statusType = "secondary";
    } else if (status === 10) {
      statusVal = "Cancelled";
      statusType = "danger";
    } else if (status === 11) {
      statusVal = "Failed";
      statusType = "danger";
    } else if (status === 13) {
      statusVal = "Abandoned";
      statusType = "primary";
    } else {
      statusVal = status;
    }
    return (
      <Status value={statusVal} size="sm" type={statusType} className="w-1/2" />
    );
  }

  function getPaymentMethod(value) {
    if (value === 1) {
      return "Cash";
    } else if (value === 2) {
      return "Credit-Card";
    } else if (value === 3) {
      return "PAYPAL";
    } else {
      return value;
    }
  }

  function checkIfPartPayment(value) {
    if (value === 1) {
      return "Full";
    } else if (value === 2) {
      return "Part";
    } else {
      return value;
    }
  }

  function getBookingType(type) {
    if (type === 1) {
      return "Online";
    } else if (type === 2) {
      return "Offline";
    } else {
      return type;
    }
  }

  function handleBookingDetailsEditUpdate(type) {
    // console.log("Inside booking upddate: ---- 1", newBookingData);
    if (type === "update") {
      updateBookingData()
      // console.log("----upddate----");
      
      // console.log("inside type update: ---- 5", newBookingData);
      // handleUpdateModal();
      
    } else if (type === "cancel") {
      // console.log("----cancel----");
      if (Object.keys(newBookingData).length > 0) {
        setRequest((prev) => !prev);
        setNewBookingData({});
        setisEditable((prev) => !prev);
      }
    } else {
      // console.log("----eddit----");
      setisEditable((prev) => !prev);
    }
   
  }

  // function handleUpdateModal() {
  //   setShowUpdateModal((prev) => !prev);
  // }

  function updateBookingData() {
    // console.log("Inside update booking: ----2", newBookingData);
    let clone
    if (Object.keys(newBookingData).length > 0) {
      if ("listing_id" in newBookingData) {
        // console.log("inside type update: ---- 4", newBookingData);
        // let { property_id: property_id, ...rest } = newBookingData;
        // newBookingData = { property_id, ...rest };
        clone = {...newBookingData, property_id: newBookingData?.listing_id}
        delete clone['listing_id']
        // console.log('clone', clone)
        // setNewBookingData(clone)
      }
      else {
        clone = {...newBookingData}
      }
      console.log('newBookingData', clone)
      dispatch(loaderStart());
      updateOtherPlatformBooking(bookingDetails.id, clone)
        .then((res) => {
          dispatch(loaderSuccess());
          setRequest((prev) => !prev);
        })
        .catch((err) => {
          console.log("Error iin updating booking detailas:- ", err);
          // dispatch(actionAddErrorMessage(err.message));
          dispatch(error(err?.message));
        });
    }
    // handleUpdateModal();
    setNewBookingData({});
    setisEditable((prev) => !prev);

    // getData();
  }

  function handleInput(e, type) {
    
    let val = e.target.value;
    switch (type) {
      case "checkin": {
        setBookingData({
          ...bookingData,
          checkin: val,
        });
        setNewBookingData({
          ...newBookingData,
          checkin: val,
        });
        break;
      }
      case "checkout": {
        setBookingData({
          ...bookingData,
          checkout: val,
        });
        setNewBookingData({
          ...newBookingData,
          checkout: val,
        });
        break;
      }
      case "booking_number": {
        setBookingData({
          ...bookingData,
          booking_number: val,
        });
        setNewBookingData({
          ...newBookingData,
          booking_number: val,
        });
        break;
      }
      case "booking_date": {
        setBookingData({
          ...bookingData,
          booking_date: val,
        });
        setNewBookingData({
          ...newBookingData,
          booking_date: val,
        });
        break;
      }
      case "booking_status": {
        val = Number.parseInt(val);
        setBookingData({
          ...bookingData,
          booking_status: e.target.value,
        });
        setNewBookingData({
          ...newBookingData,
          booking_status: val,
        });
        break;
      }
      case "booking_type": {
        val = Number.parseInt(val);
        setBookingData({
          ...bookingData,
          booking_type: e.target.value,
        });
        setNewBookingData({
          ...newBookingData,
          booking_type: val,
        });
        break;
      }

      case "channel_name": {
        
        setBookingData({
          ...bookingData,
          booking_channel: e.target.value,
        });
        setNewBookingData({
          ...newBookingData,
          booking_channel: val,
        });
        break;
      }
      case "total_amount": {
        val = Number.parseInt(val);
        setBookingData({
          ...bookingData,
          total_amount: val,
        });
        setNewBookingData({
          ...newBookingData,
          total_amount: val,
        });
        break;
      }
      case "promo_code": {
        setBookingData({
          ...bookingData,
          promo_code: val,
        });
        setNewBookingData({
          ...newBookingData,
          promo_code: val,
        });
        break;
      }
      case "discount_amount": {
        val = Number.parseInt(val);
        setBookingData({
          ...bookingData,
          discount_amount: e.target.value,
        });
        setNewBookingData({
          ...newBookingData,
          discount_amount: val,
        });
        break;
      }
      case "grand_total": {
        val = Number.parseInt(val);
        setBookingData({
          ...bookingData,
          grand_total: e.target.value,
        });
        setNewBookingData({
          ...newBookingData,
          grand_total: val,
        });
        break;
      }
      case "amount_paid": {
        val = Number.parseInt(val);
        setBookingData({
          ...bookingData,
          amount_paid: e.target.value,
        });
        setNewBookingData({
          ...newBookingData,
          amount_paid: val,
        });
        break;
      }

      case "due_amount": {
        val = Number.parseInt(val);
        setBookingData({
          ...bookingData,
          due_amount: e.target.value,
        });
        setNewBookingData({
          ...newBookingData,
          due_amount: val,
        });
        break;
      }
      case "deduction_reason": {
        // val = Number.parseInt(val);
        setBookingData({
          ...bookingData,
          deduction_reason: e.target.value,
        });
        setNewBookingData({
          ...newBookingData,
          deduction_reason: val,
        });
        break;
      }
      case "deduction_amount": {
        val = Number.parseInt(val);
        setBookingData({
          ...bookingData,
          deduction_amount: e.target.value,
        });
        setNewBookingData({
          ...newBookingData,
          deduction_amount: val,
        });
        break;
      }
      case "payment_method": {
        val = Number.parseFloat(val);
        setBookingData({
          ...bookingData,
          payment_method: e.target.value,
        });
        setNewBookingData({
          ...newBookingData,
          payment_method: val,
        });
        break;
      }
      case "payment_status": {
        val = Number.parseInt(val);
        setBookingData({
          ...bookingData,
          payment_status: e.target.value,
        });
        setNewBookingData({
          ...newBookingData,
          payment_status: val,
        });
        break;
      }
      case "is_partpayment": {
        val = Number.parseInt(val);
        setBookingData({
          ...bookingData,
          is_partpayment: e.target.value,
        });
        setNewBookingData({
          ...newBookingData,
          is_partpayment: val,
        });
        break;
      }
      case "total_adults": {
        val = Number.parseInt(val);
        setBookingData({
          ...bookingData,
          total_adults: e.target.value,
        });
        setNewBookingData({
          ...newBookingData,
          total_adults: val,
        });
        break;
      }
      case "total_childrens": {
        val = Number.parseInt(val);
        setBookingData({
          ...bookingData,
          total_childrens: e.target.value,
        });
        setNewBookingData({
          ...newBookingData,
          total_childrens: val,
        });
        break;
      }
      case "total_infants": {
        val = Number.parseInt(val);
        setBookingData({
          ...bookingData,
          total_infants: e.target.value,
        });
        setNewBookingData({
          ...newBookingData,
          total_infants: val,
        });
        break;
      }
      default:
        return;
    }
  }

  function handleCustomerIdClick(id) {
    console.log("please navigate to the user detaills page: ", id);
    // navigate(`../channels/users/${id}`);
  }

  function handlePropertyIdClick(id) {
    // navigate(`../channels/listings/${id}`);
  }

  function getProperty(propertyId) {
    let property;
    property = listings.find((listing) => listing.listing_id == propertyId);
  
    if (property) {
      return <p>{property && property["title"]}</p>;
    } else {
      return "";
    }
  }

  // console.log('v',propertyId)

  return (
    <div className="min-h-screen">
      {/* {showUpdateModal && (
        <ConfirmationModal
          isOpen={showUpdateModal}
          closeModal={handleUpdateModal}
          onUpdate={updateBookingData}
          title="Update User Booking details"
        />
      )} */}
      {bookingDetails ? (
        <div className="pb-36 flex flex-col items-center mt-10">
          <div className="flex justify-between w-[90%]">
            <h1 className="text-red-500 text-center font-medium  mt-5 text-lg">
              User Booking Details
            </h1>
            {isEditable ? (
              <div>
                <Button
                  value="Cancel"
                  type="cancel"
                  size="sm"
                  className="my-3 mr-4"
                  onClick={() => handleBookingDetailsEditUpdate("cancel")}
                />
                <Button
                  value="Update"
                  type="success"
                  size="sm"
                  className="my-3 "
                  onClick={() => handleBookingDetailsEditUpdate("update")}
                />
              </div>
            ) : (
              <Button
                value="Edit Booking"
                type="primary"
                size="sm"
                className="my-4"
                onClick={() => handleBookingDetailsEditUpdate("edit")}
              />
            )}
          </div>
          {/* first-container */}
          <div className="flex flex-row justify-around mt-5 items-center w-full">
            {/* child-1 */}
            <div className="w-5/12">
              <div className="shadow-lg border px-4 py-12 rounded">
                <div className="flex justify-between items-center pb-5">
                  <p className="w-1/2 text-slate-700">Customer id</p>
                  <p
                    className="w-1/2 text-primary cursor-pointer"
                    onClick={() =>
                      handleCustomerIdClick(bookingDetails.customer_id)
                    }
                  >
                    {bookingDetails.customer_id}
                  </p>
                </div>
                <div className="flex justify-between items-center pb-5">
                  <p className="w-1/2 text-slate-700">Checkin</p>
                  {isEditable ? (
                    <input
                      className={` ${INPUT_CLASS}`}
                      value={bookingData.checkin.slice(0, 10)}
                      onChange={(e) => handleInput(e, "checkin")}
                      type="date"
                    />
                  ) : (
                    <p className="w-1/2">
                      {formatDate(bookingDetails.checkin)}
                    </p>
                  )}
                </div>
                <div className="flex justify-between items-center ">
                  <p className="w-1/2 text-slate-700">Checkout</p>
                  {isEditable ? (
                    <input
                      className={` ${INPUT_CLASS}`}
                      value={bookingData.checkout.slice(0, 10)}
                      onChange={(e) => handleInput(e, "checkout")}
                      type="date"
                    />
                  ) : (
                    <p className="w-1/2">
                      {formatDate(bookingDetails.checkout)}
                    </p>
                  )}
                </div>
              </div>
            </div>

            {/* child-2 */}
            <div className="shadow-lg borde  rounded p-4  w-5/12">
              <div className="flex justify-between items-center pb-2">
                <p className="w-1/2 text-slate-700">Booking id</p>
                <p className="w-1/2">{bookingDetails.id}</p>
              </div>

              <div className="flex justify-between items-center pb-2">
                <p className="w-1/2 text-slate-700">Booking Number</p>
                {isEditable ? (
                  <input
                    className={` ${INPUT_CLASS}`}
                    value={bookingData.booking_number}
                    onChange={(e) => handleInput(e, "booking_number")}
                  />
                ) : (
                  <p className="w-1/2">{bookingDetails.booking_number}</p>
                )}
              </div>

              <div className="flex justify-between items-center pb-2">
                <p className="w-1/2 text-slate-700">Booking Date</p>
                {isEditable ? (
                  <input
                    className={` ${INPUT_CLASS}`}
                    value={bookingData.booking_date.slice(0, 10)}
                    onChange={(e) => handleInput(e, "booking_date")}
                    type="date"
                  />
                ) : (
                  <p className="w-1/2">
                    {formatDate(bookingDetails.booking_date)}
                  </p>
                )}
              </div>

              <div className="flex justify-between items-center pb-2">
                <p className="w-1/2 text-slate-700">Property</p>
                {isEditable ? (
                  <div className="w-1/2">
                    <label className="pb-1 ml-1 block text-slate-500">
                      Select Property
                    </label>
                    <div className="shadow-sm shadow-primary">
                      <MyComboBox
                        data={listings}
                        title="title"
                        label="Select Property"
                        propertyId={propertyId}
                        firstOption={`title: "Choose from the Properties"`}
                        myKey="listing_id"
                        newData={newBookingData}
                        setNewData={setNewBookingData}
                      />
                    </div>
                  </div>
                ) : (
                  <p
                    className="w-1/2 text-primary cursor-pointer"
                    // onClick={() =>handlePropertyIdClick(bookingDetails.property_id)}
                  >
                    {/* {bookingDetails.property_id} */}
                    {bookingDetails && getProperty(bookingDetails.property_id)}
                  </p>
                )}
              </div>
            </div>
          </div>

          {/* second-container */}
          <div className="w-[50%] mt-10 shadow-lg borde rounded p-3 drop-shadow mb-5">
            <div className="flex justify-between items-center pb-2">
              <p className="w-1/2 text-slate-700">Booking Status</p>
              {isEditable ? (
                <select
                  className={` ${INPUT_CLASS}`}
                  onChange={(e) => handleInput(e, "booking_status")}
                  value={bookingData.booking_status}
                >
                  <option value="">Choose Booking Status</option>
                  {BOOKING_STATUS_ARR.map((status) => (
                    <option value={Object.values(status)}>
                      {" "}
                      {Object.keys(status)}{" "}
                    </option>
                  ))}
                </select>
              ) : (
                <p className="w-1/2">
                  {getStatusComponent(bookingDetails.booking_status)}
                </p>
              )}
            </div>
            <div className="flex justify-between items-center pb-2">
              <p className="w-1/2 text-slate-700">Booking type</p>
              {isEditable ? (
                <select
                  className={` ${INPUT_CLASS}`}
                  onChange={(e) => handleInput(e, "booking_type")}
                  value={bookingData.booking_type}
                >
                  <option value="">Choose Booking Type</option>
                  {BOOKING_TYPE_ARR.map((type) => (
                    <option value={Object.values(type)}>
                      {" "}
                      {Object.keys(type)}{" "}
                    </option>
                  ))}
                </select>
              ) : (
                <p className="w-1/2">
                  {getBookingType(bookingDetails.booking_type)}
                </p>
              )}
            </div>
            <div className="flex justify-between items-center pb-2">
              <p className="w-1/2 text-slate-700">Booking Channel</p>
              {isEditable ? (
                <select
                  className={` ${INPUT_CLASS}`}
                  onChange={(e) => handleInput(e, "channel_name")}
                  value={bookingData.booking_channel}
                >
                  <option value="">Choose Booking Channel</option>
                  {BOOKING_CHANNEL_ARR.map((channel) => (
                    <option value={channel.value}>
                      {" "}
                      {Object.keys(channel.name)}{" "}
                    </option>
                  ))}
                </select>
              ) : (
                <p className="w-1/2">
                  {getBookingType(bookingDetails.booking_channel)}
                </p>
              )}
            </div>
            <div className="flex justify-between items-center pb-2">
              <p className="w-1/2 text-slate-700">Promo Code</p>
              {isEditable ? (
                <input
                  className={` ${INPUT_CLASS}`}
                  value={bookingData.promo_code}
                  onChange={(e) => handleInput(e, "promo_code")}
                />
              ) : (
                <p className="w-1/2">{bookingDetails.promo_code}</p>
              )}
            </div>
            <div className="flex justify-between  items-center pb-2">
              <p className="w-1/2 font-semibold text-[blue]">Total Amount</p>
              {isEditable ? (
                <input
                  className={` ${INPUT_CLASS}`}
                  value={bookingData.total_amount}
                  type="number"
                  onChange={(e) => handleInput(e, "total_amount")}
                />
              ) : (
                <p className="w-1/2 font-semibold text-[blue]">
                  {bookingDetails.total_amount}
                </p>
              )}
            </div>
            <div className="flex justify-between items-center pb-2">
              <p className="w-1/2 text-slate-700">Discount</p>
              {isEditable ? (
                <input
                  className={` ${INPUT_CLASS}`}
                  value={bookingData.discount_amount}
                  onChange={(e) => handleInput(e, "discount_amount")}
                  type="number"
                />
              ) : (
                <p className="w-1/2 text-danger">
                  -{bookingDetails.discount_amount}
                </p>
              )}
            </div>
            <div className="flex justify-between items-center pb-2">
              <p className="w-1/2 text-slate-700">Grand Total</p>
              {isEditable ? (
                <input
                  className={` ${INPUT_CLASS}`}
                  value={bookingData.grand_total}
                  onChange={(e) => handleInput(e, "grand_total")}
                  type="number"
                />
              ) : (
                <p className="w-1/2">{bookingDetails.grand_total}</p>
              )}
            </div>
            <div className="flex justify-between items-center pb-2">
              <p className="w-1/2 text-[#468725] font-semibold ">Amount paid</p>
              {isEditable ? (
                <input
                  className={` ${INPUT_CLASS}`}
                  value={bookingData.amount_paid}
                  onChange={(e) => handleInput(e, "amount_paid")}
                  type="number"
                />
              ) : (
                <p className="w-1/2 text-[#468725] font-semibold">
                  {bookingDetails.amount_paid}
                </p>
              )}
            </div>
            <div className="flex justify-between items-center pb-2">
              <p className="w-1/2 text-main font-semibold">Due amount</p>
              {isEditable ? (
                <input
                  className={` ${INPUT_CLASS}`}
                  value={bookingData.due_amount}
                  onChange={(e) => handleInput(e, "due_amount")}
                  type="number"
                />
              ) : (
                <p className="w-1/2 text-main font-semibold">
                  {bookingDetails.due_amount}
                </p>
              )}
            </div>

            <div className="flex justify-between items-center pb-2">
              <p className="w-1/2 text-slate-700">Deduction Amount</p>
              {isEditable ? (
                <input
                  className={` ${INPUT_CLASS}`}
                  value={bookingData?.deduction_amount}
                  onChange={(e) => handleInput(e, "deduction_amount")}
                />
              ) : (
                <p className="w-1/2">{bookingDetails?.deduction_amount}</p>
              )}
            </div>

            <div className="flex justify-between items-center pb-2">
              <p className="w-1/2 text-slate-700">Deduction  Reason</p>
              {isEditable ? (
                <input
                  className={` ${INPUT_CLASS}`}
                  value={bookingData?.deduction_reason}
                  onChange={(e) => handleInput(e, "deduction_reason")}
                />
              ) : (
                <p className="w-1/2">{bookingDetails?.deduction_reason}</p>
              )}
            </div>

            <div className="flex justify-between items-center pb-2">
              <p className="w-1/2 text-slate-700">Payment method</p>
              {isEditable ? (
                <select
                  className={` ${INPUT_CLASS}`}
                  onChange={(e) => handleInput(e, "payment_method")}
                  value={bookingData.payment_method}
                >
                  <option value="">Choose Payment Method</option>
                  {PAYMENT_METHOD_ARR.map((method) => (
                    <option value={Object.values(method)}>
                      {" "}
                      {Object.keys(method)}{" "}
                    </option>
                  ))}
                </select>
              ) : (
                <p className="w-1/2">
                  {getPaymentMethod(bookingDetails.payment_method)}
                </p>
              )}
            </div>
            <div className="flex justify-between items-center pb-2">
              <p className="w-1/2 text-slate-700">Payment status</p>
              {isEditable ? (
                <select
                  className={` ${INPUT_CLASS}`}
                  onChange={(e) => handleInput(e, "payment_status")}
                  value={bookingData.payment_status}
                >
                  <option value="">Choose Payment Status</option>
                  {PAYMENT_STATUS_ARR.map((status) => (
                    <option value={Object.values(status)}>
                      {Object.keys(status)}
                    </option>
                  ))}
                </select>
              ) : (
                <p className="w-1/2">
                  {getStatusComponent(bookingDetails.payment_status)}
                </p>
              )}
            </div>
            <div className="flex justify-between items-center pb-2">
              <p className="w-1/2 text-slate-700">Is part payment</p>
              {isEditable ? (
                <select
                  className={` ${INPUT_CLASS}`}
                  onChange={(e) => handleInput(e, "is_partpayment")}
                  value={bookingData.is_partpayment}
                >
                  <option value="">Choose Payment Type</option>
                  {IS_PART_PAYMENT_ARR.map((type) => (
                    <option value={Object.values(type)}>
                      {Object.keys(type)}
                    </option>
                  ))}
                </select>
              ) : (
                <p className="w-1/2">
                  {checkIfPartPayment(bookingDetails.is_partpayment)}
                </p>
              )}
            </div>
            <div className="flex justify-between items-center pb-2">
              <p className="w-1/2 text-slate-700">Created at</p>
              <p className="w-1/2">{formatDate(bookingDetails.created_at)}</p>
            </div>
            <div className="flex justify-between items-center pb-2">
              <p className="w-1/2 text-slate-700">Updated at</p>
              <p className="w-1/2">{formatDate(bookingDetails.updated_at)}</p>
            </div>
          </div>

          {/* third-container */}
          <div className="w-[50%] shadow-lg borde rounded p-3 drop-shadow mb-5">
            <div className="flex justify-between items-center pb-2">
              <p className="w-1/2 text-slate-700">Adults</p>
              {isEditable ? (
                <input
                  className={` ${INPUT_CLASS}`}
                  value={bookingData.total_adults}
                  type="number"
                  onChange={(e) => handleInput(e, "total_adults")}
                />
              ) : (
                <p className="w-1/2">{bookingDetails.total_adults}</p>
              )}
            </div>
            <div className="flex justify-between items-center pb-2">
              <p className="w-1/2 text-slate-700">Children</p>
              {isEditable ? (
                <input
                  className={` ${INPUT_CLASS}`}
                  value={bookingData.total_childrens}
                  type="number"
                  onChange={(e) => handleInput(e, "total_childrens")}
                />
              ) : (
                <p className="w-1/2">{bookingDetails.total_childrens}</p>
              )}
            </div>
            <div className="flex justify-between items-center pb-2">
              <p className="w-1/2 text-slate-700">Infants</p>
              {isEditable ? (
                <input
                  className={` ${INPUT_CLASS}`}
                  value={bookingData.total_infants}
                  type="number"
                  onChange={(e) => handleInput(e, "total_infants")}
                />
              ) : (
                <p className="w-1/2">{bookingDetails.total_infants}</p>
              )}
            </div>
          </div>
        </div>
      ) : (
        "NO DATA"
      )}
    </div>
  );
}

export default BookingDetail;
