import React, {useState, useEffect } from 'react'
import { loaderStart, loaderSuccess, error } from '../../../store/reducers/loaderSlice'
import { useDispatch } from "react-redux";
import { DeleteIcon, EditIcon } from '../../../icons'
import { formatDate } from "../../../utils/common";
import PageHeader from "../../../components/pageHeader";
import Table from "../../../components/hkTable";
import Dialog from "../../../ui/dialog";
import { createActivitiesAttractionMapping, deleteActivitiesAttractionMapping, getAllActivitiesAttractionMapping, updateActivitiesAttractionMapping } from '../../../services/activitiesAttractionsMapping';
import ActivitiesAttractionsMappingForm from '../../../components/forms/listingAttributes/activitiesAttractionsMapping';
import { getActivities } from '../../../store/reducers/listingAttributesSlice';
import { useActivitiesAttractionsHashMapSelector, useDestinationHashMapSelector } from '../../../store/selectors';
import CommonDeletePopup from '../../../components/popup/commonDeletePopup';


const TABLE_COLUMNS = [
    {
      fieldName: "id",
      headName: "ID",
      sort: false,
      filter: false,
      className: "w-[150px]",
      filterFormat: "array",
      inputType: "text",
      hideColumn: false,
    },
    {
      fieldName: "dest_id",
      headName: "Destination Id",
      sort: false,
      filter: false,
      className: "w-[150px]",
      filterFormat: "array",
      inputType: "text",
      hideColumn: false,
    },
    {
      fieldName: "act_id",
      headName: "Activities/Attractions Id",
      sort: false,
      filter: false,
      className: "w-[200px]",
      filterFormat: "array",
      inputType: "text",
      hideColumn: false,
    },
    {
        fieldName: "type",
        headName: "Type",
        sort: false,
        filter: false,
        className: "w-[150px]",
        filterFormat: "array",
        inputType: "text",
        hideColumn: false,
    },
    {
        fieldName: "is_deleted",
        headName: "Is Deleted",
        sort: false,
        filter: false,
        className: "w-[150px]",
        filterFormat: "array",
        inputType: "text",
        hideColumn: false,
    },
    {
        fieldName: "is_active",
        headName: "Is Active",
        sort: false,
        filter: false,
        className: "w-[150px]",
        filterFormat: "array",
        inputType: "text",
        hideColumn: false,
    },
    {
        fieldName: "createdAt",
        headName: "Created At",
        sort: false,
        filter: false,
        className: "w-[150px]",
        filterFormat: "array",
        inputType: "text",
        hideColumn: false,
    },
    {
        fieldName: "updatedAt",
        headName: "Updated At",
        sort: false,
        filter: false,
        className: "w-[150px]",
        filterFormat: "array",
        inputType: "text",
        hideColumn: false,
    },
    {
      fieldName: "edit",
      headName: "Edit",
      sort: false,
      filter: false,
      className: "w-[100px]",
      filterFormat: "array",
      inputType: "text",
      hideColumn: false,
  },
  {
    fieldName: "delete",
    headName: "Delete",
    sort: false,
    filter: false,
    className: "w-[100px]",
    filterFormat: "array",
    inputType: "text",
    hideColumn: false,
}
]

function DestinationAttractions() {
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [pageLoad, setPageLoad] = useState(false);
  const [payload, setPayload] = useState({dest_id:"", act_id:"", type:"attraction"});
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false)
  const [deleteObj, setDeleteObj] = useState({})
  const [actionType, setActionType] = useState('CREATE')

  const activitiesAttractionsHashMap = useActivitiesAttractionsHashMapSelector();
  const destinationHashmap = useDestinationHashMapSelector()

  

  useEffect(() => {
    getData()
  }, [pageLoad]);


  async function getData(){
     try {
        dispatch(loaderStart())
        const response = await getAllActivitiesAttractionMapping();
        let result = transformRows(response)
        setRows(result)
        dispatch(loaderSuccess())
     }
     catch (e) {
        dispatch(error(e?.message))
     }
  }


  function transformRows(response) {
     return response?.map(res => ({
       ...res,
       edit: getEditComponent(res),
       delete: getDeleteComponent(res),
       dest_id: <GetDestinationIdComponent id={res?.dest_id}/>,
       act_id: <GetActivitiesIdComponent id={res?.act_id}/>,
       is_deleted: <GetBooleonJsx type={res?.is_deleted}/>,
       is_active: <GetBooleonJsx type={res?.is_active}/>
     }))
  }

  function GetBooleonJsx({type}) {
     return (
       <span>{type ? "True": "False"}</span>
      )
  }

  function GetDestinationIdComponent({id}) {
    return ( <>{destinationHashmap[id]?.name}</>)
  }

  function GetActivitiesIdComponent({id}) {
    return (<>{activitiesAttractionsHashMap[id]?.title}</>)
  }

  function getEditComponent(item) {
    return (
      <div onClick={() => handleOpenEditDialog(item)}>
        <EditIcon />
      </div>
    );
  }

  function getDeleteComponent(item) {
    return (
      <div>
        <DeleteIcon onClick={() => handleOpenDeleteDialog(item)}/>
      </div>
    );
  }
  


  const handleOpenCreateDialog = () => {
    setPayload({dest_id:"", act_id:"", type:"attraction"})
    setActionType('CREATE')
    setIsOpen((prev) => !prev)
  }

  const handleOpenEditDialog = ( item ) => {
    const {dest_id, act_id, type, id} = item
    setPayload({dest_id, act_id, type, id});
    setIsOpen((prev) => !prev)
    setActionType('UPDATE')
  }

  function handleOpenDeleteDialog( item ) {
     const {id, dest_id, act_id, type} = item 
     setDeleteObj({id, dest_id:destinationHashmap[dest_id]?.name, act_id:activitiesAttractionsHashMap[act_id]?.title, type})
     setIsOpenDeleteDialog((prev) => !prev)
  }

  const handleCloseDialog = () => {
    setIsOpen((prev) => !prev)
    setActionType('CREATE')
  }

 
  const handleAction = async () => {
    if ( actionType === 'CREATE') {
      await handleCreate()
    }
    else {
      await handleEdit()
    }
  }


  async function handleEdit() {
    try {
        let body = {dest_id:payload?.dest_id, act_id:payload?.act_id, type:payload?.type}
        dispatch(loaderStart())
        const response = await updateActivitiesAttractionMapping( payload?.id, body );
        dispatch(loaderSuccess())
        setIsOpen((prev) => !prev)
        setPageLoad((prev) => !prev)
    } 
    catch (e) {
      dispatch(error(e?.message))
    }
  }

  async function handleCreate() {
    try {
      dispatch(loaderStart())
      const response = await createActivitiesAttractionMapping( payload )
      dispatch(loaderSuccess())
      setIsOpen((prev) => !prev)
      setPageLoad((prev) => !prev)
    }
    catch (e) {
      dispatch(error(e?.message))
    }
  }

  async function handleDelete() {
    try {
      dispatch(loaderStart())
      const response = await deleteActivitiesAttractionMapping( deleteObj?.id );
      dispatch(loaderSuccess())
      setPageLoad((prev) => !prev)
      setIsOpenDeleteDialog((prev) => !prev)
    }
    catch (e) {
      dispatch(error(e?.message))
    }
  }




  return (
    <>
      <div>
       <PageHeader title="Activities/Attractions Mapping" onClick={handleOpenCreateDialog} />
        {rows && (
          <Table
            rows={rows}
            columns={TABLE_COLUMNS}
            filter={false}
            pagiNationFilter={false}
          />
        )}
      </div>

      <Dialog createClick={actionType === 'CREATE'} isOpen={isOpen} closeModal={handleCloseDialog} childrenClass={"w-[50%] p-6 rounded-md no-scrollbar dark-scrollbar"}>
          <ActivitiesAttractionsMappingForm actionType={actionType} payload={payload} setPayload={setPayload} handleAction={handleAction} handleCloseDialog={handleCloseDialog} />
      </Dialog>

      {isOpenDeleteDialog && (
          <CommonDeletePopup
            title={deleteObj?.type === 'attraction' ? 'Attraction' : 'Activities'}
            deleteObj={deleteObj}
            handleDeleteData={handleDelete}
            setPageLoad={setPageLoad}
            onClose={() => setIsOpenDeleteDialog((prev) => !prev)}
          />
        )
      }

    </>
  )
}

export default DestinationAttractions