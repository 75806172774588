import React from 'react'
import {  useActivitiesAttractionsSelector, useDestinationsSelector } from '../../../../store/selectors'
import { INPUT_STYLE } from '../../../../constants';

function ActivitiesAttractionsMappingForm({ actionType, payload, setPayload, handleAction, handleCloseDialog }) {
  const destinations = useDestinationsSelector();
  const activitiesAttractions = useActivitiesAttractionsSelector();
  
  const handleOnChange = (e, key) => {
     let value = e.target.value
     setPayload((prev) => ({...prev, [key]:value}))
  }

 
   const renderOptions = () => {
     switch (payload?.type) {
        case "attraction": 
           return activitiesAttractions.filter((data) => data.type === 'attraction').map((attraction, idx) => <option value={attraction?.id} key={idx}>{attraction?.title}</option>)
           
         case 'activities':
            return activitiesAttractions.filter((data) => data.type === 'activities').map((activities, idx) => <option value={activities?.id} key={idx}>{activities?.title}</option>)
      }
   }
 
  return (
    <>
      <div className='flex flex-col mt-6'>
         <label className='text-slate-600'>Destination</label>
         <select className={INPUT_STYLE} onChange={(e) => handleOnChange(e, 'dest_id')} value={payload?.dest_id}>
            <option>Choose Destination</option>
            {destinations &&  destinations.map((data,idx) => (
                <option key={idx} value={data?.id}>{data?.name}</option>
            ))}
         </select>
      </div>

      <div className='flex flex-col mt-6'>
         <label className='text-slate-600'>Type</label>
         <select className={INPUT_STYLE} onChange={(e) => handleOnChange(e, 'type')} value={payload?.type}>
            <option value="attraction">Attraction</option>
            <option value="activities">Activities</option>
         </select>
      </div>

      <div className='flex flex-col mt-6'>
         <label className='text-slate-600'>{payload?.type === 'attraction' ?  'Attraction' : "Activities"}</label>
         <select className={INPUT_STYLE} onChange={(e) => handleOnChange(e, 'act_id')} value={payload?.act_id}>
            <option>Choose {payload?.type === 'attraction' ?  'Attraction' : "Activities"}</option>
            {/* {activitiesAttractions &&  activitiesAttractions.map((data,idx) => (
                <option key={idx} value={data?.id}>{data?.title}</option>
            ))} */}
            {renderOptions()}
         </select>
      </div>

     <div className='mt-10 flex items-center justify-between'>
        <button className='border border-black rounded-md px-3 py-2 hover:bg-black hover:text-white' onClick={handleCloseDialog}>Close</button>
        <button className='bg-black text-white px-3 py-2 rounded-md' onClick={handleAction}>{actionType === 'CREATE' ? 'Create' : "Update"}</button>
     </div>
    </>
  )
}

export default ActivitiesAttractionsMappingForm