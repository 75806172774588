import React from 'react'

function ThankYouPage({reservationData, checkoutData, payload2}) {
  console.log('reservationData', reservationData);
  console.log('checkoutData', checkoutData)
  let listingImage = checkoutData?.listing_data?.media?.file_path
  let title = checkoutData?.listing_data?.title 

  return (
    <div className='flex flex-col items-center justify-center'>
        <img className='h-[200px]' src={`${process.env.REACT_APP_CDN_URL}${listingImage}`}/>
        <p className='font-bold mt-2 text-base'>{title}</p>

        <div className='flex flex-col items-center justify-center  mt-3 '>
         
          <img 
              src="https://www.holidaykeepers.com/wp-content/uploads/2024/02/payment-success.gif"
              className={`!w-28 !h-28`}
            />
          <div>

         
          <p className='font-bold'>Congratulations Reservation has been created <span className='text-[#E01457]'>#{reservationData?.orderId}</span></p>


          <div className='flex items-center gap-x-3'>
            <p className='w-[30%]'>Checkout Id</p>
            <p className='text-[#E01457]'>{checkoutData?.id}</p>
          </div>
          <div className='flex items-center gap-x-3'>
            <p className='w-[30%]'>Payment Id</p>
            <p className='text-[#E01457]'>{reservationData?.paymentId}</p>
          </div>
          <div className='flex items-center gap-x-3'>
            <p className='w-[30%]'>Order Status</p>
            <p className='text-[#E01457]'>{reservationData?.order_status}</p>
          </div>

          <div className="flex flex-col w-full">
         <div className="flex items-center gap-x-3">
            <span className='w-[30%]'>Fisrt Name</span>
            <p title={payload2?.fname} className="text-base text-[#E01457]">{payload2?.fname}</p>
         </div>

         <div className="flex items-center gap-x-3">
            <span className='w-[30%]'>Last Name</span>
            <p title={payload2?.lname} className="text-base text-[#E01457]">{payload2?.lname}</p>
         </div>

         <div className="flex items-center gap-x-3">
            <span className='w-[30%]'>Email</span>
            <p title={payload2?.email} className="text-base text-[#E01457]">{payload2?.email}</p>
         </div>

         <div className="flex items-center gap-x-3">
            <span className='w-[30%]'>Mobile</span>
            <span title={payload2?.mobile} className="text-base text-[#E01457]">{payload2?.phone_ext} {payload2?.mobile}</span>
         </div>

       

       </div>
          </div>
        </div>
    </div>
  )
}

export default ThankYouPage