import api from '../api'
import endPoints from './endPoints';


export async  function getAllGeotags() {
  try  {
    const response = await api.get(endPoints.getAllGeoTags());
    return response
  }
  catch (e) {
    throw new Error(e?.message)
  }
}

export async function postGeotag( body ) {
  try  {
    const response = await api.post(endPoints.createGeoTag(), body);
    return response
  }
  catch (e) {
    throw new Error(e?.message)
  }
}


export async function putGeotag(body) {
  try  {
    const response = await api.put(endPoints.updateGeoTag(), body);
    return response
  }
  catch (e) {
    throw new Error(e?.message)
  }
}

export async function deleteGeotag(id) {
  try  {
    const response = await api.delete(endPoints.deleteGeoTag(id));
    return response
  }
  catch (e) {
    throw new Error(e?.message)
  }
}





