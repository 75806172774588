import React, { useEffect } from 'react'
import {useNotificationSelector} from '../../store/selectors/notificationSelector'
import { Toaster, toast, ToastBar } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { clearMessage } from '../../store/reducers/notificationsSlice';

function Notifications() {
  const dispatch = useDispatch()
  const message = useNotificationSelector()

  useEffect(() => {
    if (message) {
        toast.success(message);
    }
    dispatch(clearMessage());
  }, [dispatch, message])

  return (
    <Toaster position="top-right"  reverseOrder={false} 
    toastOptions={{
        className: '',
        // duration: 5000,
        style: {
            marginTop: "60px",
            // border: '1px solid black',
            paddingRight: '30px',
            paddingLeft: '30px',
            //color: '#713200',
        },
        success: {
      duration: 3000,
      theme: {
        primary: 'green',
        secondary: 'black',
      },
    },

      }}

      
      
      
      />
    
  )
}

export default Notifications