import React, { useMemo } from 'react';
import Input from './input';
import Map from '../../ui/map';
import { INPUT_STYLE } from '../../constants'
import { useSelector } from 'react-redux';
import { currencyData } from '../../constants';

const basicPropertyData = [
  { key: 'permalink', label: 'Permalink' },
  { key: 'title', label: 'Title' },
  { key: 'Description', label: 'Description', type: 'textarea' },
];

const locationData = [
  { key: 'house', label: 'House' , value:'address'},
  { key: 'area', label: 'area', value:'address'},
  { key: 'street', label: 'Street', value:'address' },
  { key: 'landmark', label: 'Landmark', value:'address' },
  { key: 'city', label: 'City', value:'address' },
  { key: 'pin', label: 'Pin', value:'address' },
  { key: 'location_latitude', label: 'Latitude', value:'lat-long' },
  { key: 'location_longitude', label: 'Longitude', value:'lat-long' },
];

const BasicPropertyDetails = ({ payload, setPayload }) => {

  const attributes = useSelector((state) =>  state.attributes);
  const countryState = useSelector((state) => state.country);
  const regionsState =  useSelector((state) => state.regions);
  const destinationsState = useSelector((state) => state.destinations);
  const hostsData = useSelector((state) => state.users);

  const listingTypes = attributes?.listingTypes?.data;
  const processingFee = attributes?.processingFee?.data
  const countries = countryState?.countryList;
  const regions = regionsState?.regions?.data;
  const destinations = destinationsState?.destinations?.data;
  const hosts = hostsData?.hostAndAdmin;
  const countryHashMap = countryState?.countryHashMap

  const handleOnChangeBasicPropertyDetails = (e, key) => setPayload((prev) => ({ ...prev, [key]: e.target.value }));

  const handleAdress = (e, key) => {
    let value = e.target.value;

    if ( key === 'location_latitude' || key === 'location_longitude' ) {
      setPayload((prev) => ({ ...prev, [key]: value }));
    } 
    else {
      setPayload((prev) => ({ ...prev, address: {...prev.address, [key]: value} }));
    }
  };

  const handleOnChange = ( key, value ) => {
    setPayload((prev) => ({ ...prev, [key]: value }));
  }


  const handlePlaces = ( e, key ) => {
    let value = e.target.value 
    switch (key) {
      case 'country_id':
        let countryName = countryHashMap[value]?.name 
        setPayload( (prev) => ({ ...prev, address: {...prev.address, country:countryName, [key]:value}}) );
        break 
      case 'region':
        setPayload((prev) => ({ ...prev, address: {...prev.address, [key]:value}}));
        break 
      case 'destination':
        setPayload((prev) => ({ ...prev, address: {...prev.address, [key]:value}}));
        break 
      case 'processing_fee':
        setPayload((prev) => ({...prev, [key]:value}));
        break
      default:
        break
    }
  }


  const handleChangeCurrreny = (value) => {
    let symbol = currencyData[value]?.symbol;
    setPayload((prev) => ({...prev, currency:value, currency_symbol:symbol}))
  }


  console.log('processingFee', processingFee, payload?.address)
 

  return (
    <div>

     <div>
       <label className='text-[#5C5C5C] leading-6 text-lg font-normal'>Migration Id</label>
       <input className={`${INPUT_STYLE}`} value={payload?.migration_listing_id} onChange={(e) => setPayload((prev) => ({...prev, migration_listing_id:e.target.value}))}/>
     </div>
    
     


      {basicPropertyData.map((data, index) => (
        <Input
          key={index}
          className='mt-6'
          label={data.label}
          value={payload[data.key]}
          onChange={(e) => handleOnChangeBasicPropertyDetails(e, data.key)}
        />
      ))}

      <div className='flex items-center justify-between'>
            <div className={`flex flex-col mt-6 w-[20%]`}>
              <label className='text-[#5C5C5C] leading-6 text-lg font-normal'>Host</label>
              <select value={payload?.host_id} onChange={(e) => handleOnChange('host_id', e.target.value)} className={`${INPUT_STYLE} mt-1`}>
                <option>Choose Host</option>
                {hosts && hosts?.map((host, idx) => (
                  <option key={idx} value={host.id}>{host?.fname} {host?.lname}</option>
                )) }
              </select>
            </div>

            <div className={`flex flex-col mt-6 w-[20%]`}>
              <label className='text-[#5C5C5C] leading-6 text-lg font-normal'>Listing Type</label>
              <select value={payload?.listing_type} onChange={(e) => handleOnChange('listing_type', e.target.value)} className={`${INPUT_STYLE} mt-1`}>
                <option>Choose Listing Type</option>
                {listingTypes && listingTypes?.map((types, idx) => (
                  <option key={idx} value={types?.id}>{types?.name}</option>
                ))}
              </select>
            </div>

            <div className={`flex flex-col mt-6 w-[20%]`}>
              <label className='text-[#5C5C5C] leading-6 text-lg font-normal'>Processing Fee</label>
              <select onChange={(e) => handlePlaces(e, 'processing_fee')} value={payload?.processing_fee} className={`${INPUT_STYLE} mt-1`}>
                <option>Choose Processing Fee</option>
                {processingFee && processingFee.filter(fee => fee.country_id === payload.address.country_id)?.map((fee, idx) => (
                  <option key={idx} value={fee?.id}>{fee?.name}</option>
                ))}
              </select>
            </div>

            <div className={`flex flex-col mt-6 w-[20%]`}>
              <label className='text-[#5C5C5C] leading-6 text-lg font-normal'>Curreny</label>
              <select  onChange={(e) => handleChangeCurrreny(e.target.value)} value={payload?.currency} className={`${INPUT_STYLE} mt-1`}>
                <option value="">Choose  Curreny</option>
                {currencyData && Object.keys(currencyData).map((data) => (
                  <option value={data}>{`${data} - ${currencyData[data]?.symbol}`}</option>
                ))}
              </select>
            </div>
      </div>

      <div className='flex justify-between items-start mt-8 h-full gap-x-3'>
        <div className='w-full'>
        
        <div className={`flex flex-col mt-6`}>
          <label className='text-[#5C5C5C] leading-6 text-lg font-normal'>Country</label>
          <select onChange={(e) => handlePlaces(e, 'country_id')} value={payload?.address?.country_id} className={`${INPUT_STYLE} mt-1`}>
            <option>Choose Country</option>
            {countries && countries?.map((country, idx) => (
              <option key={idx} value={country?.id}>{country?.name}</option>
            ))}
           </select>
        </div>

        <div className={`flex flex-col mt-6`}>
          <label className='text-[#5C5C5C] leading-6 text-lg font-normal'>Region</label>
          <select onChange={(e) => handlePlaces(e, 'region')} value={payload?.address?.region} className={`${INPUT_STYLE} mt-1`}>
            <option>Choose Region</option>
            {regions && regions.filter(region => region.parent_id === payload.address.country_id).map((region, idx) => (
              <option key={idx} value={region?.id}>{region?.name}</option>
            ))}
           </select>
        </div>

        <div className={`flex flex-col mt-6`}>
          <label className='text-[#5C5C5C] leading-6 text-lg font-normal'>Destination</label>
          <select onChange={(e) => handlePlaces(e, 'destination')} value={payload?.address?.destination} className={`${INPUT_STYLE} mt-1`}>
            <option>Choose Destination</option>
            {destinations && destinations.filter(destination => destination.parent_id === payload.address.region).map((destination, idx) => (
              <option key={idx} value={destination?.id}>{destination?.name}</option>
            ))}
           </select>
        </div>


            <Input
              className='mt-6'
              label={'Country'}
              value={payload?.address?.country}
              onChange={(e) => {}}
            />
       
        {locationData.map((data, index) => (
            <Input
              key={index}
              className='mt-6'
              label={data.label}
              value={ data.value === 'address' ? payload?.address[data.key] : payload[data.key]}
              onChange={(e) => handleAdress(e, data.key)}
            />
          ))}
        </div>

        <div className='h-[100%] mt-8'>
          <Map payload={payload} setPayload={setPayload}
            onChange={(value) => {
                handleOnChange('location_latitude',  value.lat);
                handleOnChange('location_longitude',  value.lng);
                handleOnChange("address", {...payload?.address, 
                    street:value?.formatted_address,
                    landmark:value?.landmark,
                    city:value?.city,
                    pin:value?.postal_code,
                    country:value?.country,
                    country_id: countries?.find(e => e.name == value?.country)?.id || undefined
                  });

            }
            }
           />
        </div>
      </div>
    </div>
  );
};

export default BasicPropertyDetails;
