import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isValidToken } from "../../utils";
import { authSuccess, authFail } from "../../store/reducers/authSlice";
import { error } from "../../store/reducers/loaderSlice";
import ErrorPopup from "../../components/popup/errorPopup";
import Cookies from "js-cookie";
import { showNotifications } from "../../store/reducers/notificationsSlice";

function Login() {
  const errorMessage = useSelector((state) => state.loader.errorMessage);
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      window.googleSSOSuccess = function (e) {
        const token = e.credential;
        
        if (!token) {
        } 
        else {
          localStorage.setItem("token", JSON.stringify(token));
          Cookies.set("accessToken", token);
          const validateToken = isValidToken(token);
          if (validateToken.data) {
            dispatch(authSuccess());
            dispatch(showNotifications(`Welcome ${validateToken?.name}`))
          } else {
            dispatch(error(validateToken.error));
            dispatch(authFail());
          }
        }
      };
      loadScript("https://accounts.google.com/gsi/client");
    }
    catch (e ) {
      console.log('e', e)
    }
  }, [dispatch]);

  const loadScript = (url) => {
    const myScript = document.createElement("script");
    myScript.src = url;
    document.body.appendChild(myScript);
  };

  return (
    <>
      {errorMessage && <ErrorPopup isOpen={true} errorMessage={errorMessage} />}
      <div
        style={{ backgroundColor: "rgba(245, 242, 235, 1)" }}
        className="flex flex-col justify-center items-center h-screen"
      >
        <div className="flex flex-col">
          <div className="flex flex-col">
            <img
              width="184"
              alt="logo"
              src="https://www.holidaykeepers.com/wp-content/uploads/2022/05/holidaykeepers-logo.png"
            />
            <div className="mt-4">
              <GoogleSSOButton />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function GoogleSSOButton() {
  console.log('1')

  const clientId = process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID
    

  return (
    <>
      <div
        id="g_id_onload"
        data-client_id={clientId}
        data-context="signin"
        data-ux_mode="popup"
        data-callback="googleSSOSuccess"
        data-auto_prompt="false"
      ></div>

      <div
        className="g_id_signin"
        data-type="standard"
        data-shape="rectangular"
        data-theme="outline"
        data-text="signin_with"
        data-size="large"
        data-logo_alignment="left"
      ></div>
    </>
  );
}

export default Login;
