import React, { useEffect, useState } from 'react'
import { INPUT_STYLE } from '../../../constants'
import { useCountrySelector, useRegionsSelector } from '../../../store/selectors';
import { useDispatch } from 'react-redux';
import { error, loaderStart, loaderSuccess } from '../../../store/reducers/loaderSlice';
import { validateUserPayload } from '../../../validators/user';
import { createUser, updateUser } from '../../../services/userServices';
import { showNotifications } from '../../../store/reducers/notificationsSlice';
import PhoneExtensionSelectBar from '../../dropdown/PhoneInputs/selectPhoneNo';
import { userTypesConstants } from '../../../constants/userConstants';

function User({actionType='create', handleClose, setPageLoad, setIsOpen, editId, updatePayload}) {
 const [payload, setPayload] = useState({ fname:'', lname:'', email:'', username:'',  password:'',gender:'', languages:'', bio:'', country_id:'', state_id:'', user_role: '', mobile:'', mobile_ext:'+1'});
 const [updatedFields, setUpdatedFields] = useState(null)


 useEffect(() => {
    if (actionType === 'update') {
      setPayload(updatePayload)
    }
 }, [])

 const dispatch = useDispatch();

 const countriesData = useCountrySelector();
 const regionsData = useRegionsSelector();
 

 const handleOnChange = (e, key) => {
    let value = e.target.value 
    if (key === 'country_id') {
        setPayload((prev) => ({...prev, [key]:value, state_id:''}))
        setUpdatedFields((prev) => ({...prev, [key]:value, state_id:''}))
    }
    else {
        setPayload((prev) => ({...prev, [key]:value}))
        setUpdatedFields((prev) => ({...prev, [key]:value}))
    }
 }

 const handleCreateOrUpdate = async () => {
    try {
        dispatch(loaderStart())
        if (actionType === 'create') {
          await handleCreateUser();
        }
        else {
           await handleUpdateUser()
        }
    }
    catch(e) {
       dispatch(error(e?.message))
    }
 }

 async function handleCreateUser() {
    validateUserPayload(payload)
    const response = await createUser(payload);
    console.log('response', response);
    setPageLoad((prev) => !prev);
    setIsOpen((prev) => !prev);
    dispatch(loaderSuccess())
    dispatch(showNotifications('Created Successfully'))
 }

 async function handleUpdateUser() {
    validateUserPayload(payload)
    const response = await updateUser(updatedFields, editId);
    console.log('response', response);
    setPageLoad((prev) => !prev);
    setIsOpen((prev) => !prev);
    dispatch(loaderSuccess())
    dispatch(showNotifications('Updated Successfully'))
 }

 //phone number input on change
 const handlePhoneOnChange = (e) => {
    setPayload((prev) => ({...prev, mobile:e.target.value}))
    setUpdatedFields((prev) => ({...prev, mobile:e.target.value}))
 }

 //phone ext onchange
 const handlePhoneExt = (value) => {
    setPayload((prev) => ({...prev, mobile_ext:value}))
    setUpdatedFields((prev) => ({...prev, mobile_ext:value}))
 }

  return (
   <>
    <div className='flex items-center flex-wrap gap-x-4'>
        <div className='flex flex-col w-[45%]'>
            <label>FirstName</label>
            <input className={`${INPUT_STYLE}`} value={payload?.fname} onChange={(e) => handleOnChange(e, 'fname')} />
        </div>

        <div className='flex flex-col  w-[45%]'>
            <label>LastName</label>
            <input className={`${INPUT_STYLE}`} value={payload?.lname} onChange={(e) => handleOnChange(e, 'lname')} />
        </div>

        <div className='flex flex-col w-[45%] mt-4'>
            <label>Username</label>
            <input className={`${INPUT_STYLE}`} value={payload?.username} onChange={(e) => handleOnChange(e, 'username')} />
        </div>

        <div className='flex flex-col w-[45%] mt-4'>
            <label>Email</label>
            <input className={`${INPUT_STYLE}`} value={payload?.email} onChange={(e) => handleOnChange(e, 'email')} />
        </div>

        <div className='flex flex-col w-[45%] mt-4 '>
            <PhoneExtensionSelectBar
            phoneExt={payload?.mobile_ext}
            onChange={handlePhoneOnChange}
            label={"Mobile"}
            inputStyle={"flex flex-col"}
            value={payload?.mobile}
            selectPhoneExt={handlePhoneExt}
            className={"w-[90%] h-[40px]"}
            />
        </div>

        <div className='flex flex-col w-[45%] mt-4'>
            <label>Date Of Birth</label>
            <input className={`${INPUT_STYLE}`} type="date" value={payload?.dob} onChange={(e) => handleOnChange(e, 'dob')} />
        </div>

        {actionType === 'create' && (
         <div className='flex flex-col w-[45%] mt-4'>
            <label>Password</label>
            <input className={`${INPUT_STYLE}`} value={payload?.password} onChange={(e) => handleOnChange(e, 'password')} />
         </div>
        )}

        <div className='flex flex-col w-[45%] mt-4'>
            <label>Gender</label>
            <select className={`${INPUT_STYLE}`} value={payload?.gender} onChange={(e) => handleOnChange(e, 'gender')}>
                <option value="">Choose an Option</option>
                <option value={userTypesConstants?.USER_MALE}>Male</option>
                <option value={userTypesConstants?.USER_FEMALE}>Female</option>
            </select>
        </div>

        <div className='flex flex-col w-[45%] mt-4'>
            <label>Known Languages</label>
            <input className={`${INPUT_STYLE}`} value={payload?.languages} onChange={(e) => handleOnChange(e, 'languages')} />
        </div>

        <div className='flex flex-col w-[45%] mt-4'>
            <label>User Role</label>
            <select className={`${INPUT_STYLE}`} value={payload?.user_role} onChange={(e) => handleOnChange(e, 'user_role')}>
                <option value="">Choose an Option</option>
                <option value={userTypesConstants?.USER_ROLE_ADMIN}>Admin</option>
                <option value={userTypesConstants?.USER_ROLE_HOST}>Host</option>
                <option value={userTypesConstants?.USER_ROLE_CUSTOMER}>Customer</option>
            </select>
        </div>
        <div className='flex flex-col w-[45%] mt-4'>
            <label>Country</label>
            <select className={`${INPUT_STYLE}`} value={payload?.country_id} onChange={(e) => handleOnChange(e, 'country_id')}>
                <option value="">Choose an Option</option>
                {countriesData?.map((country, idx) => (
                    <option key={idx} value={country?.id}>{country?.name}</option>
                ))}
            </select>
        </div>

        <div className='flex flex-col w-[45%] mt-4'>
            <label>Region</label>
            <select className={`${INPUT_STYLE}`} value={payload?.state_id} onChange={(e) => handleOnChange(e, 'state_id')}>
                <option value="">Choose an Option</option>
                {regionsData?.filter((data) => data?.parent_id === payload?.country_id)?.map((region, idx) => (
                    <option key={idx} value={region?.id}>{region?.name}</option>
                ))}
            </select>
        </div>

        <div className='flex flex-col mt-4'>
            <label>Bio</label>
            <textarea className={`${INPUT_STYLE}`} value={payload?.bio} onChange={(e) => handleOnChange(e, 'bio')}></textarea>
        </div>

    </div>

    <div className='flex items-center justify-between mt-5'>
        <button className="hover:bg-black hover:text-white px-3  py-2 border border-black rounded-md" onClick={handleClose}>Close</button>
        <button className="bg-black text-white px-3  py-2 rounded-md" onClick={handleCreateOrUpdate}>{actionType === 'create'? 'Create' : 'Update'}</button>
    </div>
   </>
  )
}

export default User