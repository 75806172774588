import React from 'react'
import MakePayment from '../makePayment'

function Step4({payload, setPayload, payload2, checkoutData}) {
  return (
    <div>
        <h1 className='text-gray-900 text-[18px] mb-4 font-bold text-center'>Make Payment</h1>
        <MakePayment showCloseCreate={false} payload={payload} setPayload={setPayload} checkoutData={{...checkoutData, fname:payload2?.fname, lname:payload2?.lname, email:payload2?.email, phone_ext: payload2?.phone_ext, mobile: payload2?.mobile}}/>
    </div>
  )
}

export default Step4