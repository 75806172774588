import  api from '../api'
import endPoints from './endPoints';

export async function getAllReferrals( body ) {
  try {
    const response = await api.post(endPoints.getAllReferrals(), body)
    return response 
  }
  catch (e) {
    throw new Error(e?.response?.data?.error?.message)
  }
}


export async function postReferral(body) {
  try {
    const response = await api.post(endPoints.createReferral(), body)
    return response 
  }
  catch (e) {
    throw new Error(e?.response?.data?.error?.message)
  }
}

export async function putReferral(id , body ) {
  try {
    const response = await api.put(endPoints.updateReferral(id), body)
    return response 
  }
  catch (e) {
    throw new Error(e?.response?.data?.error?.message)
  }
}


export async function deleteReferral(id) {
  try {
    const response = await api.delete(endPoints.deleteReferral(id))
    return response 
  }
  catch (e) {
    throw new Error(e?.response?.data?.error?.message)
  }
}

