import React, { useState } from "react";
import Button from "../../components/button";
import FormInputs from "../../components/popup/formInputs";
import { reservations, checkout, users, listing, payments } from "../../constants/moduleEvents";
import { INPUT_STYLE } from "../../constants";

const formList = [
  // {
  //   id: 1,
  //   formType: "selectBox",
  //   type: "select",
  //   label: "Module Name",
  //   apiKey: "module_name",
  //   optionsList: [
  //     {
  //       id: 1,
  //       optionValue: "RESERVATIONS",
  //       name: "Reservations",
  //     },
  //     {
  //       id: 2,
  //       optionValue: "USERS",
  //       name: "Users",
  //     },
  //     {
  //       id: 3,
  //       optionValue: "LISTINGS",
  //       name: "Listings",
  //     },
  //     {
  //       id: 4,
  //       optionValue: "PAYMENTS",
  //       name: "Payments",
  //     },
  //   ],
  // },
  // {
  //   id: 2,
  //   formType: "selectBox",
  //   type: "select",
  //   label: "Action",
  //   apiKey: "action",
  //   optionsList: [
  //     {
  //       id: 1,
  //       optionValue: "GET",
  //       name: "GET",
  //     },
  //     {
  //       id: 2,
  //       optionValue: "CREATE",
  //       name: "CREATE",
  //     },
  //     {
  //       id: 3,
  //       optionValue: "UPDATE",
  //       name: "UPDATE",
  //     },
  //     {
  //       id: 4,
  //       optionValue: "DECLINE",
  //       name: "Decline",
  //     },
  //     {
  //       id: 5,
  //       optionValue: "CHECKIN",
  //       name: "Check In",
  //     },
  //     {
  //       id: 6,
  //       optionValue: "CHECKOUT",
  //       name: "Check out",
  //     },
  //   ],
  // },
  {
    id: 3,
    type: "number",
    label: "Delay",
    apiKey: "delayin_sec",
    optionsList: "",
  },
  {
    id: 5,
    type: "text",
    label: "Url",
    apiKey: "webhook_url",
    optionsList: "",
  },
  {
    id: 6,
    type: "select",
    label: "Webhook method",
    apiKey:"webhook_method",
    optionsList: [
      {
        id: 1,
        optionValue: "GET",
        name: "GET",
      },
      {
        id: 2,
        optionValue: "POST",
        name: "POST",
      },
      {
        id: 3,
        optionValue: "UPDATE",
        name: "UPDATE",
      },
      {
        id: 4,
        optionValue: "DELETE",
        name: "Delete",
      },
    ],
  },
  {
    id: 6,
    type: "text",
    label: "Headers",
    apiKey:"webhook_headers",
    optionsList: "",
  },
  {
    id: 6,
    type: "text",
    label: "Created By(User ID)",
    apiKey: "user_id",
    optionsList: "",
  },
];





function EventModuleForm({
  isEdit,
  close,
  editData,
  handleRequest,
  value,
  createClick,
}) {
  const [formData, setFormData] = useState({ ...editData });

  const handleSubmit = (e) => {
    e.preventDefault();
    handleRequest(formData);
    let obj = {}
    Object.keys(formData).forEach((e) => (formData[e] = ""));
    Object.keys(formData).forEach(eachkey => {
      if (formData[eachkey] !== editData[eachkey]) {
        obj[eachkey] = formData[eachkey]
      }
    })
    // handleRequest(createClick ? formData : obj); // send the obj data(edit)-keys for API which is changed 
    setFormData(formData);
  };


  const renderOptions = () => {
    switch (formData?.module_name) {
      case 'RESERVATIONS':
        return reservations.map((data, idx) => <option key={idx} value={data?.value}>{data?.name}</option>);

      case 'CHECKOUTS':
        return checkout.map((data, idx) => <option key={idx} value={data?.value}>{data?.name}</option>);
      
      case 'USERS':
        return users.map((data, idx) => <option key={idx} value={data?.value}>{data?.name}</option>);
      
      case 'LISTINGS':
        return listing.map((data, idx) => <option key={idx} value={data?.value}>{data?.name}</option>);

      case 'PAYMENTS':
        return payments.map((data, idx) => <option key={idx} value={data?.value}>{data?.name}</option>);
      default:
        break
    }
  }
  
  return (
    <div>
      {isEdit && (
        <form onSubmit={handleSubmit}>
          <div className="flex justify-between flex-wrap">

          <div className="w-[49%]">
            <label className="text-slate-600  text-md mb-1">Module Name</label>
            <select className={INPUT_STYLE} value={formData?.module_name} onChange={(e) => setFormData((prev) => ({...prev, module_name:e.target.value}))}>
               <option value="">Choose Module Name</option>
               <option value="RESERVATIONS">Reservations</option>
               <option value="CHECKOUTS">Checkout</option>
               <option value="USERS">Users</option>
               <option value="LISTINGS">Listing</option>
               <option value="PAYMENTS">Payments</option>
            </select>
          </div>


          <div className="w-[49%]">
             <label className="text-slate-600  text-md mb-1">Action</label>
             <select className={INPUT_STYLE} value={formData?.action} onChange={(e) => setFormData((prev) => ({...prev, action:e.target.value}))} >
                <option value="">Choose Action</option>
                {renderOptions()}
             </select>
          </div>





            {formList.map((eachItem) => (
              <FormInputs
                objKey="optionValue"
                optionsData={eachItem.optionsList}
                key={eachItem.id}
                label={eachItem.label}
                type={eachItem.type}
                value={formData[eachItem.apiKey]}
                className="w-[49%] mt-2"
                onChange={(e) =>
                  setFormData(
                    createClick
                      ? { ...formData, [eachItem.apiKey]: e.target.value }
                      : eachItem.apiKey !== "slug"
                      ? { ...formData, [eachItem.apiKey]: e.target.value }
                      : { ...formData }
                  )
                }
              />
            ))}
          </div>
          <div className="flex justify-between mt-6">
            <Button type="outline" value="Cancel" onClick={close} />
            <Button buttonType="submit" value={value} type="secondary" />
          </div>
        </form>
      )}
    </div>
  );
}
export default EventModuleForm;
